import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL, TOKEN, URL } from "./constants";
import { getToken } from "../store/userTocken";

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: async (headers) => {
      // const token = getToken();
      let id = localStorage.getItem(TOKEN);
      headers.set("Authorization", "Bearer " + id);
      headers.set("mode", "no-cors");
      headers.set("Accept", "application/json");
    },
  }),
  refetchOnMountOrArgChange: true,
  tagTypes: ["profileData", "contactUs", "postList"],
  endpoints: (builder) => ({
    // REGISTER
    register: builder.mutation({
      query: (payload) => ({
        url: URL.REGISTER,
        method: "POST",
        body: payload,
      }),
    }),

    // LOGIN
    login: builder.mutation({
      query: (payload) => ({ url: URL.LOGIN, method: "POST", body: payload }),
    }),

    // VERIFY OTP
    verify_otp: builder.mutation({
      query: (payload) => ({
        url: URL.VERIFY_OTP,
        method: "POST",
        body: payload,
      }),
    }),

    // RESEND OTP
    resend_otp: builder.mutation({
      query: (payload) => ({
        url: URL.RESEND_OTP,
        method: "POST",
        body: payload,
      }),
    }),

    // LOGOUT
    logout: builder.query({
      query: () => URL.LOG_OUT,
    }),

    // PROFILE VIEW
    profile_view: builder.query({
      query: () => URL.PROFILE_VIEW,
      providesTags: ["profileData"],
    }),

    // PROFILE UPDATE
    profile_update: builder.mutation({
      query: (payload) => ({
        url: URL.PROFILE_EDIT,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["profileData"],
    }),

    // BANNERS LIST
    bannerList: builder.query({
      query: () => URL.BANNER_LIST,
    }),

    // CONTACT US
    contactUs: builder.mutation({
      query: (payload) => ({
        url: URL.CONTACT_US,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["contactUs"],
    }),

    // CONTACT VIEW
    contactView: builder.query({
      query: () => URL.CONTACT_VIEW,
      providesTags: ["contactUs"],
    }),

    // CATEGORY LIST
    categoryList: builder.query({
      query: () => URL.CATEGORY_LIST,
    }),

    // PROFILE EDIT
    profileEdit: builder.mutation({
      query: (payload) => ({
        url: URL.PROFILE_EDIT,
        method: "POST",
        body: payload,
      }),
    }),

    // POST CREATE
    postCreate: builder.mutation({
      query: (payload) => ({
        url: URL.POST_CREATE,
        method: "POST",
        body: payload,
      }),
    }),

    // POST LIST
    postList: builder.mutation({
      query: (payload) => ({
        url: URL.POST_LIST,
        method: "POST",
        body: payload,
      }),
    }),

    // SUBCATEGORIES
    subcategories: builder.mutation({
      query: (payload) => ({
        url: URL.SUBCATEGORIES,
        method: "POST",
        body: payload,
      }),
    }),

    // CLASSIFIED EDIT
    classifiedEdit: builder.mutation({
      query: (payload) => ({
        url: URL.CLASSIFIED_EDIT,
        method: "POST",
        body: payload,
      }),
    }),

    // IMAGE DELETE
    imageDelete: builder.mutation({
      query: (payload) => ({
        url: URL.IMAGE_DELETE,
        method: "POST",
        body: payload,
      }),
    }),

    // CATEGORY DELETE
    categoryDelete: builder.mutation({
      query: (payload) => ({
        url: URL.CATEGORY_DELETE,
        method: "POST",
        body: payload,
      }),
    }),

    // CATEGORY & SERVICE VIEW
    postView: builder.mutation({
      query: (payload) => ({
        url: URL.CATEGORY_VIEW,
        method: "POST",
        body: payload,
      }),
    }),

    // SERVICE EDIT
    serviceEdit: builder.mutation({
      query: (payload) => ({
        url: URL.SERVICE_EDIT,
        method: "POST",
        body: payload,
      }),
    }),

    // JOB VACANCY POST
    jobVacancyPost: builder.mutation({
      query: (payload) => ({
        url: URL.JOB_VACANCY_POST,
        method: "POST",
        body: payload,
      }),
    }),

    // OVERALL LIST
    overallList: builder.mutation({
      query: ({ payload, params }) => ({
        url: URL.OVERALL_LIST + params,
        method: "POST",
        body: payload,
      }),
    }),

    // SUBCATEGORY LIST
    subcategoryList: builder.mutation({
      query: ({ payload, params }) => ({
        url: URL.SUBCATEGORY_LIST + params,
        method: "POST",
        body: payload,
      }),
    }),

    // SERVICE BANNERS
    serviceBanners: builder.mutation({
      query: (payload) => ({
        url: URL.SERVICE_BANNERS,
        method: "POST",
        body: payload,
      }),
    }),

    //HOME PAGE SHOW CATEGORYS
    homeCategoryShow: builder.mutation({
      query: (payload) => ({
        url: URL.HOME_SHOW_CATEGORIES,
        method: "POST",
        body: payload,
      }),
    }),

    // JOB VACCANCY VIEW
    jobVacancyView: builder.mutation({
      query: (payload) => ({
        url: URL.JOB_VACANCY_VIEW,
        method: "POST",
        body: payload,
      }),
    }),

    // JOB VACCANCY UPDATE
    jobVacancyUpdate: builder.mutation({
      query: (payload) => ({
        url: URL.JOB_VACANCY_UPDATE,
        method: "POST",
        body: payload,
      }),
    }),

    // JOB SUBCATEGORIES
    jobSubcategories: builder.mutation({
      query: (payload) => ({
        url: URL.JOB_SUBCATEGORIES,
        method: "POST",
        body: payload,
      }),
    }),

    // EXPLORE BANNERS
    exploreBanner: builder.mutation({
      query: (payload) => ({
        url: URL.EXPLORE_BANNERS,
        method: "POST",
        body: payload,
      }),
    }),

    // EXPLORE POST CREATE
    explorePostCreate: builder.mutation({
      query: (payload) => ({
        url: URL.EXPLORE_POST_CREATE,
        method: "POST",
        body: payload,
      }),
    }),

    // EXPLORE POST VIEW
    explorePostView: builder.mutation({
      query: (payload) => ({
        url: URL.EXPLORE_POST_VIEW,
        method: "POST",
        body: payload,
      }),
    }),

    // EXPLORE POST UPDATE
    explorePostUpdate: builder.mutation({
      query: (payload) => ({
        url: URL.EXPLORE_POST_UPDATE,
        method: "POST",
        body: payload,
      }),
    }),

    // SEARCH
    search: builder.mutation({
      query: (payload) => ({
        url: URL.SEARCH,
        method: "POST",
        body: payload,
      }),
    }),

    // ENQUIRY
    enquiry: builder.mutation({
      query: (payload) => ({
        url: URL.ENQUIRY,
        method: "POST",
        body: payload,
      }),
    }),

    // CATEGORY LISTS
    categoryLists: builder.mutation({
      query: ({ payload, params }) => ({
        url: URL.CATEGORY_LISTS + params,
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useLazyLogoutQuery,
  useRegisterMutation,
  useVerify_otpMutation,
  useResend_otpMutation,
  useLazyProfile_viewQuery,
  useBannerListQuery,
  useContactUsMutation,
  useContactViewQuery,
  useCategoryListQuery,
  useProfileEditMutation,
  usePostCreateMutation,
  usePostListMutation,
  useSubcategoriesMutation,
  useCategoryDeleteMutation,
  useClassifiedEditMutation,
  useImageDeleteMutation,
  usePostViewMutation,
  useServiceEditMutation,
  useJobVacancyPostMutation,
  useOverallListMutation,
  useSubcategoryListMutation,
  useServiceBannersMutation,
  useHomeCategoryShowMutation,
  useJobVacancyUpdateMutation,
  useJobVacancyViewMutation,
  useJobSubcategoriesMutation,
  useExploreBannerMutation,
  useExplorePostCreateMutation,
  useExplorePostViewMutation,
  useExplorePostUpdateMutation,
  useSearchMutation,
  useEnquiryMutation,
  useCategoryListsMutation,
} = api;
