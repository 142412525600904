import React, { useEffect, useState } from "react";
import ClassifiedBanner from "../../Components/Classified/ClassifiedDetail/ClassifiedBanner";
import ClassifiedRelatePost from "../../Components/Classified/ClassifiedDetail/ClassifiedRelatePost";
import ClassifiedRight from "../../Components/Classified/ClassifiedDetail/ClassifiedRight";
import { useLocation } from "react-router-dom";
import { usePostViewMutation } from "../../redux/api/api";
import CloseIcon from "@mui/icons-material/Close";
import { lodergif } from "../../assets/img";
import { Modal } from "react-bootstrap";

const ClassifiedDetail = () => {
  const location = useLocation();
  const post_id = location?.state?.post_id;
  const [loader, setLoader] = useState(false);
  const [openCard, setOpenCard] = useState(false);
  const [imgData, setImgData] = useState({});
  const [postDetails, setPostDetails] = useState({});
  const [postView] = usePostViewMutation();

  useEffect(() => {
    if (post_id) {
      const formData = new FormData();
      formData.append("post_id", post_id);
      formData.append("type", 1); //{1->classified,2->service}
      setLoader(true);
      postView(formData)
        .unwrap()
        .then((res) => {
          if (res?.status === "success") {
            // console.log(res);
            setLoader(false);
            setPostDetails(res);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
    }
  }, [post_id]);

  // console.log("postDetails", postDetails);

  const openHandele = (data) => {
    setImgData(data);
    setOpenCard(true);
  };

  return (
    <div className=" bg-white">
      {loader && (
        <div className="loader-icon position-fixed bg-opacity-25 loderbg ac-jc">
          <div className="loader-icon1">{/* <img src={lodergif} /> */}</div>
        </div>
      )}
      <div className="w-90 pb-3">
        <div className="d-flex flex-wrap">
          <ClassifiedBanner
            postDetails={postDetails}
            openHandele={openHandele}
          />
          <ClassifiedRight postDetails={postDetails} />
        </div>
        {postDetails?.classified && (
          <ClassifiedRelatePost postDetails={postDetails} />
        )}
      </div>
      <Modal
        className=""
        show={openCard}
        centered
        onHide={() => setOpenCard(false)}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <div className="position-relative rounded">
          <img className="w-100 rounded" src={imgData?.image_path} />
          <button
            style={{ right: 0 }}
            type="button"
            className="close position-absolute border-0 rounded bg-black text-white"
            data-dismiss="modal"
            aria-label="Close"
            onClick={(e) => setOpenCard(false)}
          >
            <CloseIcon />
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ClassifiedDetail;
