import React from 'react'
import FutureLeft from './FutureLeft'
import FutureRight from './FutureRight'

const FurtureComp = () => {
    return (
        <div className="bg-white my-5 w-90">
            <div className='d-flex flex-wrap justify-content-between align-items-cente align-custom'>
                <FutureLeft />
                <FutureRight />
            </div>
        </div>
    )
}

export default FurtureComp