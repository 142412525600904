import React, { useEffect, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import DoneIcon from "@mui/icons-material/Done";
import { useNavigate } from "react-router-dom";
import companyLogo from "../../assets/img/header_logo.png";
import { NUMBER, TOKEN } from "../../redux/api/constants";
import {
  useResend_otpMutation,
  useVerify_otpMutation,
} from "../../redux/api/api";
import useToken from "../../redux/store/userTocken";
import useUser from "../../redux/store/userData";
import { Loader, lodergif } from "../../assets/img";

const OTPRight = ({ number }) => {
  const [checkBox, setCheckBox] = useState(false);
  const [resendEnable, setResendEnable] = useState(false);
  const [timer, setTimer] = useState(60);
  const [otpNumber, setOtpNumber] = useState("");
  const [otpNumberErr, setOtpNumberErr] = useState(false);
  const [btn, setBtn] = useState(false);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const { seToken } = useToken();
  const { setUser } = useUser();

  const [verfyOtp] = useVerify_otpMutation();
  const [resendOtp] = useResend_otpMutation();

  const onClickHandler = () => {
    let formata = new FormData();
    formata.append("mobile", number);

    setBtn(true);
    setLoader(true);
    resendOtp(formata)
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          setBtn(false);
          // alert(res?.status);
          setOtpNumber("");
          setLoader(false);
          setTimer(60);
          setResendEnable(false);
        }
      })
      .catch((err) => {
        setBtn(false);
        setLoader(false);
        // alert(err?.data?.message);
      });
  };

  const sumbitHandler = () => {
    let formata = new FormData();
    if (otpNumber.length < 6) {
      setOtpNumberErr(true);
    } else {
      formata.append("mobile", number);
      formata.append("otp", otpNumber);
      setBtn(true);
      setLoader(true);
      verfyOtp(formata)
        .unwrap()
        .then((res) => {
          if (res?.status == "success") {
            setBtn(false);
            localStorage.setItem(TOKEN, res?.token);
            setUser(res?.user);
            // alert(res?.message);
            setLoader(false);
            setTimeout(() => {
              navigate("/profile");
              window.location.reload();
            }, 500);
          }
        })
        .catch((err) => {
          setBtn(false);
          setLoader(false);
          // alert(err?.data?.message);
        });
    }
  };

  useEffect(() => {
    if (timer === 0) {
      setTimer(null);
      setResendEnable(true);
    } else if (timer > 0) {
      setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
    }
  }, [timer]);

  return (
    <div className="SecondFlex">
      {loader && (
        <div className="loader-icon position-fixed bg-opacity-25 loderbg ac-jc">
          <div className="loader-icon1">{/* <img src={lodergif} /> */}</div>
        </div>
      )}
      <div className="SecondFlexDesign position-relative">
        <img src={companyLogo} className="companyLogo d-block d-md-none" />
        <h2 className="LoginText">Login</h2>
        <label className="LoginFont">
          Enter The sent OTP on your Mobile Number
        </label>
        <input
          onInput={(e) => {
            if (e.target.value.length > e.target.maxLength)
              e.target.value = e.target.value.slice(0, e.target.maxLength);
            if (e.target.value.length !== 6) {
              e.target.setCustomValidity("invalid Number");
            } else if (e.target.value.length == 6) {
              e.target.setCustomValidity("");

              setOtpNumber(e.target.value);
            }
          }}
          onFocus={(e) =>
            e.target.addEventListener(
              "wheel",
              function (e) {
                e.preventDefault();
              },
              { passive: false }
            )
          }
          maxlength={6}
          onChange={(e) => setOtpNumber(e.target.value)}
          value={otpNumber}
          type="number"
          placeholder="Enter OTP"
          className="MobileNumberTextInput"
        />
        {otpNumberErr && otpNumber.length < 6 && (
          <h6 className="error-state-otp">Enter your OTP Number</h6>
        )}
        <div className="count-sec d-flex ac-jb otpMarginDesign">
          <p className="text-primary">{timer ? timer + " Seconds" : ""}</p>

          {resendEnable ? (
            <button
              onClick={onClickHandler}
              className="cust-btn d-flex ac-jc"
              style={{ opacity: resendEnable ? 1 : 0.3 }}
              disabled={btn ? true : false}
            >
              Resend OTP
            </button>
          ) : (
            ""
          )}
        </div>
        <button
          onClick={sumbitHandler}
          disabled={btn ? true : false}
          className="cust-btn py-2 mt-3 w-100 bg-primar text-light f2 btn-submit"
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default OTPRight;
