import React, { useState } from "react";
import LoginLeft from "../../Components/Login/LoginLeft";
import LoginRight from "../../Components/Login/LoginRight";
import OTPRight from "../../Components/Login/OTPRight";
import RegistrationRight from "../../Components/Login/RegistrationRight";
import { NUMBER } from "../../redux/api/constants";
import CustomPopup from "../../Components/Popup/CustomPopup";
import { useLoginMutation } from "../../redux/api/api";
import { useDispatch } from "react-redux";
import alertMessage, { saveAlertMessage } from "../../redux/slice/alertMessage";
import { Loader, lodergif } from "../../assets/img";

const LoginScreen = () => {
  const dispatch = useDispatch();
  const [checkBox, setCheckBox] = useState(false);
  const [showOtp, setShowOtp] = useState(true);
  const [register, setRegister] = useState(false);
  const [checkBoxErr, setcheckBoxErr] = useState(false);
  const [number, setNumber] = useState(" ");
  const [btn, setBtn] = useState(false);
  const [loader, setLoader] = useState(false);

  const [login] = useLoginMutation();

  const [numberErr, setNumberErr] = useState(false);

  const submitHandler = () => {
    let formata = new FormData();
    if (NUMBER.test(number) == false || number.length < 10) {
      setNumberErr(true);
    } else if (!checkBox) {
      console.log("CHECK_BOX___", checkBox);
      setcheckBoxErr(true);
    } else {
      formata.append("mobile", number);
      setLoader(true);
      setBtn(true);
      login(formata)
        .unwrap()
        .then((res) => {
          if (res?.status == "success") {
            // console.log(res?.otp);
            // dispatch(saveAlertMessage(res?.otp));
            setBtn(false);
            setLoader(false);
            setShowOtp(false);
          }
        })
        .catch((err) => {
          setBtn(false);
          setLoader(false);
          console.log("err", err);
        });
    }
    // if (!checkBox) {
    //   alert("Agree the terms and Conditions");
    // } else {
    //   setShowOtp(false);
    // }
  };

  const createAccHandler = () => {
    setRegister(true);
  };

  const showLoginHandler = () => {
    setShowOtp(true);
    setRegister(false);
  };

  return (
    <div className="d-flex">
      {loader && (
        <div className="loader-icon position-fixed bg-opacity-25 loderbg ac-jc">
          <div className="loader-icon1">{/* <img src={lodergif} /> */}</div>
        </div>
      )}
      <div className="w-50 d-none d-md-block">
        <LoginLeft />
      </div>
      {showOtp && !register && (
        <LoginRight
          checkBox={checkBox}
          setCheckBox={setCheckBox}
          submitHandler={submitHandler}
          createAccHandler={createAccHandler}
          number={number}
          numberErr={numberErr}
          setNumberErr={setNumberErr}
          setNumber={setNumber}
          btn={btn}
        />
      )}
      {!showOtp && <OTPRight number={number} />}
      {register && (
        <RegistrationRight
          showLoginHandler={showLoginHandler}
          setRegister={setRegister}
          setNumber={setNumber}
        />
      )}
      {checkBoxErr && (
        <CustomPopup
          bodyContent={"Agree the Terms and Conditions"}
          openPopup={checkBoxErr}
          setOpenPopup={setcheckBoxErr}
          type={"message"}
        />
      )}
      {/* {submitPopup && (
        <CustomPopup
          bodyContent={"Submit Successful"}
          type={"submit"}
          openPopup={checkBoxErr}
          setOpenPopup={setcheckBoxErr}
        />
      )} */}
    </div>
  );
};

export default LoginScreen;
