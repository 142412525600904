// @ts-nocheck
import React, { useState } from "react";
import { curve, profile1 } from "../../../assets/img";
import user from "../../../assets/img/stech.jpeg";
import OwlCarousel from "react-owl-carousel";
import { options } from "../../../redux/api/constants";
import { jobList, serviceJson } from "../../../redux/api/DummyJson";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate } from "react-router-dom";
import { Col } from "react-bootstrap";
import PlaceIcon from "@mui/icons-material/Place";
import JobListBox from "./JobListBox";

const JobLists = ({ jobList, setListOrder, listOrder }) => {
  const [showSort, setShowSort] = useState(false);
  const navigate = useNavigate();

  const showHandler = (order) => {
    if (order === "asc" || order === "desc") {
      setListOrder(order);
    }
    setShowSort(!showSort);
  };

  return (
    <div className="tl-service text-center pb-3">
      <div className="d-flex ac-jb mb-2 mx-2">
        <h5 className="result-txt">
          Result -{" "}
          {jobList?.pagination?.total
            ? jobList?.pagination?.total
            : "No Data Found"}
        </h5>
        <div className="sort-by">
          <button onClick={showHandler} className="cust-btn show-text">
            Sort by <KeyboardArrowDownIcon />
          </button>
          {showSort && (
            <div className="download-box">
              <button
                onClick={() => showHandler("desc")}
                className="cust-btn f3 py-1"
                style={
                  listOrder == "desc"
                    ? { background: "#008cff", color: "white" }
                    : { background: "transparent" }
                }
              >
                Newest
              </button>
              <button
                onClick={() => showHandler("asc")}
                className="cust-btn f3 py-1"
                style={
                  listOrder == "asc"
                    ? { background: "#008cff", color: "white" }
                    : { background: "transparent" }
                }
              >
                Oldest
              </button>
            </div>
          )}
        </div>
      </div>
      <JobListBox jobList={jobList} />
    </div>
  );
};

export default JobLists;
