import React from "react";
import ServiceLists from "../../Services/ServiceList/ServiceLists";

const ServicesPost = () => {
  return (
    <div className="jc-ac">
      <ServiceLists method="TabMethod" />
    </div>
  );
};

export default ServicesPost;
