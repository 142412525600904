import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { lodergif, singlephone, whatsapp1 } from "../../assets/img";
import { useEnquiryMutation } from "../../redux/api/api";
import CustomPopup from "../Popup/CustomPopup";

const SaidIconHome = ({ setLoaders }) => {
  const [enqModal, setEnqModal] = useState(false);
  const [enqModal2, setEnqModal2] = useState(false);
  const [btn, setBtn] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const [nameErr, setNameErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [mobileErr, setMobileErr] = useState(false);
  const [subjectErr, setSubjectErr] = useState(false);
  const [messageErr, setMessageErr] = useState(false);
  const [popup, setPopup] = useState(false);
  const [checkBoxErr, setcheckBoxErr] = useState(false);

  //   RTK QUERY

  const [enquiryAdd] = useEnquiryMutation();

  const submitHandler = () => {
    if (
      name?.length == 0 ||
      email?.length == 0 ||
      mobile?.length < 10 ||
      subject?.length == 0 ||
      message?.length == 0
    ) {
      setNameErr(true);
      setEmailErr(true);
      setMobileErr(true);
      setSubjectErr(true);
      setMessageErr(true);
    } else {
      let formdata = new FormData();

      formdata.append("name", name);
      formdata.append("email", email);
      formdata.append("subject", subject);
      formdata.append("mobile", mobile);
      formdata.append("message", message);
      setBtn(true);
      setLoaders(true);
      enquiryAdd(formdata)
        .unwrap()
        .then((res) => {
          if (res?.status == "success") {
            setEnqModal(false);
            setName("");
            setEmail("");
            setSubject("");
            setMobile("");
            setMessage("");
            setBtn(false);
            setEnqModal2(true);
            setLoaders(false);
          }
        })
        .catch((err) => {
          console.log("err", err);
          setBtn(false);
          setLoaders(false);
        });
    }
  };

  let enquiry = ["E", "N", "Q", "U", "I", "R", "Y"];
  const validEmail = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);

  return (
    <div className="loginpopup">
      <div className="loginpopuplayout mo-di">
        <div className="modal-content">
          <div className="modal-body gap-2">
            <a
              href={`tel:+91 ${9988779928}`}
              target="_blank"
              className="d-flex"
            >
              <img
                src={singlephone}
                style={{ height: "20px", width: "20px" }}
              />
            </a>
            <a
              href={`https://wa.me/${919988779928}`}
              target="_blank"
              className="d-flex"
            >
              <img src={whatsapp1} style={{ height: "20px", width: "20px" }} />
            </a>
            <button
              className="btn btn-primary"
              style={{
                color: "#fff",
              }}
              onClick={() => setEnqModal(true)}
            >
              {enquiry?.map((item) => (
                <p className="btn-texts ">{item}</p>
              ))}
            </button>
          </div>
        </div>
      </div>
      <Modal
        className="flot-monal"
        show={enqModal}
        size="md"
        centered
        onHide={() => setEnqModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <h5>Enquiry</h5>
        </Modal.Header>
        <div className="audi-form my-3 p-3">
          <div>
            <div className="my-3 position-relative pt-2">
              <input
                className="form-control"
                placeholder="Name "
                type="name"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {name?.length == 0 && nameErr && (
                <p className="error-state-enquiry">Enter Name</p>
              )}
            </div>
            <div className="my-3 position-relative pt-2">
              <input
                className="form-control"
                placeholder="E-mail "
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {validEmail.test(email) == false && emailErr && (
                <p className="error-state-enquiry">Enter Email</p>
              )}
            </div>
            <div className="my-3 position-relative pt-2">
              <input
                className="form-control"
                placeholder="Mobile"
                type="number"
                name="mobile"
                onInput={(e) => {
                  if (e.target.value.length > e.target.maxLength)
                    e.target.value = e.target.value.slice(
                      0,
                      e.target.maxLength
                    );
                  if (e.target.value.length !== 10) {
                    e.target.setCustomValidity("invalid Number");
                  } else if (e.target.value.length == 10) {
                    e.target.setCustomValidity("");

                    setMobile(e.target.value);
                  }
                }}
                onFocus={(e) =>
                  e.target.addEventListener(
                    "wheel",
                    function (e) {
                      e.preventDefault();
                    },
                    { passive: false }
                  )
                }
                maxlength={10}
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
              />
              {mobile?.length < 10 && mobileErr && (
                <p className="error-state-enquiry">Enter Mobile</p>
              )}
            </div>
            <div className="my-3 position-relative pt-2">
              <input
                className="form-control"
                placeholder="Subject"
                type="text"
                name="subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
              {subject?.length == 0 && subjectErr && (
                <p className="error-state-enquiry">Enter Subject</p>
              )}
            </div>
            <div className="my-3 position-relative pt-2">
              <textarea
                rows={2}
                className="form-control"
                placeholder="Message"
                name="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
              {message?.length == 0 && messageErr && (
                <p className="error-state-enquiry">Enter Message</p>
              )}
            </div>

            <div className="d-flex justify-content-end pt-3">
              <button
                className="cust-btn btn-style bg-sec"
                onClick={submitHandler}
                disabled={btn ? true : false}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        show={enqModal2}
        size="md"
        centered
        onHide={() => setEnqModal2(false)}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <div className="mt-5">
          <div className="">
            <h6 className="text-center">Enquiry Sended Successfully</h6>
            <div className="d-flex justify-content-center">
              <button
                className="cust-btn bg-primar text-white rounded-3 p-2 d-grid m-3"
                onClick={() => setEnqModal2(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SaidIconHome;
