import React from "react";
import { Col, Row } from "react-bootstrap";

const EducationLeft = () => {
  return (
    <Col
      xs={12}
      md={6}
      className="service-detail-sec col-custom-res align-self-center"
    >
      <h5
        className="primary1 font-custom
                "
      >
        EDUCATION
        <div className="activeLine align-self-center" />
      </h5>
      <div className="my-2 desc-txt font-custom2">
        <p>
          At Asquare, we cherish the vision of the late Mr. Shariff, who
          illuminated the path for our tuition center in Parangi Pettai. He
          perceived education not just as a business, but as a profound mission.
          His bond with students and staunch dedication are pillars we stand on.
          Today, we further his dream, emphasizing quality education
        </p>
        <div className="my-2 desc-txt">
          <p>
            fostering a lifelong passion for learning. We take pride in the
            success of our students who completed their studies last year and
            remain committed to fostering an environment that supports academic
            achievement.
          </p>
        </div>
      </div>
    </Col>
  );
};

export default EducationLeft;
