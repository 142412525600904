import React, { useEffect, useState } from "react";

import ExploreBanner from "../../Components/Explore/ExploreDetail/ExploreBanner";
import ExploreRelatePost from "../../Components/Explore/ExploreDetail/ExploreRelatePost";
import ExploreRight from "../../Components/Explore/ExploreDetail/ExploreRight";
import { useLocation } from "react-router-dom";
import {
  useExplorePostViewMutation,
  usePostViewMutation,
} from "../../redux/api/api";
import ServiceDetailLeft from "../../Components/Services/ServicesDetail/ServiceDetailLeft";
import { lodergif } from "../../assets/img";

const ExploreDetailScreen = () => {
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const post_id = location?.state?.post_id;
  const [postDetails, setPostDetails] = useState({});
  const [postView] = useExplorePostViewMutation();
  const [clinic, setClinic] = useState("");

  useEffect(() => {
    // console.log(post_id);
    if (post_id) {
      const formData = new FormData();
      formData.append("post_id", post_id);
      setLoader(true);
      postView(formData)
        .unwrap()
        .then((res) => {
          if (res?.status === "success") {
            // console.log(res);
            setLoader(false);
            setClinic(res?.explore?.categories?.name);
            setPostDetails(res);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
    }
  }, [post_id]);

  return (
    <div className=" bg-white">
      {loader && (
        <div className="loader-icon position-fixed bg-opacity-25 loderbg ac-jc">
          <div className="loader-icon1">{/* <img src={lodergif} /> */}</div>
        </div>
      )}
      <div className="w-90 pb-3">
        <div className="d-flex flex-wrap">
          {clinic?.toLowerCase().startsWith("cli") && (
            <ServiceDetailLeft postDetails={postDetails} />
          )}
          {!clinic?.toLowerCase().startsWith("cli") && (
            <ExploreBanner postDetails={postDetails} />
          )}
          <ExploreRight postDetails={postDetails} />
        </div>
        <ExploreRelatePost postDetails={postDetails} />
      </div>
    </div>
  );
};

export default ExploreDetailScreen;
