import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import BlurCircularIcon from "@mui/icons-material/BlurCircular";
import AirIcon from "@mui/icons-material/Air";
import BusinessIcon from "@mui/icons-material/Business";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faLinkedinIn,
  faInstagram,
  faYoutube,
  faWhatsapp,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import TransgenderIcon from "@mui/icons-material/Transgender";
import FemaleIcon from "@mui/icons-material/Female";
import MaleIcon from "@mui/icons-material/Male";

import { Link, useNavigate } from "react-router-dom";
import moment from "moment";

const JobsDetailRight = ({ postDetails }) => {
  const [pointsList, setPointsList] = useState([]);
  const [FbUrl, setFbUrl] = useState("");
  const [instaUrl, setInstaUrl] = useState("");
  const [ytUrl, setYtUrl] = useState("");
  const [twitterUrl, setTwitterUrl] = useState("");

  useEffect(() => {
    const points = postDetails?.vacancy?.points;
    const socialLinks = postDetails?.vacancy?.user?.url;
    if (points) {
      const pointsArr = JSON.parse(points);
      // console.log(pointsArr);
      setPointsList(pointsArr);
    }
    if (socialLinks) {
      const url = JSON.parse(socialLinks);
      setInstaUrl(url?.[0]);
      setFbUrl(url?.[1]);
      setTwitterUrl(url?.[2]);
      setYtUrl(url?.[3]);
    }
  }, [postDetails]);

  // console.log("postDetailsssss", postDetails);

  return (
    <Col xs={12} md={6} className="service-detail-sec my-1 ps-sm-3 p-lg-3">
      <div className="w-100">
        <h5 style={{ textTransform: "capitalize" }} className="my-1 primary1">
          {postDetails?.vacancy?.job_title}
        </h5>
        {postDetails?.vacancy?.categories?.parent?.name === "Vacancy" ? (
          <h6 style={{ textTransform: "capitalize" }} className="my-1 primary1">
            {postDetails?.vacancy?.job_position} |{" "}
            {postDetails?.vacancy?.company_name}
          </h6>
        ) : null}
        <div className="d-flex bread-crumb">
          <Link
            style={{ color: "#0d6efd" }}
            className="cust-btn"
            to={`/job/list?catId=${postDetails?.vacancy?.categories?.parent_id}&catName=${postDetails?.vacancy?.categories?.parent?.name}`}
          >
            {postDetails?.vacancy?.categories?.parent?.name}
          </Link>
          <p> / </p>
          <Link
            style={{ color: "#0d6efd" }}
            className="cust-btn"
            to={`/job/list?catId=${postDetails?.vacancy?.categories?.parent_id}&subId=${postDetails?.vacancy?.categories?.id}&catName=${postDetails?.vacancy?.categories?.parent?.name}&subName=${postDetails?.vacancy?.categories?.name}`}
          >
            {postDetails?.vacancy?.categories?.name}
          </Link>
        </div>
        <div className="desc-txt">
          <p className="my-1">{postDetails?.vacancy?.description}</p>
          <div className="buttet-points">
            {pointsList?.map((item, index) => {
              return (
                <div key={index} className="d-flex my-2">
                  <AirIcon className="bullets-icon me-1" />
                  <p className="">{item} </p>
                </div>
              );
            })}
          </div>
        </div>
        {/* <p className="f3 ms-1 opacity-100 price my-1">₹ 20,000</p> */}
        <p className="me-1 my-2">
          <span>
            <BusinessCenterIcon />
          </span>
          <span className="fs-14 ms-1">
            {postDetails?.vacancy?.categories?.parent?.name
              .toLowerCase()
              .startsWith("vac")
              ? postDetails?.vacancy?.full_time_part_time +
                " | " +
                postDetails?.vacancy?.experience +
                " years Experience"
              : postDetails?.vacancy?.full_time_part_time}
          </span>
        </p>{" "}
        {postDetails?.vacancy?.categories?.parent?.name
          .toLowerCase()
          .startsWith("vac") ? (
          <>
            <p className="me-1 my-2">
              <span>
                <WorkOutlineIcon />
              </span>
              <span className="fs-14 ms-1">
                {postDetails?.vacancy?.job_position}
              </span>
            </p>
            <p className="me-1 my-2">
              <span>
                <BusinessIcon />
              </span>
              <span className="fs-14 ms-1">
                {postDetails?.vacancy?.company_name}
              </span>
            </p>
            <p className="me-1 my-2">
              <span>
                <LocationOnIcon />
              </span>
              <span className="fs-14 ms-1">
                {postDetails?.vacancy?.job_location}
              </span>
            </p>
          </>
        ) : null}
        <p className="me-1 my-2">
          <span style={{ marginLeft: "4px" }} className="f5 fs-15">
            ₹
          </span>
          <span className="fs-14 ms-2">{postDetails?.vacancy?.salary}</span>
        </p>
        <p className="me-1 my-2">
          <span>
            {postDetails?.vacancy?.gender == "Female" ? (
              <FemaleIcon />
            ) : (
              <MaleIcon />
            )}
          </span>
          <span className="fs-14 ms-1">{postDetails?.vacancy?.gender}</span>
        </p>
        <p className="me-1 my-2">
          <span className="f3">Key Skills:</span>{" "}
          {postDetails?.vacancy?.key_skills}
        </p>
        {postDetails?.vacancy?.categories?.parent?.name
          .toLowerCase()
          .startsWith("vac") ? (
          <p className="me-1 my-2">
            <span className="f3"> Applicant Location:</span>{" "}
            {postDetails?.vacancy?.applicant_location}
          </p>
        ) : null}
        <div className="d-flex flex-wrap ac-jb">
          <div className="d-flex flex-wrap ac-jb">
            <a href={`tel:+91 ${postDetails?.vacancy?.user?.mobile}`}>
              {/* <p className="contact-button">
                <span>
                  <LocalPhoneIcon />
                </span>
                <span> +91 {postDetails?.vacancy?.user?.mobile}</span>
              </p> */}
              <p className="contact-button">
                <LocalPhoneIcon className="mx-2" />
              </p>
            </a>
            {postDetails?.vacancy?.user?.secondary_number &&
              postDetails?.vacancy?.user?.secondary_number !== null && (
                // <p className="contact-button">
                //   <span>
                //     <LocalPhoneIcon />
                //   </span>
                //   <span>
                //     {" "}
                //     +91 {postDetails?.vacancy?.user?.secondary_number}
                //   </span>
                // </p>
                <p className="contact-button">
                  <LocalPhoneIcon className="mx-2" />
                </p>
              )}
          </div>
          <div className="d-flex mt-2 gap-2 ms-1 social-media-icons">
            {FbUrl && (
              <a target="_blank" href={FbUrl}>
                <button className="cust-btn bg-darkBlue text-white">
                  <FontAwesomeIcon icon={faFacebook} size="sm" />
                </button>
              </a>
            )}
            {instaUrl && (
              <a href={instaUrl} target="_blank">
                <button className="cust-btn bg-pinks">
                  <FontAwesomeIcon
                    icon={faInstagram}
                    size="sm"
                    style={{ color: "#ffff" }}
                  />
                </button>
              </a>
            )}
            {ytUrl && (
              <a href={ytUrl} target="_blank">
                <button className="cust-btn bg-red">
                  <FontAwesomeIcon
                    icon={faYoutube}
                    size="sm"
                    style={{ color: "#ffff" }}
                  />
                </button>
              </a>
            )}
            {twitterUrl && (
              <a href={twitterUrl} target="_blank">
                <button className="cust-btn bg-lt-primary">
                  <FontAwesomeIcon
                    icon={faTwitter}
                    size="sm"
                    style={{ color: "#ffff" }}
                  />
                </button>
              </a>
            )}
            {postDetails?.vacancy?.user?.w_number && (
              <a
                href={`https://wa.me/91${postDetails?.vacancy?.user?.w_number}`}
                target="_blank"
              >
                <button className="cust-btn bg-greens">
                  <FontAwesomeIcon
                    icon={faWhatsapp}
                    size="sm"
                    style={{ color: "#ffff" }}
                  />
                </button>
              </a>
            )}
          </div>
        </div>
        <div className="d-flex ac-jb post-by">
          <p className="ms-1 d-flex align-items-center mt-2">
            <span className="ms-1">Posted by </span>
            <span className="ms-1 text-dark f3">
              {postDetails?.vacancy?.user?.name}
            </span>
          </p>
          <p className="ms-1 d-flex align-items-center text-dark mt-2">
            <span className="ms-1 f3">
              {moment(postDetails?.vacancy?.created_at).fromNow()}
            </span>
          </p>
        </div>
      </div>
    </Col>
  );
};

export default JobsDetailRight;
