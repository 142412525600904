import React, { useEffect, useState } from "react";
import { classifiedBanner, explore_banner } from "../../../assets/img";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import HomeIcon from "@mui/icons-material/Home";
import ExploreTab from "./ExploreTab";
import ExploreListBox from "./ExploreListBox";
import { Carousel } from "react-bootstrap";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  useCategoryListsMutation,
  useExploreBannerMutation,
  useOverallListMutation,
  useSubcategoriesMutation,
  useSubcategoryListMutation,
} from "../../../redux/api/api";
const ExploreList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showTabList, setShowTabList] = useState(true);
  const [subCategTabList, setSubCategTabList] = useState(true);
  const [list, setList] = useState([]);
  const [subcategoriesListId, setSubCategoriesListId] = useState("");
  const [page, setPage] = useState(1);
  const [subcategoriesList, setSubCategoriesList] = useState([]);
  const [selectedSubCat, setSelectedSubCat] = useState();
  const [exploreList, setExploreList] = useState([]);
  const [listOrder, setListOrder] = useState("");
  const [catNames, setCatNames] = useState([]);
  const [catSubNames, setCatSubNames] = useState([]);
  const [subcategoriesListsId, setSubCategoriesListsId] = useState("");

  // RTK QUERY
  const [subcategoriesData] = useSubcategoriesMutation();
  const [exploreBanner] = useExploreBannerMutation();
  const [subcategoryListData] = useSubcategoryListMutation();
  const [overallList] = useOverallListMutation();
  const [catgoryList] = useCategoryListsMutation();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (subcategoriesListsId) {
      getCategoryListWithPaginate(subcategoriesListsId);
    } else if (subcategoriesListId) {
      // console.log("subcategoriesListId", subcategoriesListId);
      getSubcategoriesListPaginate(subcategoriesListId);
    } else if (!searchParams?.get("catId")) {
      getOverallList();
    }
  }, [page]);

  const getOverallList = (order) => {
    removeQueryParams();
    if (Object.values(catNames)?.length > 0) {
      setPage(1);
    }
    setSubCategoriesListsId("");
    setSubCategoriesListId("");
    const formData = new FormData();
    formData.append("type", 3); // {1->classified,2->services,3->explores,4->jobs}
    if (order || listOrder !== "") {
      formData.append("order", order ? order : listOrder);
    } else {
      formData.append("order", "desc");
      setListOrder("desc");
    }

    let params = `?page=${page}`;

    overallList({ payload: formData, params })
      .unwrap()
      .then((res) => {
        if (res?.status === "success") {
          setExploreList(res);
          setSubCategTabList(true);
          setCatNames([]);
          setCatSubNames([]);
          setSelectedSubCat("");
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const showTabListHandler = (item) => {
    setPage(1);
    const formData = new FormData();
    formData.append("subcategory_id", item?.id || item);
    subcategoriesData(formData)
      .unwrap()
      .then((res) => {
        if (res?.status === "success") {
          setSubCategoriesList(res?.sub_categories);
          if (searchParams?.get("subId")) {
            setSelectedSubCat(Number(searchParams?.get("subId")));
          }

          if (searchParams?.get("subId")) {
            getSubcategoriesList(searchParams?.get("subId"));
          } else {
            getCategoryList(item?.id);
          }

          // setCatSubNames(res?.sub_categories[0]);
          // setSubCategoriesListId(res?.sub_categories?.[0]?.id);

          setSubCategTabList(false);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // CATEGORY LISTS
  const getCategoryList = (id) => {
    setSelectedSubCat("");
    setSubCategoriesListsId(id);
    let formdata = new FormData();
    formdata.append("type", 3);
    formdata.append("category_id", id);
    let params = `?page=${1}`;
    formdata.append("order", "desc");
    setListOrder("desc");
    setSubCategoriesListId("");
    catgoryList({ payload: formdata, params })
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          setExploreList(res);
        }
      });
  };

  // CATEGORY LISTS WITH PAGINATE
  const getCategoryListWithPaginate = (id, order) => {
    setSubCategoriesListsId(id);
    setSelectedSubCat("");
    let formdata = new FormData();
    formdata.append("type", 3);
    formdata.append("category_id", id);
    if (order || listOrder !== "") {
      formdata.append("order", order ? order : listOrder);
    } else {
      formdata.append("order", "desc");
      setListOrder("desc");
    }
    let params = `?page=${page}`;
    catgoryList({ payload: formdata, params })
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          setExploreList(res);
        }
      });
  };

  // SUB CATEGORY LIST
  const getSubcategoriesList = (id) => {
    setExploreList([]);
    setSubCategoriesListId(id);
    setSubCategoriesListsId("");
    if (id) {
      let params = `?page=${1}`;
      const formData = new FormData();
      formData.append("subcategory_id", id);
      formData.append("type", 3);
      formData.append("order", "desc");
      setListOrder("desc");
      subcategoryListData({ payload: formData, params })
        .unwrap()
        .then((res) => {
          // console.log(res);
          if (res?.status === "success") {
            setExploreList(res);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };
  const getSubcategoriesListPaginate = (id, order) => {
    setExploreList([]);
    setSubCategoriesListId(id);
    setSubCategoriesListsId("");
    const formData = new FormData();
    if (order || listOrder !== "") {
      formData.append("order", order ? order : listOrder);
    } else {
      formData.append("order", "desc");
      setListOrder("desc");
    }
    if (id) {
      let params = `?page=${page}`;
      formData.append("subcategory_id", id);
      formData.append("type", 3);
      subcategoryListData({ payload: formData, params })
        .unwrap()
        .then((res) => {
          // console.log(res);
          if (res?.status === "success") {
            setExploreList(res);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  const onSubCategoryClick = (item) => {
    getSubcategoriesList(item?.id);
    setSelectedSubCat(item?.id);
    setCatSubNames(item);
    removeQueryParams();
    setListOrder("");
  };

  // GET BANNERS LIST
  const getExploreBanners = (id) => {
    let formData = new FormData();
    formData.append("category_id", id);
    exploreBanner(formData)
      .unwrap()
      .then((res) => {
        setList(res?.explore_banner);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (location?.state?.subcategory_id) {
      showTabListHandler(location?.state?.subcategory_id);
      getExploreBanners(location?.state?.subcategory_id?.id);
    } else if (searchParams?.get("catId") && searchParams?.get("subId")) {
      let item = {
        id: searchParams?.get("catId"),
      };
      let data = {
        id: searchParams?.get("catId"),
        name: searchParams?.get("catName"),
      };
      let datas = {
        id: searchParams?.get("subId"),
        name: searchParams?.get("subName"),
      };
      showTabListHandler(item);
      getExploreBanners(searchParams?.get("catId"));
      setCatNames(data);
      setCatSubNames(datas);
    } else if (searchParams?.get("catId")) {
      let item = {
        id: searchParams?.get("catId"),
      };
      let data = {
        id: searchParams?.get("catId"),
        name: searchParams?.get("catName"),
      };
      showTabListHandler(item);
      setCatNames(data);
      getExploreBanners(searchParams?.get("catId"));
    } else {
      if (subcategoriesListId) {
        // console.log("INSIDE IF");
        getSubcategoriesListPaginate(subcategoriesListId, listOrder);
      } else if (subcategoriesListsId) {
        getCategoryListWithPaginate(subcategoriesListsId, listOrder);
      } else {
        // console.log("OUTSIDE IF");
        getOverallList(listOrder);
      }
    }
  }, [listOrder, searchParams?.get("catId")]);

  // REMOVE PARAMS
  const removeQueryParams = () => {
    const catId = searchParams.get("catId");
    const subId = searchParams.get("subId");
    const catName = searchParams.get("catName");
    const subName = searchParams.get("subName");

    if (catId) {
      // 👇️ delete each query param
      searchParams.delete("catId");

      // 👇️ update state after
      setSearchParams(searchParams);
    }

    if (subId) {
      searchParams.delete("subId");
      setSearchParams(searchParams);
    }

    if (catName) {
      searchParams.delete("catName");
      setSearchParams(searchParams);
    }

    if (subName) {
      searchParams.delete("subName");
      setSearchParams(searchParams);
    }
  };

  return (
    <div>
      {subCategTabList === false ? (
        <Carousel>
          {list?.length > 0 ? (
            list?.map((item, ind) => (
              <Carousel.Item interval={2000} key={ind}>
                <div className="list-banner pt-3 pb-2">
                  <img
                    src={item?.image ? item?.image_url : explore_banner}
                    alt=""
                  />
                </div>
              </Carousel.Item>
            ))
          ) : (
            <Carousel.Item>
              <div className="list-banner pt-3 pb-2">
                <img src={explore_banner} alt="" />
              </div>
            </Carousel.Item>
          )}
        </Carousel>
      ) : (
        <div className="list-banner pt-3 pb-2">
          <img src={explore_banner} alt="" />
        </div>
      )}
      <div className="bread-crum-list d-flex ac-je">
        <button
          className="cust-btn d-flex ac-js fs-14 f2"
          onClick={() => navigate("/")}
        >
          <HomeIcon className="fs-16 me-1" />
          Home
        </button>
        <ChevronRightIcon className="fs-20 me-1" />
        <button
          className="cust-btn d-flex ac-js fs-14 f2"
          onClick={() => getOverallList()}
        >
          Explore
        </button>
        {Object.values(catNames)?.length > 0 && (
          <>
            <ChevronRightIcon className="fs-20 me-1" />
            <button
              className="cust-btn d-flex ac-js fs-14 f2"
              onClick={() => {
                setCatSubNames([]);
                removeQueryParams();
                showTabListHandler(catNames);
                setSubCategoriesListId("");
                setPage(1);
                setListOrder("");
              }}
            >
              {catNames?.name}
            </button>
          </>
        )}
        {exploreList?.explores?.length > 0 &&
          Object.values(catSubNames)?.length > 0 && (
            <>
              <ChevronRightIcon className="fs-20 me-1" />
              <button className="cust-btn d-flex ac-js fs-14 f2">
                {catSubNames?.name}
              </button>
            </>
          )}
      </div>
      <ExploreTab
        showTabList={showTabList}
        setShowTabList={setShowTabList}
        subCategTabList={subCategTabList}
        showTabListHandler={showTabListHandler}
        getExploreBanners={getExploreBanners}
        subcategoriesList={subcategoriesList}
        setPage={setPage}
        selectedSubCat={selectedSubCat}
        onSubCategoryClick={onSubCategoryClick}
        setCatNames={setCatNames}
      />
      <ExploreListBox
        exploreList={exploreList}
        subCategTabList={subCategTabList}
        setPage={setPage}
        page={page}
        listOrder={listOrder}
        setListOrder={setListOrder}
      />
    </div>
  );
};

export default ExploreList;
