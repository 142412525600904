import React from 'react'
import { Col } from 'react-bootstrap'

const FutureRight = () => {
    return (
        <Col xs={12} md={6} className="service-detail-sec col-custom-res align-self-center">
            <h5 className="primary1 font-custom
                ">FUTURE VISION
                <div className='activeLine align-self-center' />
            </h5>
            <div className="my-2 desc-txt font-custom2">
                <p>At Asquare, we believe in innovation as the cornerstone of growth, and nothing exemplifies this belief more than our vision for the future. In an era where digital competence is not just an asset, but a necessity, we envision a community where every individual is equipped with the skills and knowledge to navigate the digital world with confidence. To achieve this, we are planning to expand our educational offerings to include courses in fields such as programming, data science and other emerging technologies.
                </p>
                <div className='my-2 desc-txt'>
                    <p>
                        Building on a solid foundation where our people are already making significant strides in the IT sector, we envision this platform as a launchpad for the next generation. By fostering a culture of technological competence, we aim to inspire and cultivate future leaders, making Parangi Pettai a powerhouse in the digital world.
                    </p>
                </div>
            </div>
        </Col>
    )
}

export default FutureRight