import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import BlurCircularIcon from "@mui/icons-material/BlurCircular";
import AirIcon from "@mui/icons-material/Air";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faLinkedinIn,
  faInstagram,
  faYoutube,
  faWhatsapp,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";

import { Link, useNavigate } from "react-router-dom";
import moment from "moment";

const ClassifiedRight = ({ postDetails }) => {
  const navigate = useNavigate();
  const [pointsList, setPointsList] = useState([]);
  const [FbUrl, setFbUrl] = useState("");
  const [instaUrl, setInstaUrl] = useState("");
  const [ytUrl, setYtUrl] = useState("");
  const [twitterUrl, setTwitterUrl] = useState("");

  useEffect(() => {
    const points = postDetails?.classified?.points;
    const socialLinks = postDetails?.user?.url;
    if (points) {
      const pointsArr = JSON.parse(points);
      if (Array.isArray(pointsArr)) {
        setPointsList(pointsArr);
      } else {
        let temp = [];
        temp.push(JSON.parse(postDetails?.classified?.points));
        setPointsList(temp);
      }
    }
    if (socialLinks) {
      const url = JSON.parse(socialLinks);
      setInstaUrl(url?.[0]);
      setFbUrl(url?.[1]);
      setTwitterUrl(url?.[2]);
      setYtUrl(url?.[3]);
    }
  }, [postDetails]);

  // console.log("postDetails", postDetails?.parent_category?.name);

  return (
    <Col xs={12} md={6} className="service-detail-sec my-1 ps-sm-3 p-lg-3">
      <div className="w-100">
        <h5 className="my-1 primary1">{postDetails?.classified?.title}</h5>
        <div className="d-flex bread-crumb">
          {/* <button
            className="cust-btn"
            onClick={() =>
              navigate("/classified/list", {
                state: { subcategory_id: postDetails?.classified?.categories },
              })
            }
          >
            {postDetails?.parent_category?.name}
          </button> */}
          <Link
            style={{ color: "#0d6efd" }}
            className="cust-btn"
            to={`/classified/list?catId=${postDetails?.classified?.categories?.parent_id}&catName=${postDetails?.parent_category?.name}`}
          >
            {postDetails?.parent_category?.name}
          </Link>
          <p>{" / "}</p>
          {/* <button
            className="cust-btn"
            onClick={() =>
              navigate("/classified/list", {
                state: { subcategory_id: postDetails?.classified },
              })
            }
          >
            {postDetails?.classified?.categories?.name}
          </button> */}
          <Link
            style={{ color: "#0d6efd" }}
            className="cust-btn"
            to={`/classified/list?catId=${postDetails?.classified?.categories?.parent_id}&subId=${postDetails?.classified?.category_id}&catName=${postDetails?.parent_category?.name}&subName=${postDetails?.classified?.categories?.name}`}
          >
            {postDetails?.classified?.categories?.name}
          </Link>
        </div>
        <div className="desc-txt">
          <p className="my-1">{postDetails?.classified?.description}</p>
          <div className="buttet-points">
            {pointsList?.map((item, index) => {
              return (
                <div key={index} className="d-flex my-2">
                  <AirIcon className="bullets-icon me-1" />
                  <p className="">{item}</p>
                </div>
              );
            })}
          </div>
        </div>
        {postDetails?.classified?.rate && (
          <p className="f3 ms-1 opacity-100 price my-1">
            ₹ {postDetails?.classified?.rate}
          </p>
        )}
        <p className="me-1 my-2">
          <span>
            <LocationOnIcon />
          </span>
          <span className="fs-14 ms-1">
            {postDetails?.classified?.location}
          </span>
        </p>
        {/* <h6 className="me-1 opacity-75 d-flex align-items-center">
          <LocationOnOutlinedIcon />
          Pune
        </h6> */}
        <div className="d-flex flex-wrap ac-jb">
          <div className="d-flex flex-wrap ac-jb">
            <a href={`tel:+91 ${postDetails?.user?.mobile}`}>
              <p className="contact-button">
                {/* <span> */}
                <LocalPhoneIcon className="mx-2" />
                {/* </span> */}
                {/* <span> +91 {postDetails?.user?.mobile}</span> */}
              </p>
            </a>
            {postDetails?.user?.secondary_number &&
              postDetails?.user?.secondary_number !== null && (
                <a href={`tel:+91 ${postDetails?.user?.secondary_number}`}>
                  <p className="contact-button">
                    {/* <span> */}
                    <LocalPhoneIcon />
                    {/* </span> */}
                    {/* <span> +91 {postDetails?.user?.secondary_number}</span> */}
                  </p>
                </a>
              )}
          </div>
          <div className="d-flex mt-2 gap-2 ms-1 social-media-icons">
            {FbUrl && (
              <a target="_blank" href={FbUrl}>
                <button className="cust-btn bg-darkBlue text-white">
                  <FontAwesomeIcon icon={faFacebook} size="sm" />
                </button>
              </a>
            )}
            {instaUrl && (
              <a href={instaUrl} target="_blank">
                <button className="cust-btn bg-pinks">
                  <FontAwesomeIcon
                    icon={faInstagram}
                    size="sm"
                    style={{ color: "#ffff" }}
                  />
                </button>
              </a>
            )}
            {ytUrl && (
              <a href={ytUrl} target="_blank">
                <button className="cust-btn bg-red">
                  <FontAwesomeIcon
                    icon={faYoutube}
                    size="sm"
                    style={{ color: "#ffff" }}
                  />
                </button>
              </a>
            )}
            {twitterUrl && (
              <a href={twitterUrl} target="_blank">
                <button className="cust-btn bg-lt-primary">
                  <FontAwesomeIcon
                    icon={faTwitter}
                    size="sm"
                    style={{ color: "#ffff" }}
                  />
                </button>
              </a>
            )}
            {postDetails?.user?.w_number && (
              <a
                href={`https://wa.me/91${postDetails?.user?.w_number}`}
                target="_blank"
              >
                <button className="cust-btn bg-greens">
                  <FontAwesomeIcon
                    icon={faWhatsapp}
                    size="sm"
                    style={{ color: "#ffff" }}
                  />
                </button>
              </a>
            )}
          </div>
        </div>
        <div className="d-flex ac-jb post-by">
          <p className="ms-1 d-flex align-items-center mt-2">
            <span className="ms-1">Posted by </span>
            <span className="ms-1 text-dark f3">{postDetails?.user?.name}</span>
          </p>
          <p className="ms-1 d-flex align-items-center text-dark mt-2">
            <span className="ms-1 f3">
              {moment(postDetails?.classified?.created_at).fromNow()}
            </span>
          </p>
        </div>
      </div>
    </Col>
  );
};

export default ClassifiedRight;
