import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { usePostListMutation } from "../../redux/api/api";

const PostTab = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [count, setCount] = useState("");

  const [postLists] = usePostListMutation();

  const { pathname } = location;
  const splitLocation = pathname.split("/");
  // console.log("HELLO____", splitLocation);

  useEffect(() => {
    if (splitLocation[2] === undefined) {
      navigate("/postList/classifiedPost");
    }
  }, []);

  const getList = () => {
    let formdata = new FormData();
    if (splitLocation[2] === undefined) {
      formdata.append("type", 1);
    } else if (splitLocation[2] === "classifiedPost") {
      formdata.append("type", 1);
    } else if (splitLocation[2] === "servicesPost") {
      formdata.append("type", 2);
    } else if (splitLocation[2] === "explorePost") {
      formdata.append("type", 3);
    } else if (splitLocation[2] === "jobPostList") {
      formdata.append("type", 4);
    }

    postLists(formdata)
      .unwrap()
      .then((res) => {
        // console.log(res);
        let temp = [];
        if (res?.status == "success") {
          res?.postList?.map((item, ind) => {
            item?.classifieds?.map((items, inds) => {
              temp.push(items);
            });
          });
          setCount(res?.postList);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getList();
  }, [splitLocation[2]]);

  return (
    <div className="d-flex tl-tb-box bg-sec py-0 px-2 ac-jb ms-0">
      <div className="d-flex tl-tb-box bg-sec py-3 px-2">
        <button
          onClick={() => navigate("/postList/classifiedPost")}
          className={`cust-btn ${
            splitLocation[2] === "classifiedPost" && "active"
          }`}
        >
          Classified
        </button>
        <button
          onClick={() => navigate("/postList/servicesPost")}
          className={`cust-btn ${
            splitLocation[2] === "servicesPost" && "active"
          }`}
        >
          Services
        </button>
        <button
          onClick={() => navigate("/postList/jobPostList")}
          className={`cust-btn ${
            splitLocation[2] === "jobPostList" && "active"
          }`}
        >
          Job
        </button>
        <button
          onClick={() => navigate("/postList/explorePost")}
          className={`cust-btn ${
            splitLocation[2] === "explorePost" && "active"
          }`}
        >
          Explore
        </button>
      </div>
      <p className="d-none d-md-block">Result - {count ? count?.length : ""}</p>
    </div>
  );
};

export default PostTab;
