import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import BlurCircularIcon from "@mui/icons-material/BlurCircular";
import AirIcon from "@mui/icons-material/Air";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import SchoolIcon from "@mui/icons-material/School";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import LocalPharmacyIcon from "@mui/icons-material/LocalPharmacy";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faLinkedinIn,
  faInstagram,
  faYoutube,
  faWhatsapp,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";

import { Link, useNavigate } from "react-router-dom";
import moment from "moment";

const ExploreRight = ({ postDetails }) => {
  const [pointsList, setPointsList] = useState([]);
  const [FbUrl, setFbUrl] = useState("");
  const [instaUrl, setInstaUrl] = useState("");
  const [ytUrl, setYtUrl] = useState("");
  const [twitterUrl, setTwitterUrl] = useState("");
  const [food, setFood] = useState("");
  const [health, sethealth] = useState("");

  useEffect(() => {
    const points = postDetails?.explore?.points;
    const socialLinks = postDetails?.explore?.user?.url;
    setFood(postDetails?.explore?.categories?.parent?.name);
    sethealth(postDetails?.explore?.categories?.name);
    if (points) {
      const pointsArr = JSON.parse(points);
      if (Array.isArray(pointsArr)) {
        setPointsList(pointsArr);
      } else {
        let temp = [];
        temp.push(JSON.parse(postDetails?.explore?.points));
        setPointsList(temp);
      }
    }
    if (socialLinks) {
      const url = JSON.parse(socialLinks);
      setInstaUrl(url?.[0]);
      setFbUrl(url?.[1]);
      setTwitterUrl(url?.[2]);
      setYtUrl(url?.[3]);
    }
  }, [postDetails]);

  // console.log(postDetails);

  return (
    <Col
      xs={12}
      md={6}
      className="service-detail-sec my-1 ps-sm-3 p-lg-3 col-custom-res"
    >
      {/* <div className="w-100"> */}
      <h5 className="my-1 primary1 ">{postDetails?.explore?.title}</h5>
      <h6 className="my-1 primary1 f4 fs-18">{postDetails?.explore?.name}</h6>
      <div className="d-flex bread-crumb ">
        <Link
          style={{ color: "#0d6efd" }}
          className="cust-btn"
          to={`/explore/list?catId=${postDetails?.explore?.categories?.parent_id}&catName=${postDetails?.explore?.categories?.parent?.name}`}
        >
          {postDetails?.explore?.categories?.parent?.name}
        </Link>
        <p> / </p>
        <Link
          style={{ color: "#0d6efd" }}
          className="cust-btn"
          to={`/explore/list?catId=${postDetails?.explore?.categories?.parent_id}&subId=${postDetails?.explore?.category_id}&catName=${postDetails?.explore?.categories?.parent?.name}&subName=${postDetails?.explore?.categories?.name}`}
        >
          {postDetails?.explore?.categories?.name}
        </Link>
      </div>
      <div className="desc-txt">
        <p className="my-1">{postDetails?.explore?.description}</p>
        <div className="buttet-points">
          {pointsList?.map((item, index) => {
            return (
              <div key={index} className="d-flex my-2">
                <AirIcon className="bullets-icon me-1" />
                <p className="">{item}</p>
              </div>
            );
          })}
        </div>
      </div>
      {/* <p className="f3 ms-1 opacity-100 my-1 price">₹ 20,000</p> */}
      <h6 className="me-1 opacity-75 d-flex align-items-center">
        {postDetails?.explore?.key_field?.length > 0 &&
        postDetails?.explore?.categories?.name
          ?.toLowerCase()
          .startsWith("cli") ? (
          <div>
            <p className="mb-2">
              <LocationCityIcon />{" "}
              {" " + postDetails?.explore?.key_field[0]?.facility}
            </p>
            <p>{"₹ " + postDetails?.explore?.rate}</p>
          </div>
        ) : !food?.toLowerCase().startsWith("fo") ? (
          <div>
            <p>
              <LocationOnOutlinedIcon />
              {postDetails?.explore?.location}
            </p>
            <p className="mt-2 mx-1">{"₹ " + postDetails?.explore?.rate}</p>
          </div>
        ) : null}
      </h6>
      <div>
        {postDetails?.explore?.key_field?.length > 0 &&
          health?.toLowerCase().startsWith("med") && (
            <div className="mb-2 mt-2">
              <p className="mb-1">
                <SchoolIcon />
                {" " + postDetails?.explore?.key_field[0]?.education}
              </p>
              <p className="mb-1">
                <LocalPharmacyIcon />
                {" " + postDetails?.explore?.key_field[0]?.speciality}
              </p>
              <p className="mb-1">
                <LocationCityIcon />
                {" " + postDetails?.explore?.key_field[0]?.facility}
              </p>
            </div>
          )}
        {postDetails?.explore?.key_field?.length > 0 &&
          health?.toLowerCase().startsWith("lab") && (
            <div className="mb-2 mt-2">
              <p className="mb-1">
                <SchoolIcon />
                {" " + postDetails?.explore?.key_field[0]?.education}
              </p>
              <p className="mb-1">
                <LocalPharmacyIcon />
                {" " + postDetails?.explore?.key_field[0]?.speciality}
              </p>
              <p className="mb-1">
                <LocationCityIcon />
                {" " + postDetails?.explore?.key_field[0]?.facility}
              </p>
            </div>
          )}
        {food?.toLowerCase().startsWith("fo") && (
          <>
            <p>
              <LocationOnOutlinedIcon />
              {postDetails?.explore?.location}
            </p>
            <p className="mt-2 mb-2 mx-1">
              {"₹ " + postDetails?.explore?.rate}
            </p>
            <div className="d-flex flex-wrap ac-jb mt-2 mb-2">
              <a
                className="food_btn d-flex"
                href={postDetails?.explore?.pdf_path}
                target="_blank"
              >
                <div className="py-1 mb-2">
                  <PlayArrowIcon className="food-play" />
                </div>
                <div>
                  <p className="food-menu mt-1">View Menu</p>
                </div>
              </a>
            </div>
          </>
        )}
        {postDetails?.explore?.key_time?.length > 0 && (
          <div className="d-flex flex-wrap ac-jb mt-3 mb-2 food-table">
            <table className="table w-100 food-table-tab">
              <thead className="text-center">
                <th>Days</th>
                <th>Open-Colse</th>
              </thead>
              <tbody className="text-center">
                {postDetails?.explore?.key_time?.map((item, ind) => {
                  return (
                    <tr key={ind}>
                      <td>{item?.day}</td>
                      <td>{item?.time}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <div className="d-flex flex-wrap ac-jb">
        <div className="d-flex flex-wrap ac-jb">
          <a href={`tel:+91 ${postDetails?.explore?.user?.mobile}`}>
            {" "}
            {/* <p className="contact-button">
              <span>
                <LocalPhoneIcon />
              </span>
              <span> +91 {postDetails?.explore?.user?.mobile}</span>
            </p> */}{" "}
            <p className="contact-button">
              <LocalPhoneIcon className="mx-2" />
            </p>
          </a>
          {postDetails?.explore?.user?.secondary_number &&
            postDetails?.explore?.user?.secondary_number !== null && (
              <a
                href={`tel:+91 ${postDetails?.explore?.user?.secondary_number}`}
              >
                {/* <p className="contact-button">
                  <span>
                    <LocalPhoneIcon />
                  </span>
                  <span>
                    {" "}
                    +91 {postDetails?.explore?.user?.secondary_number}
                  </span>
                </p> */}
                <p className="contact-button">
                  <LocalPhoneIcon className="mx-2" />
                </p>
              </a>
            )}
        </div>

        <div className="d-flex mt-2 gap-2 ms-1 social-media-icons">
          {FbUrl && (
            <a target="_blank" href={FbUrl}>
              <button className="cust-btn bg-darkBlue text-white">
                <FontAwesomeIcon icon={faFacebook} size="sm" />
              </button>
            </a>
          )}
          {instaUrl && (
            <a href={instaUrl} target="_blank">
              <button className="cust-btn bg-pinks">
                <FontAwesomeIcon
                  icon={faInstagram}
                  size="sm"
                  style={{ color: "#ffff" }}
                />
              </button>
            </a>
          )}
          {ytUrl && (
            <a href={ytUrl} target="_blank">
              <button className="cust-btn bg-red">
                <FontAwesomeIcon
                  icon={faYoutube}
                  size="sm"
                  style={{ color: "#ffff" }}
                />
              </button>
            </a>
          )}
          {twitterUrl && (
            <a href={twitterUrl} target="_blank">
              <button className="cust-btn bg-lt-primary">
                <FontAwesomeIcon
                  icon={faTwitter}
                  size="sm"
                  style={{ color: "#ffff" }}
                />
              </button>
            </a>
          )}

          {postDetails?.explore?.user?.w_number && (
            <a
              href={`https://wa.me/91${postDetails?.explore?.user?.w_number}`}
              target="_blank"
            >
              <button className="cust-btn bg-greens">
                <FontAwesomeIcon
                  icon={faWhatsapp}
                  size="sm"
                  style={{ color: "#ffff" }}
                />
              </button>
            </a>
          )}
        </div>
      </div>
      <div className="d-flex ac-jb post-by">
        <p className="ms-1 d-flex align-items-center mt-2">
          <span className="ms-1">Posted by </span>
          <span className="ms-1 text-dark f3">
            {postDetails?.explore?.user?.name}
          </span>
        </p>
        <p className="ms-1 d-flex align-items-center text-dark mt-2">
          <span className="ms-1 f3">
            {moment(postDetails?.explore?.created_at).fromNow()}
          </span>
        </p>
      </div>
      {/* </div> */}
    </Col>
  );
};

export default ExploreRight;
