import React from 'react'
import DigitalLeft from './DigitalLeft'
import DigitalRight from './DigitalRight'

const DigitalComp = () => {
    return (
        <div className="bg-white my-5 w-90">
            <div className='d-flex flex-wrap flex-wrap justify-content-between  align-items-cente comp-hide'>
                <DigitalRight />
                <DigitalLeft />
            </div>
            <div className="d-flex flex-wrap justify-content-around  align-items-cente comp-show"
            >
                <DigitalLeft />
                <DigitalRight />
            </div>
        </div>
    )
}

export default DigitalComp