import { Col } from "react-bootstrap";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useEffect, useState } from "react";
import { useCategoryListQuery } from "../../redux/api/api";
import { Link, useNavigate } from "react-router-dom";
import { faL } from "@fortawesome/free-solid-svg-icons";
import { arrow } from "../../assets/img";

function FooterFirst() {
  const navigate = useNavigate();
  const [categoryList, setCategoryList] = useState({});
  // RTK QUERY
  const { data: categoryData, isSuccess: isCategorySuccess } =
    useCategoryListQuery();
  // console.log(categoryList?.classified_list?.length)
  useEffect(() => {
    if (isCategorySuccess) {
      // console.log(categoryData);
      setCategoryList(categoryData);
    }
  }, [categoryData]);

  const [listshow, setListShow] = useState(false);
  const [showService, setListService] = useState(false);
  const [listJob, setListJob] = useState(false);
  const [listExplore, setListExplore] = useState(false);

  const menuShow = () => {
    setListShow(!listshow);
  };

  // SERVICE
  const menuSevice = () => {
    setListService(!showService);
  };

  // JOB
  const menuJob = () => {
    setListJob(!listJob);
  };

  // EXPLORE
  const menuExplore = () => {
    setListExplore(!listExplore);
  };

  return (
    <Col xs={12} className="footer-one-sec">
      <div className="footer-one-inner-cont">
        <Col>
          <ul className="class-list-cont">
            <li>
              <button
                className="class-active"
                style={{ fontFamily: "f3", fontSize: "18px" }}
                onClick={() => navigate("/classified/list")}
              >
                Classified
              </button>
            </li>
            {!listshow &&
              categoryList?.classified_list?.slice(0, 4).map((item) => {
                return (
                  <li key={item?.id}>
                    <Link
                      to={`/classified/list?catId=${item?.id}&catName=${item?.name}`}
                    >
                      <button className="footer-btns">{item?.name}</button>
                    </Link>
                  </li>
                );
              })}

            {listshow &&
              categoryList?.classified_list?.map((item) => {
                return (
                  <li key={item?.id}>
                    <Link
                      to={`/classified/list?catId=${item?.id}&catName=${item?.name}`}
                    >
                      <button className="footer-btns">{item?.name}</button>
                    </Link>
                  </li>
                );
              })}

            {categoryList?.classified_list?.length > 4 && (
              <li
                className="d-flex flew-row"
                style={{
                  alignItems: "center",
                }}
              >
                <button
                  onClick={menuShow}
                  className="readtext"
                  style={{ color: "#0c2348", fontFamily: "f3" }}
                >
                  {listshow ? "Less" : "More..."}
                </button>
                <button onClick={menuShow}>
                  <img
                    src={arrow}
                    style={{
                      height: "20px",
                      width: "20px",
                    }}
                  />
                </button>
              </li>
            )}
          </ul>
        </Col>
        <Col>
          <ul className="footer-one-list-cont">
            <li>
              <button
                className="service-active"
                style={{ fontFamily: "f3", fontSize: "18px" }}
                onClick={() => navigate("/service/list")}
              >
                Services
              </button>
            </li>
            {!showService &&
              categoryList?.service_list?.slice(0, 4)?.map((item) => {
                return (
                  <li key={item?.id}>
                    <Link
                      to={`/service/list?catId=${item?.id}&catName=${item?.name}`}
                    >
                      <button className="footer-btns">{item?.name}</button>
                    </Link>
                  </li>
                );
              })}
            {showService &&
              categoryList?.service_list?.map((item) => {
                return (
                  <li key={item?.id}>
                    <Link
                      to={`/service/list?catId=${item?.id}&catName=${item?.name}`}
                    >
                      <button className="footer-btns">{item?.name}</button>
                    </Link>
                  </li>
                );
              })}
            {categoryList?.service_list?.length > 4 && (
              <li
                className="d-flex flew-row"
                style={{
                  alignItems: "center",
                }}
              >
                <button
                  onClick={menuSevice}
                  className="readtext"
                  style={{ color: "#0c2348", fontFamily: "f3" }}
                >
                  {showService ? "Less" : "More..."}
                </button>
                <button onClick={menuSevice}>
                  <img
                    src={arrow}
                    style={{
                      height: "20px",
                      width: "20px",
                    }}
                  />
                </button>
              </li>
            )}
            {/* <li>
              <button className="primary f2 my-1 fs-14">
                <span> More</span>
                <span>
                  <ArrowRightAltIcon />
                </span>
              </button>
            </li> */}
          </ul>
        </Col>
        <Col>
          <ul className="footer-one-list-cont">
            <li>
              <button
                className="jobs-active"
                style={{ fontFamily: "f3", fontSize: "18px" }}
                onClick={() => navigate("/job/list")}
              >
                Jobs
              </button>
            </li>
            {!listJob &&
              categoryList?.jobs_list?.slice(0, 4)?.map((item) => {
                return (
                  <li key={item?.id}>
                    <Link
                      to={`/job/list?catId=${item?.id}&catName=${item?.name}`}
                    >
                      <button className="footer-btns">{item?.name}</button>
                    </Link>
                  </li>
                );
              })}

            {listJob &&
              categoryList?.jobs_list?.map((item) => {
                return (
                  <li key={item?.id}>
                    <Link
                      to={`/job/list?catId=${item?.id}&catName=${item?.name}`}
                    >
                      <button className="footer-btns">{item?.name}</button>
                    </Link>
                  </li>
                );
              })}
            {categoryList?.jobs_list?.length > 4 && (
              <li
                className="d-flex flew-row"
                style={{
                  alignItems: "center",
                }}
              >
                <button
                  onClick={menuJob}
                  className="readtext"
                  style={{ color: "#0c2348", fontFamily: "f3" }}
                >
                  {listJob ? "Less" : "More..."}
                </button>
                <button onClick={menuJob}>
                  <img
                    src={arrow}
                    style={{
                      height: "20px",
                      width: "20px",
                    }}
                  />
                </button>
              </li>
            )}
          </ul>
        </Col>
        <Col>
          <ul className="footer-one-list-cont">
            <li>
              <button
                className="explore-active"
                style={{ fontFamily: "f3", fontSize: "18px" }}
                onClick={() => navigate("/explore/list")}
              >
                Explore
              </button>
            </li>
            {!listExplore &&
              categoryList?.explores_list?.slice(0, 4)?.map((item) => {
                return (
                  <li key={item?.id}>
                    <Link
                      to={`/explore/list?catId=${item?.id}&catName=${item?.name}`}
                    >
                      <button className="footer-btns">{item?.name}</button>
                    </Link>
                  </li>
                );
              })}

            {listExplore &&
              categoryList?.explores_list?.map((item) => {
                return (
                  <li key={item?.id}>
                    <Link
                      to={`/explore/list?catId=${item?.id}&catName=${item?.name}`}
                    >
                      <button className="footer-btns">{item?.name}</button>
                    </Link>
                  </li>
                );
              })}
            {categoryList?.explores_list?.length > 4 && (
              <li
                className="d-flex flew-row"
                style={{
                  alignItems: "center",
                }}
              >
                <button
                  onClick={menuExplore}
                  className="readtext"
                  style={{ color: "#0c2348", fontFamily: "f3" }}
                >
                  {listExplore ? "Less" : "More..."}
                </button>
                <button onClick={menuExplore}>
                  <img
                    src={arrow}
                    style={{
                      height: "20px",
                      width: "20px",
                    }}
                  />
                </button>
              </li>
            )}
            {/* <li>
              <button className="primary f2 my-1 fs-14">
                <span> More</span>
                <span>
                  <ArrowRightAltIcon />
                </span>
              </button>
            </li> */}
          </ul>
        </Col>
      </div>
    </Col>
  );
}

export default FooterFirst;
