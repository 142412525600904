import React from 'react'
import { Col } from 'react-bootstrap'

const DigitalRight = () => {
    return (
        <Col xs={12} md={6} className="service-detail-sec col-custom-res align-self-center">
            <h5 className="primary1 font-custom
                ">DIGITAL
                <div className='activeLine align-self-center' />
            </h5>
            <div className="my-2 desc-txt font-custom2">
                <p>In our quest to stay connected and supply prompt, relevant information to our community, we have initiated Asquare Digital. a dedicated website for Parangi Pettai. Our aim is to ensure that data and essential information reach every single household in the community, thereby fostering better decision-making and enhancing local development.
                </p>
                <div className='my-2 desc-txt'>
                    <p>
                        As we continue to serve Parangi Pettai, we are immensely grateful for the support we have received from our community. We look forward to forging stronger bonds, unlocking more opportunities, and contributing to the educational advancement of our beloved town.
                    </p>
                </div>
            </div>
        </Col>
    )
}

export default DigitalRight