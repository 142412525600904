// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { profile1, sofa } from "../../../assets/img";
import PlaceIcon from "@mui/icons-material/Place";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";
import { exploreJson } from "../../../redux/api/DummyJson";
import moment from "moment/moment";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import DateRangeIcon from "@mui/icons-material/DateRange";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import useUser from "../../../redux/store/userData";
import {
  useCategoryDeleteMutation,
  usePostListMutation,
} from "../../../redux/api/api";
import ConfirmPopup from "../../Popup/ConfirmPopup";

const ExploreBox = ({ subCategTabList, method, type, exploreList }) => {
  const { user } = useUser();
  const navigate = useNavigate();
  const [postLists] = usePostListMutation();
  const [deletePost] = useCategoryDeleteMutation();
  const [list, setList] = useState([]);
  const [disableDeleteBtn, setDisableDeleteBtn] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});
  const [deletePopup, setDeletePopup] = useState(false);

  useEffect(() => {
    // console.log(exploreList);
    setList(exploreList?.explores);
  }, [exploreList]);

  const getList = () => {
    let formdata = new FormData();
    formdata.append("type", 3);
    postLists(formdata)
      .unwrap()
      .then((res) => {
        let temp = [];
        if (res?.status == "success") {
          if (Array.isArray(res?.postList)) {
            // res?.postList?.map((item, ind) => {
            //   item?.classifieds?.map((items, inds) => {
            //     temp.push(items);
            //   });
            // });
            // let reverse = [...res?.postList].reverse();
            // setList(reverse);

            temp = [...res?.postList];
          } else {
            res?.postList?.data?.map((item, ind) => {
              temp.push(item);
            });
          }
          setList(temp?.reverse());
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (type == "home") {
      //
    } else {
      getList();
    }
  }, [type]);

  const togglePopup = () => {
    setDeletePopup(!deletePopup);
  };

  const onClickDelete = (item) => {
    setDeleteItem(item);
    togglePopup();
  };

  const deleteApi = () => {
    setDisableDeleteBtn(true);
    const formData = new FormData();
    formData.append("post_id", deleteItem?.id);
    formData.append("type", 3); // {1->classified,2->services,3->explores,4->jobs}

    deletePost(formData)
      .unwrap()
      .then((res) => {
        if (res?.status === "success") {
          // console.log(res);
          // getList();
          window.location.reload();
          setDisableDeleteBtn(false);
          setDeleteItem({});
        }
      })
      .catch((err) => {
        console.log(err);
        setDisableDeleteBtn(false);
        togglePopup();
      });
  };

  return (
    <div className="tl-classified-box d-flex flex-wrap">
      {deletePopup && (
        <ConfirmPopup
          onCloseClick={togglePopup}
          message={"Are you sure you want delete"}
          disableBtn={disableDeleteBtn}
          onConfirmClick={deleteApi}
        />
      )}
      {list?.map((item, ind) => {
        let clinic = item?.categories?.name;
        // console.log("cl", clinic);
        return (
          <Col className={` ${method == "TabMethod" && " p-1"}`} xs={6} lg={6}>
            <div
              className={`p-2 pb-3 mb-2 ${
                method == "TabMethod" && "bg-sec rounded rounded-3"
              }`}
            >
              <div className="classified-box d-md-flex p-2 w-100">
                <div className="lft-img-box lft-img-box2">
                  {clinic?.toLowerCase().startsWith("cli") ? (
                    <img
                      src={
                        item?.user?.image
                          ? item?.user?.image_path
                          : item?.user?.gender == "Male"
                          ? "https://img.freepik.com/free-photo/smiley-handsome-man-posing_23-2148911841.jpg?w=740&t=st=1689942327~exp=1689942927~hmac=bda76672938776892327321166719cf5f4f117e952887c2f31377b8cacaba331"
                          : "https://st3.depositphotos.com/6179956/15450/i/1600/depositphotos_154504514-stock-photo-university-student-girl-looking-happy.jpg"
                      }
                      alt=""
                    />
                  ) : (
                    <img
                      src={
                        item?.image
                          ? item?.image_path
                          : item?.user?.image
                          ? item?.user?.image_path
                          : sofa
                      }
                      alt=""
                    />
                  )}
                </div>
                <div className="rit-box ms-md-2">
                  <h3
                  // style={{
                  //   textTransform: "capitalize",
                  //   whiteSpace: "nowrap",
                  //   overflow: "hidden",
                  //   textOverflow: "ellipsis",
                  // }}
                  >
                    {item?.title?.length > 20
                      ? item?.title?.slice(0, 20) + "..."
                      : item?.title}
                    {/* {item?.title} */}
                  </h3>
                  {subCategTabList === true && (
                    <h4 style={{ textTransform: "capitalize" }}>
                      {item?.categories?.parent?.name}
                    </h4>
                  )}
                  {/* <h5>₹ {cost}</h5> */}
                  <div
                    style={{ textTransform: "capitalize" }}
                    className="d-flex ac-jb w-100 mt-1 post-by"
                  >
                    <p className="d-flex">
                      <PlaceIcon className="fs-18" />
                      <span className="">{item?.location}</span>
                    </p>
                  </div>
                  <div
                    style={{ textTransform: "capitalize" }}
                    className="d-flex ac-jb w-100 mt-1 post-by"
                  >
                    <p className="d-flex">
                      <InsertInvitationIcon className="fs-18" />
                      <span className="">
                        <b>{moment(item?.created_at).format("DD MMM yyyy")}</b>
                      </span>
                    </p>
                  </div>
                  <button
                    onClick={() =>
                      navigate("/explore/detail", {
                        state: { post_id: item?.id },
                      })
                    }
                    className="d-flex ac-js explore-btn cust-btn"
                  >
                    <p className="">Explore More</p>
                    <ArrowForwardIcon className="fs-18" />
                  </button>
                  <div className="d-sm-flex flex-wrap ac-jb w-100 mt-2 mt-md-4 post-by">
                    <p className="d-flex">
                      Post by
                      <span className="ms-1 text-capitalize">
                        <b>
                          {" "}
                          {method == "TabMethod"
                            ? user?.name
                            : item?.user?.name}
                        </b>
                      </span>
                    </p>
                    <p className="d-flex">
                      <span className="me-1">
                        <b>{moment(item?.created_at).fromNow()} </b>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              {method == "TabMethod" && (
                <div className="d-flex ac-je">
                  <button
                    className="cust-btn p-1 edit-delet-icon-tablist"
                    onClick={() =>
                      navigate("/editPost", {
                        state: { data: item, type: "explore" },
                      })
                    }
                  >
                    <BorderColorIcon className=" text-white" />
                  </button>
                  <button
                    disabled={disableDeleteBtn}
                    onClick={() => onClickDelete(item)}
                    className="cust-btn p-1 edit-delet-icon-tablist"
                  >
                    <DeleteIcon className=" text-white" />
                  </button>
                </div>
              )}
            </div>
          </Col>
        );
      })}
    </div>
  );
};

export default ExploreBox;
