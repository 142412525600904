import React, { useEffect, useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useCategoryListQuery } from "../../../redux/api/api";
import { useSearchParams } from "react-router-dom";

const JobTab = ({
  showTabListHandler,
  showTabList,
  setShowTabList,
  subCategTabList,
  setPage,
  subcategoriesList,
  selectedSubCat,
  onSubCategoryClick,
  setCatNames,
}) => {
  const [categoryList, setCategoryList] = useState({});
  const [selectedCategory, setSelectedCategory] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  // RTK QUERY
  const { data: categoryData, isSuccess: isCategorySuccess } =
    useCategoryListQuery();

  useEffect(() => {
    if (isCategorySuccess) {
      // console.log(categoryData);
      setCategoryList(categoryData);
    }
  }, [categoryData]);

  useEffect(() => {
    if (searchParams?.get("catName")) {
      setSelectedCategory(searchParams?.get("catName"));
    }
  }, [searchParams?.get("catName")]);

  return (
    <div className="tl-classif-tab bg-white position-relative">
      <div className="d-md-flex as-jb">
        <div className="title-h4">
          {subCategTabList ? (
            <>
              <p>OverAll</p>
              <div className="line1"></div>
            </>
          ) : (
            <p className="orange">{selectedCategory}</p>
          )}
        </div>

        {subCategTabList ? (
          <div
            className={`tab-txt m-auto d-flex flex-wrap ${showTabList ? "justify-content-center" : "justify-content-start"
              }`}
            style={{ height: showTabList ? "30px" : "auto" }}
          >
            {categoryList?.jobs_list?.map((item, index) => {
              return (
                <button
                  key={item?.id}
                  className="cust-btn"
                  onClick={() => {
                    setPage(1);
                    showTabListHandler(item);
                    setSelectedCategory(item?.name);
                    setCatNames(item);
                  }}
                >
                  <b className="d-flex">
                    {item?.name}
                    <span className="d-block d-md-none ms-1">|</span>
                  </b>
                </button>
              );
            })}
          </div>
        ) : (
          <div
            className={`tab-txt m-auto d-flex flex-wrap ${showTabList ? "justify-content-center" : "justify-content-start"
              }`}
            style={{ height: showTabList ? "30px" : "auto" }}
          >
            {subcategoriesList?.map((item, index) => {
              return (
                <button
                  onClick={() => {
                    setPage(1);
                    onSubCategoryClick(item);
                  }}
                  className={`cust-btn ${item?.id === selectedSubCat && "active"
                    }`}
                >
                  {item?.name}
                  <span className="d-block d-md-none  ms-1">|</span>
                </button>
              );
            })}
          </div>
        )}

        {subCategTabList ? null : (
          <button
            onClick={() => setShowTabList(!showTabList)}
            className="more-btn d-flex rounded px-2 py-1 cust-btn ac-jc"
          >
            <p>{showTabList ? "More" : "Less"}</p>
            <ArrowForwardIcon />
          </button>
        )}
      </div>
    </div>
  );
};

export default JobTab;
