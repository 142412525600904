import Carousel from "react-bootstrap/Carousel";
import Banner_image from "../../assets/img/banner_img.png";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import { useBannerListQuery, useSearchMutation } from "../../redux/api/api";
import { home_bann } from "../../assets/img";
import { Link, useNavigate } from "react-router-dom";

function FadingBanner() {
  const navigate = useNavigate();
  const [bannerList, setBannerList] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [searchList, setSearchList] = useState([]);

  // RTK QUERY
  const { isSuccess: isBannerSuccess, data: bannerData } = useBannerListQuery();
  const [search] = useSearchMutation();

  useEffect(() => {
    // console.log(bannerData?.banners);
    setBannerList(bannerData?.banners);
  }, [bannerData]);

  const getSearch = () => {
    let formdata = new FormData();

    formdata.append("search", searchInput);
    search(formdata)
      .unwrap()
      .then((res) => {
        let temp = [];
        if (res?.status == "success") {
          res?.classified_post?.map((item) => {
            temp.push(item);
          });

          res?.explore_post?.map((item) => {
            temp.push(item);
          });

          res?.service_post?.map((item) => {
            temp.push(item);
          });
        }

        let check = Array.isArray(res?.searched_results);

        if (check) {
          setSearchList(res?.searched_results);
        } else {
          setSearchList(Object.values(res?.searched_results));
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (searchInput?.length > 0) {
      getSearch();
    } else {
      setSearchList([]);
    }
  }, [searchInput]);

  // console.log("searchList", searchList);

  return (
    <div className="position-relative">
      <div className="position-relative hide-arrow-slide">
        <Carousel fade>
          {bannerList?.length > 0 ? (
            bannerList?.map((item) => {
              return (
                <Carousel.Item
                  interval={1000}
                  key={item?.id}
                  // style={{ height: "76vh" }}
                >
                  <img
                    src={item?.image_url}
                    // style={{ objectFit: "contain" }}
                    className="banner-img-cont"
                    alt="banner-img"
                  />
                </Carousel.Item>
              );
            })
          ) : (
            <Carousel.Item
              interval={1000}
              // key={item?.id}
              // style={{ height: "76vh" }}
            >
              <img
                src={home_bann}
                // style={{ objectFit: "contain" }}
                className="banner-img-cont " // banner-img-cont2
                alt="banner-img"
              />
            </Carousel.Item>
          )}
        </Carousel>
        <div className="banner-search-cont">
          <input
            className="banner-search"
            placeholder="Search here"
            onChange={(e) => setSearchInput(e.target.value)}
            value={searchInput}
          />
          <SearchIcon />
        </div>
      </div>
      <div className="d-flex  align-items-center justify-content-center">
        {searchList?.length > 0 && (
          <div
            className="bg-danger-custon position-absolute z-3 top-100 border border-dark"
            style={
              searchList?.length > 20
                ? { height: "500px", overflow: "auto" }
                : { overflow: "auto" }
            }
          >
            {searchList?.map((item) => {
              return item?.id &&
                item?.parent_id &&
                item?.parent_name &&
                !item?.type_name ? (
                item?.type == 3 ? (
                  <Link
                    to={`/explore/list?catId=${item?.parent_id}&subId=${item?.id}&catName=${item?.parent_name}&subName=${item?.title}`}
                  >
                    <p className="my-2" role="button">
                      {item.title}
                    </p>
                  </Link>
                ) : item?.type == 1 ? (
                  <Link
                    to={`/classified/list?catId=${item?.parent_id}&subId=${item?.id}&catName=${item?.parent_name}&subName=${item?.title}`}
                  >
                    <p className="my-2" role="button">
                      {item.title}
                    </p>
                  </Link>
                ) : item?.type == 2 ? (
                  <Link
                    to={`/service/list?catId=${item?.parent_id}&subId=${item?.id}&catName=${item?.parent_name}&subName=${item?.title}`}
                  >
                    <p className="my-2" role="button">
                      {item.title}
                    </p>
                  </Link>
                ) : item?.type == 4 ? (
                  <Link
                    to={`/job/list?catId=${item?.parent_id}&subId=${item?.id}&catName=${item?.parent_name}&subName=${item?.title}`}
                  >
                    <p className="my-2" role="button">
                      {item.title}
                    </p>
                  </Link>
                ) : null
              ) : item?.id &&
                !item?.parent_id &&
                !item?.parent_name &&
                !item?.type_name ? (
                item?.type == 1 ? (
                  <Link
                    to={`/classified/list?catId=${item?.id}&catName=${item?.title}`}
                  >
                    <p className="my-2" role="button">
                      {item.title}
                    </p>
                  </Link>
                ) : item?.type == 2 ? (
                  <Link
                    to={`/service/list?catId=${item?.id}&catName=${item?.title}`}
                  >
                    <p className="my-2" role="button">
                      {item.title}
                    </p>
                  </Link>
                ) : item?.type == 3 ? (
                  <Link
                    to={`/explore/list?catId=${item?.id}&catName=${item?.title}`}
                  >
                    <p className="my-2" role="button">
                      {item.title}
                    </p>
                  </Link>
                ) : item?.type == 4 ? (
                  <Link
                    to={`/job/list?catId=${item?.id}&catName=${item?.title}`}
                  >
                    <p className="my-2" role="button">
                      {item.title}
                    </p>
                  </Link>
                ) : null
              ) : item?.type == 1 && item?.type_name == "classified" ? (
                <p
                  className="my-2"
                  role="button"
                  onClick={() =>
                    navigate("/classified/detail", {
                      state: { post_id: item?.id },
                    })
                  }
                >
                  {item.title}
                </p>
              ) : item?.type == 2 && item?.type_name == "service" ? (
                <p
                  className="my-2"
                  role="button"
                  onClick={() =>
                    navigate("/service/detail", {
                      state: { post_id: item?.id },
                    })
                  }
                >
                  {item.title}
                </p>
              ) : item?.type == 4 && item?.type_name == "job" ? (
                <p
                  className="my-2"
                  role="button"
                  onClick={() =>
                    navigate("/job/detail", { state: { post_id: item?.id } })
                  }
                >
                  {item.title}
                </p>
              ) : item?.type == 3 && item?.type_name == "explore" ? (
                <p
                  className="my-2"
                  role="button"
                  onClick={() =>
                    navigate("/explore/detail", {
                      state: { post_id: item?.id },
                    })
                  }
                >
                  {item.title}
                </p>
              ) : null;
            })}
          </div>
        )}
      </div>
    </div>
  );
}

export default FadingBanner;
