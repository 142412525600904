const ErrorPopup = ({ alertMessage, onCloseClick }) => {
  return (
    <div className="customPopup" style={{ zIndex: "999999" }}>
      <div className="customPopupDesign">
        <h6 className="text-center">{alertMessage}</h6>
        <div className="d-flex justify-content-center pt-2">
          <button
            className="cust-btn bg-primar text-white rounded-3 p-2 d-grid m-3"
            onClick={onCloseClick}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ErrorPopup;
