import React, { useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import TelegramIcon from "@mui/icons-material/Telegram";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import LoginIcon from "@mui/icons-material/Login";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { useLocation, useNavigate } from "react-router-dom";
import { useLazyLogoutQuery } from "../../redux/api/api";
import useUser from "../../redux/store/userData";
import ConfirmPopup from "../Popup/ConfirmPopup";

const ProfileNav = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useUser();
  const [logout] = useLazyLogoutQuery();
  const [showMenu, setShowMenu] = useState(true);
  const [btn, setBtn] = useState(false);
  const [logoutPopup, setLogoutPopup] = useState(false);

  const onCLickShowHandler = () => {
    setShowMenu(!showMenu);
  };

  const { pathname } = location;
  const splitLocation = pathname.split("/");
  // console.log(splitLocation[1]);

  const logoutApi = () => {
    setBtn(true);
    logout()
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          setBtn(false);
          // alert(res?.message);
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
            window.location.reload();
          }, 400);
        }
      })
      .catch((err) => {
        setBtn(false);
      });
  };

  const toggleLogoutPopup = () => {
    setLogoutPopup(!logoutPopup);
  };

  // console.log(showMenu);
  return (
    <div className={`profile-left ${showMenu && "profile-left1"}`}>
      {logoutPopup && (
        <ConfirmPopup
          diableBtn={btn}
          onCloseClick={toggleLogoutPopup}
          onConfirmClick={logoutApi}
          message="Are you sure want to logout"
        />
      )}
      <button
        className="cust-btn cls-btn d-block d-md-none"
        onClick={onCLickShowHandler}
      >
        {showMenu ? <MenuIcon /> : <CloseIcon />}
      </button>
      <div
        className={`profile-img d-flex mx-auto my-4 ${
          showMenu ? "profile-img1 image-croping " : "profile-img2"
        }`}
      >
        <img
          src={
            user?.image
              ? user?.image_path
              : user?.gender == "Male"
              ? "https://img.freepik.com/free-photo/smiley-handsome-man-posing_23-2148911841.jpg?w=740&t=st=1689942327~exp=1689942927~hmac=bda76672938776892327321166719cf5f4f117e952887c2f31377b8cacaba331"
              : "https://st3.depositphotos.com/6179956/15450/i/1600/depositphotos_154504514-stock-photo-university-student-girl-looking-happy.jpg"
          }
          alt=""
        />
      </div>
      <button
        onClick={() => {
          navigate("/profile");
        }}
        className={`d-flex ac-jb w-100 cust-btn ${
          splitLocation[1] === "profile" ? "active" : null
        }`}
      >
        <div className="d-flex ac-js">
          <PersonIcon />
          <p className={` ${showMenu && "button1"}`}>Profile</p>
        </div>
        <KeyboardArrowRightIcon />
      </button>
      <button
        onClick={() => {
          navigate("/newPost");
        }}
        className={`d-flex ac-jb w-100 cust-btn ${
          splitLocation[1] === "newPost" ? "active" : null
        }`}
      >
        <div className="d-flex ac-js">
          <NoteAddIcon />
          <p className={` ${showMenu && "button1"}`}>New Post</p>
        </div>
        <KeyboardArrowRightIcon />
      </button>
      <button
        onClick={() => navigate("/postList")}
        className={`d-flex ac-jb w-100 cust-btn ${
          splitLocation[1] === "postList" ? "active" : null
        }`}
      >
        <div className="d-flex ac-js">
          <TelegramIcon />
          <p className={` ${showMenu && "button1"}`}>Post List</p>
        </div>
        <KeyboardArrowRightIcon />
      </button>
      <button
        onClick={() => navigate("/contact-us")}
        className={`d-flex ac-jb w-100 cust-btn ${
          splitLocation[1] === "contact-us" ? "active" : null
        }`}
      >
        <div className="d-flex ac-js">
          <PermPhoneMsgIcon />
          <p className={` ${showMenu && "button1"}`}>Contact Us</p>
        </div>
        <KeyboardArrowRightIcon />
      </button>
      {/* <button
        onClick={() => navigate("/login")}
        className="d-flex ac-jb w-100 cust-btn"
      >
        <div className="d-flex ac-js">
          <PermPhoneMsgIcon />
          <p className={` ${showMenu && "button1"}`}>Registration</p>
        </div>
        <KeyboardArrowRightIcon />
      </button> */}
      <button
        className="d-flex ac-jb w-100 cust-btn"
        onClick={toggleLogoutPopup}
        disabled={btn ? true : false}
      >
        <div className="d-flex ac-js">
          <LoginIcon />
          <p className={` ${showMenu && "button1"}`}>Logout</p>
        </div>
        <KeyboardArrowRightIcon />
      </button>
    </div>
  );
};

export default ProfileNav;
