const ConfirmPopup = ({
  message,
  onCloseClick,
  onConfirmClick,
  disableBtn,
}) => {
  return (
    <div
      className="customPopup"
      style={{ zIndex: "999999", top: 0, left: 0, bottom: 0, right: 0 }}
    >
      <div className="customPopupDesign">
        <h6 className="text-center">{message}</h6>
        <div className="d-flex justify-content-center pt-4">
          <button
            className="cust-btn bg-lt-primary text-white rounded-3 p-2 d-grid m-3"
            onClick={onCloseClick}
          >
            Cancel
          </button>
          <button
            className="cust-btn bg-gray  text-white rounded-3 p-2 d-grid m-3"
            onClick={onConfirmClick}
            disabled={disableBtn}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPopup;
