import React, { useEffect, useState } from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useNavigate } from "react-router-dom";
import ClassifiedBox from "./ClassifiedBox";
import ReactPaginate from "react-paginate";

const ClassifiedListBox = ({
  subCategTabList,
  classifiedList,
  setPage,
  page,
  setListOrder,
  listOrder,
}) => {
  const [showSort, setShowSort] = useState(false);
  const [pageCount, setPageCount] = useState(10);
  // console.log("pageCount", pageCount);
  // console.log("page", page);

  const showHandler = (order) => {
    if (order === "asc" || order === "desc") {
      setListOrder(order);
    }
    setShowSort(!showSort);
  };

  useEffect(() => {
    setPageCount(classifiedList?.pagination?.last_page);
  }, [classifiedList]);

  const handlePageClick = (e) => {
    setPage(e.selected + 1);
  };

  return (
    <>
      <div className="d-flex ac-jb mb-2">
        <h5 className="result-txt">
          Result -{" "}
          {classifiedList?.pagination?.total
            ? classifiedList?.pagination?.total
            : "No Data Found"}
        </h5>
        <div className="sort-by">
          <button onClick={showHandler} className="cust-btn show-text">
            Sort by <KeyboardArrowDownIcon />
          </button>
          {showSort && (
            <div className="download-box">
              <button
                onClick={() => showHandler("desc")}
                className="cust-btn f3 py-1"
                style={
                  listOrder == "desc"
                    ? { background: "#008cff", color: "white" }
                    : { background: "transparent" }
                }
              >
                Newest
              </button>
              <button
                onClick={() => showHandler("asc")}
                className="cust-btn f3 py-1"
                style={
                  listOrder == "asc"
                    ? { background: "#008cff", color: "white" }
                    : { background: "transparent" }
                }
              >
                Oldest
              </button>
            </div>
          )}
        </div>
      </div>

      <ClassifiedBox
        classifiedList={classifiedList}
        type="home"
        subCategTabList={subCategTabList}
      />
      <div className="pagination-tab d-flex ac-jc">
        {/* <div className="pagination-tab-inner d-flex ac-jc">
          <button className="cust-btn">
            <KeyboardArrowLeftIcon />
          </button>
          <button className="cust-btn active">1</button>
          <button className="cust-btn">2</button>
          <button className="cust-btn">3</button>
          <button className="cust-btn">4</button>
          <button className="cust-btn">
            <KeyboardArrowRightIcon />
          </button>
        </div> */}
        <ReactPaginate
          containerClassName="pagination-tab-inner d-flex ac-jc"
          pageClassName="cust-btn d-flex ac-jc"
          nextClassName="cust-btn d-flex ac-jc"
          previousClassName="cust-btn d-flex ac-jc"
          breakClassName="cust-btn d-flex ac-jc"
          activeClassName="cust-btn d-flex ac-jc active"
          breakLabel="..."
          nextLabel={<KeyboardArrowRightIcon />}
          onPageChange={handlePageClick}
          pageRangeDisplayed={2}
          pageCount={pageCount}
          forcePage={page - 1}
          previousLabel={<KeyboardArrowLeftIcon />}
          renderOnZeroPageCount={null}
        />
      </div>
    </>
  );
};

export default ClassifiedListBox;
