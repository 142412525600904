// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { sofa } from "../../../assets/img";
import PlaceIcon from "@mui/icons-material/Place";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";
import { classifiedJson } from "../../../redux/api/DummyJson";
import moment from "moment/moment";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  useCategoryDeleteMutation,
  usePostListMutation,
} from "../../../redux/api/api";
import useUser from "../../../redux/store/userData";
import ConfirmPopup from "../../Popup/ConfirmPopup";

const ClassifiedBox = ({ subCategTabList, method, type, classifiedList }) => {
  const navigate = useNavigate();
  const { user } = useUser();
  const [postLists] = usePostListMutation();
  const [deletePost] = useCategoryDeleteMutation();
  const [list, setList] = useState([]);
  const [disableDeleteBtn, setDisableDeleteBtn] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});
  const [deletePopup, setDeletePopup] = useState(false);

  useEffect(() => {
    // console.log(classifiedList);
    setList(classifiedList?.classifieds);
  }, [classifiedList]);

  const getList = () => {
    let formdata = new FormData();
    formdata.append("type", 1);
    postLists(formdata)
      .unwrap()
      .then((res) => {
        let temp = [];
        if (res?.status == "success") {
          if (Array.isArray(res?.postList)) {
            // res?.postList?.map((item, ind) => {
            //   item?.classifieds?.map((items, inds) => {
            //     temp.push(items);
            //   });
            // });
            // // let reverse = [...res?.postList].reverse();
            // // setList(reverse);
            temp = [...res?.postList];
          } else {
            res?.postList?.data?.map((item, ind) => {
              temp.push(item);
            });
          }
          setList(temp?.reverse());
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (type == "home") {
      // API
    } else {
      getList();
    }
  }, [type]);

  const togglePopup = () => {
    setDeletePopup(!deletePopup);
  };

  const onClickDelete = (item) => {
    setDeleteItem(item);
    togglePopup();
  };

  const deleteApi = () => {
    setDisableDeleteBtn(true);
    const formData = new FormData();
    formData.append("post_id", deleteItem?.id);
    formData.append("type", 1); // {1->classified,2->service}
    deletePost(formData)
      .unwrap()
      .then((res) => {
        if (res?.status === "success") {
          console.log(res);
          // getList();
          window.location.reload();
          setDisableDeleteBtn(false);
          setDeleteItem({});
        }
      })
      .catch((err) => {
        console.log(err);
        setDisableDeleteBtn(false);
        togglePopup();
      });
  };

  // console.log(method);
  return (
    <div className="tl-classified-box d-flex flex-wrap">
      {deletePopup && (
        <ConfirmPopup
          onCloseClick={togglePopup}
          message={"Are you sure you want delete"}
          disableBtn={disableDeleteBtn}
          onConfirmClick={deleteApi}
        />
      )}
      {list?.length > 0 &&
        list?.map((item, ind) => {
          const {
            img,
            name,
            cost,
            location,
            postBy,
            time,
            designation,
            discription,
          } = item;
          return (
            <Col xs={6} className={` ${method == "TabMethod" && " p-1"}`}>
              <div
                className={`p-2 pb-3 mb-md-2 ${
                  method == "TabMethod" && "bg-sec rounded rounded-3"
                }`}
              >
                <div className=" d-flex w-100 position-relative">
                  <div className="classified-box d-md-flex p-2 w-100">
                    <div className="lft-img-box">
                      <img
                        className="image-res"
                        src={item?.images[0]?.image_path || sofa}
                        alt=""
                        loading="lazy"
                      />
                    </div>
                    <div className="rit-box ms-md-2">
                      <h3 className="custom-h3">{item?.title}</h3>
                      {subCategTabList === true && (
                        <h4>{item?.categories?.parent?.name}</h4>
                      )}
                      {item?.rate ? (
                        <h5>{"₹ " + item?.rate}</h5>
                      ) : (
                        <h5>{"₹ -"}</h5>
                      )}
                      <div className="d-flex ac-jb w-100 mt-1 post-by">
                        <p className="d-flex">
                          <PlaceIcon className="" />
                          <span className="">{item?.location}</span>
                        </p>
                      </div>
                      <button
                        onClick={() =>
                          navigate("/classified/detail", {
                            state: { post_id: item?.id },
                          })
                        }
                        className="d-flex ac-js explore-btn cust-btn"
                      >
                        <p className="">Explore More</p>
                        <ArrowForwardIcon />
                      </button>
                      <div className="d-sm-flex flex-wrap ac-jb w-100 mt-md-2 mt-md-4 post-by">
                        <p className="d-flex">
                          Post by
                          <span className="ms-1">
                            <p className="text-capitalize">
                              {" "}
                              {method == "TabMethod"
                                ? user?.name
                                : item?.user?.name}
                            </p>
                          </span>
                        </p>
                        <p className="d-flex">
                          <span className="me-1">
                            <b>{moment(item?.created_at).fromNow()} </b>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {method == "TabMethod" && (
                  <div className="d-flex ac-je">
                    <button
                      className="cust-btn p-1 edit-delet-icon-tablist"
                      onClick={() =>
                        navigate("/editPost", {
                          state: { data: item, type: "classified" },
                        })
                      }
                    >
                      <BorderColorIcon className=" text-white" />
                    </button>
                    <button
                      disabled={disableDeleteBtn}
                      onClick={() => onClickDelete(item)}
                      className="cust-btn p-1 edit-delet-icon-tablist"
                    >
                      <DeleteIcon className=" text-white" />
                    </button>
                  </div>
                )}
              </div>
            </Col>
          );
        })}
    </div>
  );
};

export default ClassifiedBox;
