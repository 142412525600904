import React from "react";
import ExploreList from "../../Components/Explore/ExploreList/ExploreList";

function ExploreListScreen() {
  return (
    <div className="tl-clasified-page">
      <ExploreList />
    </div>
  );
}

export default ExploreListScreen;
