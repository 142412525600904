import company_logo from "../../assets/img/header_logo.png";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import FmdGoodIcon from "@mui/icons-material/FmdGood";

function FooterMiddle() {
  return (
    <div className="bg-primar middle-footer-cont w-100">
      <div className="w-80 middle-footer-inner-cont">
        <div>
          <button>
            <img src={company_logo} alt="company-logo" />
          </button>
        </div>
        <div>
          <button>
            <a
              // href={`https://goo.gl/maps/UpYnLoEcABbcXMSaA`}
              href={`https://www.google.com/maps/place/Asquare+Digital/@11.4905005,79.7596157,17z/data=!3m1!4b1!4m6!3m5!1s0x3a54eb56c3df6d17:0x2534fd3d3cc199bf!8m2!3d11.4905005!4d79.7596157!16s%2Fg%2F11km4qd47y?hl=en-QA&entry=ttu`}
              target="_blank"
              className="d-flex"
            >
              <FmdGoodIcon />
              {/* <p>Portonovo Vogel Rd, Ellicott City, Maryland-21043, USA</p> */}
              <p>Parangi Pettai, TN - India</p>
            </a>
          </button>
        </div>
        <div>
          <button>
            <a
              href={`tel:+91 ${9988779928}`}
              target="_blank"
              className="d-flex"
            >
              <LocalPhoneIcon /> <p>+91 99 88 77 99 28</p>
            </a>
          </button>
        </div>
        <div>
          <button>
            <a
              href={`mailto:${"pin608502@gmail.com"}`}
              target="_blank"
              className="d-flex"
            >
              <MailOutlineIcon />
              <p style={{ fontSize: "15px" }}>pin608502@gmail.com</p>
            </a>
          </button>
        </div>
      </div>
    </div>
  );
}

export default FooterMiddle;
