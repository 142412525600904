import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";

//local import
import insta_icon from "../../assets/img/instagram.png";
import fb_icon from "../../assets/img/facebook.png";
import twitter_icon from "../../assets/img/twitter.png";

function footerLast() {
  return (
    <div className="w-100 py-1 footer-last-cont">
      <div className="w-75 py-1 last-footer-inner-cont">
        <Col xs={6} lg={4} className="py-1">
          <button>
            <img src={fb_icon} alt="facebook" />
          </button>
          <button>
            <img src={insta_icon} alt="instagram" />
          </button>

          <button>
            <img src={twitter_icon} alt="twitter" />
          </button>
        </Col>
        <Col xs={10} lg={8} className="py-1">
          <p>
            All Rights to PORTONOVO Designed and Developed by
            <Link className="ms-1">Ninos IT Solution</Link>
          </p>
        </Col>
      </div>
    </div>
  );
}

export default footerLast;
