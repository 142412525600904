import React from "react";
import ClassifiedList from "../../Components/Classified/ClassifiedList/ClassifiedList";
import ServiceList from "../../Components/Services/ServiceList/ServiceList";

function ServiceListScreen() {
  return (
    <div className="tl-clasified-page bg-white">
      <ServiceList type="home" />
    </div>
  );
}

export default ServiceListScreen;
