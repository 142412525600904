import React, { useState } from "react";
import { Col } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import sofa_1 from "../../../assets/img/Sofa_1.jpg";
import sofa_2 from "../../../assets/img/Sofas.jpg";
import { sofa } from "../../../assets/img";

const ExploreBanner = ({ postDetails }) => {
  const [currentPhoto, setCurrentPhoto] = useState(1);
  const imageSelHandler = (e) => {
    setCurrentPhoto(e + 1);
  };
  return (
    <Col xs={12} md={6} className="tl-service text-center caros-box pb-3 ac-jc">
      <div className=" img-cont p-2 ac-jc d-flex flex-wrap mt-3">
        <div className="align-items-center justyfy-content-center">
          <div className="service_hidden">
            <img
              src={
                postDetails?.explore?.image
                  ? postDetails?.explore?.image_path
                  : sofa_2
              }
              className=""
              alt="banner-img"
            />
          </div>
        </div>
      </div>
    </Col>
  );
};

export default ExploreBanner;
