// @ts-nocheck
import React from "react";
import { login, logo } from "../../assets/img";

const LoginLeft = () => {
  return (
    <div className="FirstFlex">
      <div className="FirstFlexDesign">
        <div className="ImageDesign">
          <img src={logo} />
        </div>
        <div className="ImageDesignMultivendor">
          <p className="MultiVendorFontSize">
            Multivendor Marketplace For Your <b>Business</b>
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoginLeft;
