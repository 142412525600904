// @ts-nocheck
import React, { useState } from "react";
import ClassifiedListBox from "../ClassifiedList/ClassifiedListBox";
import ClassifiedRelatedList from "./ClassifiedRelatedList";

const ClassifiedRelatePost = ({ postDetails }) => {
  const [related, setRelated] = useState(true);
  const [times, setTimes] = useState(false);
  // console.log("postDetails", postDetails);
  const onClickShowHandler = (e) => {
    if (e == 1) {
      setRelated(true);
      setTimes(false);
    }
    if (e == 2) {
      setTimes(true);
      setRelated(false);
    }
  };

  return (
    <div className="w-100">
      <div className="d-flex flex-wrap relate-post-tab">
        <button
          onClick={() => onClickShowHandler(1)}
          className={`cust-btn ${related && "active"} `}
        >
          <span>Related Posts</span>
        </button>
        <button
          onClick={() => onClickShowHandler(2)}
          className={`cust-btn ${times && "active"} `}
        >
          <span>Timeline</span>
        </button>
      </div>
      {related && (
        <ClassifiedRelatedList postDetails={postDetails?.related_posts} />
      )}
      {times && <ClassifiedRelatedList postDetails={postDetails?.time_line} />}
    </div>
  );
};

export default ClassifiedRelatePost;
