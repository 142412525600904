// @ts-nocheck
import React, { useState, useEffect } from "react";
import { classifiedBanner, service_banner } from "../../../assets/img";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import HomeIcon from "@mui/icons-material/Home";
import ServiceTab from "./ServiceTab";
import ServiceListBox from "./ServiceListBox";
import ServiceLists from "./ServiceLists";
import { Carousel } from "react-bootstrap";
import {
  useLazyServiceBannersQuery,
  useServiceBannersMutation,
  useSubcategoriesMutation,
  useSubcategoryListMutation,
  useOverallListMutation,
  useCategoryListsMutation,
} from "../../../redux/api/api";
import ReactPaginate from "react-paginate";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
const ServiceList = ({ type }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showTabList, setShowTabList] = useState(true);
  const [list, setList] = useState([]);
  const [subCategTabList, setSubCategTabList] = useState(true);
  const [page, setPage] = useState(1);
  const [listOrder, setListOrder] = useState("");
  const [pageCount, setPageCount] = useState(10);
  const [catNames, setCatNames] = useState([]);
  const [catSubNames, setCatSubNames] = useState([]);

  const [serviceList, setServiceList] = useState([]);
  const [subcategoriesList, setSubCategoriesList] = useState([]);
  const [selectedSubCat, setSelectedSubCat] = useState();
  const [subcategoriesListId, setSubCategoriesListId] = useState("");
  const [subcategoriesListsId, setSubCategoriesListsId] = useState("");

  // RTK QUERY

  const [serviceBanners] = useServiceBannersMutation();
  const [subcategoriesData] = useSubcategoriesMutation();
  const [subcategoryListData] = useSubcategoryListMutation();
  const [overallList] = useOverallListMutation();
  const [catgoryList] = useCategoryListsMutation();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (subcategoriesListsId) {
      getCategoryListWithPaginate(subcategoriesListsId);
    } else if (subcategoriesListId) {
      // console.log("subcategoriesListId", subcategoriesListId);
      getSubcategoriesListPaginate(subcategoriesListId);
    } else if (!searchParams?.get("catId")) {
      getOverallList();
    }
  }, [page]);

  // GET OVER ALL LIST
  const getOverallList = (order) => {
    removeQueryParams();
    if (Object.values(catNames)?.length > 0) {
      setPage(1);
    }
    setSubCategoriesListsId("");
    setSubCategoriesListId("");
    const formData = new FormData();
    formData.append("type", 2);
    formData.append("rowcheck", 12);

    if (order || listOrder !== "") {
      formData.append("order", order ? order : listOrder);
    } else {
      formData.append("order", "desc");
      setListOrder("desc");
    }

    let params = `?page=${page}`;
    overallList({ payload: formData, params })
      .unwrap()
      .then((res) => {
        if (res?.status === "success") {
          setServiceList(res);
          setSubCategTabList(true);
          setCatNames([]);
          setCatSubNames([]);
          setSelectedSubCat("");
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // SUB CATEGORY
  const showTabListHandler = (item) => {
    setPage(1);
    const formData = new FormData();
    formData.append("subcategory_id", item?.id);
    subcategoriesData(formData)
      .unwrap()
      .then((res) => {
        if (res?.status === "success") {
          if (res?.status === "success") {
            setSubCategoriesList(res?.sub_categories);
            if (searchParams?.get("subId")) {
              setSelectedSubCat(Number(searchParams?.get("subId")));
            }

            if (searchParams?.get("subId")) {
              getSubcategoriesList(searchParams?.get("subId"));
            } else {
              getCategoryList(item?.id);
              setSubCategoriesListsId(item?.id);
            }

            // setCatSubNames(res?.sub_categories[0]);
            // setSubCategoriesListId(res?.sub_categories?.[0]?.id);

            setSubCategTabList(false);
          }
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // CATEGORY LISTS
  const getCategoryList = (id) => {
    setSelectedSubCat("");
    setSubCategoriesListsId(id);
    let formdata = new FormData();
    formdata.append("type", 2);
    formdata.append("category_id", id);
    formdata.append("order", "desc");
    setListOrder("desc");
    let params = `?page=${1}`;
    setSubCategoriesListId("");
    catgoryList({ payload: formdata, params })
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          setServiceList(res);
        }
      });
  };

  // SUB CATEGORY LIST
  const getSubcategoriesList = (id) => {
    setServiceList([]);
    setSubCategoriesListId(id);
    setSubCategoriesListsId("");
    if (id) {
      let params = `?page=${1}`;
      const formData = new FormData();
      formData.append("subcategory_id", id);
      formData.append("type", 2);
      formData.append("order", "desc");
      setListOrder("desc");
      subcategoryListData({ payload: formData, params })
        .unwrap()
        .then((res) => {
          // console.log(res);
          if (res?.status === "success") {
            setServiceList(res);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  // CATEGORY LISTS WITH PAGINATE
  const getCategoryListWithPaginate = (id, order) => {
    setSubCategoriesListsId(id);
    setSelectedSubCat("");
    let formdata = new FormData();
    formdata.append("type", 2);
    formdata.append("category_id", id);
    if (order || listOrder !== "") {
      formdata.append("order", order ? order : listOrder);
    } else {
      formdata.append("order", "desc");
      setListOrder("desc");
    }
    let params = `?page=${page}`;
    catgoryList({ payload: formdata, params })
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          setServiceList(res);
        }
      });
  };

  const getSubcategoriesListPaginate = (id, order) => {
    setServiceList([]);
    setSubCategoriesListId(id);
    setSubCategoriesListsId("");
    const formData = new FormData();
    if (order || listOrder !== "") {
      formData.append("order", order ? order : listOrder);
    } else {
      formData.append("order", "desc");
      setListOrder("desc");
    }
    if (id) {
      let params = `?page=${page}`;
      formData.append("subcategory_id", id);
      formData.append("type", 2);
      subcategoryListData({ payload: formData, params })
        .unwrap()
        .then((res) => {
          console.log(res);
          if (res?.status === "success") {
            setServiceList(res);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  useEffect(() => {
    // console.log(serviceList);
    setPageCount(serviceList?.pagination?.last_page);
  }, [serviceList]);

  const onSubCategoryClick = (item) => {
    getSubcategoriesList(item?.id);
    setSelectedSubCat(item?.id);
    setListOrder("");
    setCatSubNames(item);
    removeQueryParams();
  };

  // GET BANNERS LIST
  const getServiceBanners = (id) => {
    let formData = new FormData();
    formData.append("category_id", id);
    serviceBanners(formData)
      .unwrap()
      .then((res) => {
        setList(res?.service_banner);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handlePageClick = (e) => {
    setPage(e.selected + 1);
  };

  useEffect(() => {
    // console.log(listOrder);
    if (location?.state?.subcategory_id) {
      showTabListHandler(location?.state?.subcategory_id);
      getServiceBanners(location?.state?.subcategory_id?.id);
    } else if (searchParams?.get("catId") && searchParams?.get("subId")) {
      let item = {
        id: searchParams?.get("catId"),
      };
      let data = {
        id: searchParams?.get("catId"),
        name: searchParams?.get("catName"),
      };
      let datas = {
        id: searchParams?.get("subId"),
        name: searchParams?.get("subName"),
      };
      getServiceBanners(searchParams?.get("catId"));
      showTabListHandler(item);
      setCatNames(data);
      setCatSubNames(datas);
    } else if (searchParams?.get("catId")) {
      let item = {
        id: searchParams?.get("catId"),
      };
      let data = {
        id: searchParams?.get("catId"),
        name: searchParams?.get("catName"),
      };
      getServiceBanners(searchParams?.get("catId"));
      showTabListHandler(item);
      setCatNames(data);
    } else {
      if (subcategoriesListId) {
        // console.log("INSIDE IF");
        getSubcategoriesListPaginate(subcategoriesListId, listOrder);
      } else if (subcategoriesListsId) {
        getCategoryListWithPaginate(subcategoriesListsId, listOrder);
      } else {
        // console.log("OUTSIDE IF");
        getOverallList(listOrder);
      }
    }
  }, [listOrder, searchParams?.get("catId")]);

  // REMOVE PARAMS
  const removeQueryParams = () => {
    const catId = searchParams.get("catId");
    const subId = searchParams.get("subId");
    const catName = searchParams.get("catName");
    const subName = searchParams.get("subName");

    if (catId) {
      // 👇️ delete each query param
      searchParams.delete("catId");

      // 👇️ update state after
      setSearchParams(searchParams);
    }

    if (subId) {
      searchParams.delete("subId");
      setSearchParams(searchParams);
    }

    if (catName) {
      searchParams.delete("catName");
      setSearchParams(searchParams);
    }

    if (subName) {
      searchParams.delete("subName");
      setSearchParams(searchParams);
    }
  };

  // console.log("list", serviceList);

  return (
    <div>
      {subCategTabList === false ? (
        <Carousel>
          {list?.length > 0 ? (
            list?.map((item, ind) => {
              return (
                <Carousel.Item interval={2000} key={ind}>
                  <div className="list-banner pt-3 pb-2">
                    <img src={item?.image_url} alt="" />
                  </div>
                </Carousel.Item>
              );
            })
          ) : (
            <Carousel.Item>
              <div className="list-banner pt-3 pb-2">
                <img src={service_banner} alt="" />
              </div>
            </Carousel.Item>
          )}
        </Carousel>
      ) : (
        <div className="list-banner pt-3 pb-2">
          <img src={service_banner} alt="" />
        </div>
      )}
      <div className="bread-crum-list d-flex ac-je">
        <button
          className="cust-btn d-flex ac-js fs-14 f2"
          onClick={() => navigate("/")}
        >
          <HomeIcon className="fs-16 me-1" />
          Home
        </button>
        <ChevronRightIcon className="fs-20 me-1" />
        <button
          className="cust-btn d-flex ac-js fs-14 f2"
          onClick={() => getOverallList()}
        >
          Services
        </button>
        {Object.values(catNames)?.length > 0 && (
          <>
            <ChevronRightIcon className="fs-20 me-1" />
            <button
              className="cust-btn d-flex ac-js fs-14 f2"
              onClick={() => {
                setCatSubNames([]);
                removeQueryParams();
                showTabListHandler(catNames);
                setSubCategoriesListId("");
                setPage(1);
                setListOrder("");
              }}
            >
              {catNames?.name}
            </button>
          </>
        )}
        {serviceList?.service?.length > 0 &&
          Object.values(catSubNames)?.length > 0 && (
            <>
              <ChevronRightIcon className="fs-20 me-1" />
              <button className="cust-btn d-flex ac-js fs-14 f2">
                {catSubNames?.name}
              </button>
            </>
          )}
      </div>
      <ServiceTab
        showTabList={showTabList}
        setShowTabList={setShowTabList}
        subCategTabList={subCategTabList}
        showTabListHandler={showTabListHandler}
        subcategoriesList={subcategoriesList}
        selectedSubCat={selectedSubCat}
        onSubCategoryClick={onSubCategoryClick}
        getServiceBanners={getServiceBanners}
        setPage={setPage}
        setCatNames={setCatNames}
      />
      <ServiceLists
        type={type}
        serviceList={serviceList}
        subCategTabList={subCategTabList}
        listOrder={listOrder}
        setListOrder={setListOrder}
      />

      <div className="pagination-tab d-flex ac-jc">
        <div className="pagination-tab-inner d-flex ac-jc">
          {/* <button className="cust-btn">
            <KeyboardArrowLeftIcon />
          </button>
          <button className="cust-btn active">1</button>
          <button className="cust-btn">2</button>
          <button className="cust-btn">3</button>
          <button className="cust-btn">4</button>
          <button className="cust-btn">
            <KeyboardArrowRightIcon />
          </button> */}
          <ReactPaginate
            containerClassName="pagination-tab-inner d-flex ac-jc"
            pageClassName="cust-btn d-flex ac-jc"
            nextClassName="cust-btn d-flex ac-jc"
            previousClassName="cust-btn d-flex ac-jc"
            breakClassName="cust-btn d-flex ac-jc"
            activeClassName="cust-btn d-flex ac-jc active"
            breakLabel="..."
            nextLabel={<KeyboardArrowRightIcon />}
            onPageChange={handlePageClick}
            pageRangeDisplayed={2}
            pageCount={pageCount}
            forcePage={page - 1}
            previousLabel={<KeyboardArrowLeftIcon />}
            renderOnZeroPageCount={null}
          />
        </div>
      </div>
    </div>
  );
};

export default ServiceList;
