import React from "react";
import ClassifiedList from "../../Components/Classified/ClassifiedList/ClassifiedList";

function ClassifiedListScreen() {
  return (
    <div className="tl-clasified-page">
      <ClassifiedList />
    </div>
  );
}

export default ClassifiedListScreen;
