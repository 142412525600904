const CustomPopup = ({ bodyContent, openPopup, setOpenPopup, type }) => {
  // console.log(openPopup);
  return (
    <div className="customPopup">
      <div className="customPopupDesign">
        <h6 className="text-center">{bodyContent}</h6>
        {type === "message" && (
          <button
            className="cust-btn bg-primar text-white rounded-3 p-2 d-grid m-3"
            onClick={() => setOpenPopup(!openPopup)}
          >
            Close
          </button>
        )}
        {type === "submit" && (
          <button
            className="cust-btn bg-primar text-white rounded-3 p-2 d-grid m-3"
            onClick={() => setOpenPopup(!openPopup)}
          >
            Close
          </button>
        )}
      </div>
    </div>
  );
};

export default CustomPopup;
