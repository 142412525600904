import React, { useEffect, useRef, useState } from "react";
import ImageIcon from "@mui/icons-material/AddAPhoto";
import DeleteIcon from "@mui/icons-material/Delete";
import { Col, Image } from "react-bootstrap";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import { useContactUsMutation, useContactViewQuery } from "../../redux/api/api";

const ContactUs = () => {
  const [imageList, setImageList] = useState([]);
  const [primaryNum, setPrimaryNum] = useState("");
  const [whatsappNum, setWhatsappNum] = useState("");
  const [secondaryNum, setSecondaryNum] = useState("");
  const [FbUrl, setFbUrl] = useState("");
  const [instaUrl, setInstaUrl] = useState("");
  const [ytUrl, setYtUrl] = useState("");
  const [twitterUrl, setTwitterUrl] = useState("");
  const [editState, setEditState] = useState(true);

  // Err
  const [whatsappNumErr, setWhatsappNumErr] = useState(false);

  // RTK QUERY
  const { data: contactData, isSuccess: isContactSuccess } =
    useContactViewQuery();
  const [contactUsEdit] = useContactUsMutation();

  useEffect(() => {
    if (isContactSuccess) {
      console.log(contactData);
      const { user } = contactData;
      setPrimaryNum(user?.mobile);
      setSecondaryNum(user?.secondary_number);
      setWhatsappNum(user?.w_number);
      const url = JSON.parse(user?.url);
      console.log(url);
      setInstaUrl(url?.[0]);
      setFbUrl(url?.[1]);
      setTwitterUrl(url?.[2]);
      setYtUrl(url?.[3]);
    }
  }, [contactData]);

  const onEditClick = () => {
    if (!editState) {
      onSubmitClick();
    } else {
      setEditState((prev) => !prev);
    }
  };

  const onWhatsappNumChange = (e) => {
    setWhatsappNum(e.target.value);
  };
  const onSecondaryNumChange = (e) => {
    setSecondaryNum(e.target.value);
  };
  const onFbUrlChange = (e) => {
    setFbUrl(e.target.value);
  };
  const onInstaUrlChange = (e) => {
    setInstaUrl(e.target.value);
  };
  const onTwitterUrlChange = (e) => {
    setTwitterUrl(e.target.value);
  };
  const onYtUrlChange = (e) => {
    setYtUrl(e.target.value);
  };

  const onSubmitClick = () => {
    // if (whatsappNum?.length < 10) {
    //   setWhatsappNumErr(true);
    // } else {
    const formData = new FormData();
    if (whatsappNum) {
      formData.append("whatsapp_number", whatsappNum);
    }

    if (secondaryNum) {
      formData.append("secondary_number", secondaryNum);
    }

    if (instaUrl) {
      formData.append("url[0]", instaUrl);
    }
    if (FbUrl) {
      formData.append("url[1]", FbUrl);
    }
    if (twitterUrl) {
      formData.append("url[2]", twitterUrl);
    }
    if (ytUrl) {
      formData.append("url[3]", ytUrl);
    }
    contactUsEdit(formData)
      .unwrap()
      .then((res) => {
        console.log(res);
        if (res?.status == "success") {
          setEditState((prev) => !prev);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
    // }
  };

  return (
    <div className="profile-right bg-white h-100">
      <h5>Contact Us</h5>
      <div className="d-flex flex-wrap justify-content-between">
        <div className="form-groups position-relative">
          <h4>
            Primary Number <span className="err">*</span>
          </h4>
          <input
            type="number"
            className="contactUsInput"
            disabled={true}
            value={primaryNum}
          />
          <p className="contactUsInputValue">+91</p>
        </div>
        <div className="form-groups position-relative">
          <h4>Secondary Number</h4>
          <input
            maxLength={10}
            type="number"
            className="contactUsInput"
            disabled={editState}
            value={secondaryNum}
            onChange={onSecondaryNumChange}
            onInput={(e) => {
              if (e.target.value.length > e.target.maxLength)
                e.target.value = e.target.value.slice(0, e.target.maxLength);
              if (e.target.value.length !== 10) {
                e.target.setCustomValidity("invalid Number");
              } else if (e.target.value.length == 10) {
                e.target.setCustomValidity("");

                setSecondaryNum(e.target.value);
              }
            }}
          />
          <p className="contactUsInputValue">+91</p>
        </div>
        <div className="form-groups position-relative">
          <h4>
            What'sapp Number <span className="err">*</span>
          </h4>
          <input
            type="number"
            maxLength={10}
            className="contactUsInput"
            disabled={editState}
            value={whatsappNum}
            onChange={onWhatsappNumChange}
            onInput={(e) => {
              if (e.target.value.length > e.target.maxLength)
                e.target.value = e.target.value.slice(0, e.target.maxLength);
              if (e.target.value.length !== 10) {
                e.target.setCustomValidity("invalid Number");
              } else if (e.target.value.length == 10) {
                e.target.setCustomValidity("");

                setWhatsappNum(e.target.value);
              }
            }}
          />
          <p className="contactUsInputValue">+91</p>
          {/* {whatsappNumErr && whatsappNum?.length < 10 && (
            <p className="text-danger fs-13">Enter Whatsapp Number*</p>
          )} */}
        </div>
        <div className="form-groups">
          <h4>Instagram URL</h4>
          <input
            type="text"
            disabled={editState}
            value={instaUrl}
            onChange={onInstaUrlChange}
          />
        </div>
        <div className="form-groups">
          <h4>Facebook URL</h4>
          <input
            type="text"
            value={FbUrl}
            disabled={editState}
            onChange={onFbUrlChange}
          />
        </div>
        <div className="form-groups">
          <h4>Twitter URL</h4>
          <input
            type="text"
            value={twitterUrl}
            disabled={editState}
            onChange={onTwitterUrlChange}
          />
        </div>
        <div className="form-groups">
          <h4>Youtube URL</h4>
          <input
            type="text"
            value={ytUrl}
            disabled={editState}
            onChange={onYtUrlChange}
          />
        </div>
      </div>
      <div className="mt-3 d-flex w-100" onClick={onEditClick}>
        {editState ? (
          <button className="cust-btn btn-style bg-sec">Edit</button>
        ) : (
          <button className="cust-btn btn-style bg-sec">Save</button>
        )}
      </div>
    </div>
  );
};

export default ContactUs;
