import React from 'react'
import CommerceLeft from './CommerceLeft'
import CommerceRight from './CommerceRight'

const CommerceComo = () => {
    return (
        <div className="bg-white my-5 w-90">
            <div className='d-flex flex-wrap justify-content-between align-custom  align-items-cente'>
                <CommerceLeft />
                <CommerceRight />
            </div>
        </div>
    )
}

export default CommerceComo