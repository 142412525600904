import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import ClassifiedHome from "../Components/Home/ClassifiedHome";
import ExploreHome from "../Components/Home/ExploreHome";
import ServiceHome from "../Components/Home/ServiceHome";
import FadingBanner from "../Components/Banner/banner";
import { useHomeCategoryShowMutation } from "../redux/api/api";
import SaidIconHome from "../Components/Home/SaidIconHome";
import { lodergif } from "../assets/img";

const HomeScreen = () => {
  const [loaders, setLoaders] = useState(false);
  const [showClassified] = useHomeCategoryShowMutation();
  const [classifiedList, setClasifiedList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [exploreList, setExploreList] = useState([]);

  const getClassifiedList = () => {
    let formdata = new FormData();
    formdata.append("type", 1);
    showClassified(formdata)
      .unwrap()
      .then((res) => {
        setClasifiedList(res?.classifieds);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getServiceList = () => {
    let formdata = new FormData();
    formdata.append("type", 2);
    showClassified(formdata)
      .unwrap()
      .then((res) => {
        setServiceList(res?.services);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getExploreList = () => {
    let formdata = new FormData();
    formdata.append("type", 3);
    showClassified(formdata)
      .unwrap()
      .then((res) => {
        let temp = [];

        res?.explores?.map((item, ind) => {
          temp.push(item);
        });

        let reverse = temp.reverse();
        // .slice(0, 3);
        setExploreList(reverse);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getClassifiedList();
    getServiceList();
    getExploreList();
  }, []);

  return (
    <div className="bg-white">
      {loaders && (
        <div className="loader-icon position-fixed bg-opacity-25 loderbg ac-jc">
          <div className="loader-icon1">{/* <img src={lodergif} /> */}</div>
        </div>
      )}
      <SaidIconHome setLoaders={setLoaders} />
      <FadingBanner />
      {classifiedList?.length > 0 && (
        <ClassifiedHome classifiedList={classifiedList} />
      )}
      {serviceList?.length > 0 && <ServiceHome serviceList={serviceList} />}
      {exploreList?.length > 0 && <ExploreHome exploreList={exploreList} />}
    </div>
  );
};

export default HomeScreen;
