import React, { useEffect, useState } from "react";
import ClassifiedBanner from "../../Components/Classified/ClassifiedDetail/ClassifiedBanner";
import ClassifiedRelatePost from "../../Components/Classified/ClassifiedDetail/ClassifiedRelatePost";
import ServiceDetailLeft from "../../Components/Services/ServicesDetail/ServiceDetailLeft";
import ServicesDetailRight from "../../Components/Services/ServicesDetail/ServicesDetailRight";
import ServicesGallery from "../../Components/Services/ServicesDetail/ServicesGallery";
import { usePostViewMutation } from "../../redux/api/api";
import { useLocation } from "react-router-dom";
import { lodergif } from "../../assets/img";

const ServiceDetail = () => {
  const location = useLocation();
  const post_id = location?.state?.post_id;
  const [loader, setLoader] = useState(false);
  const [postDetails, setPostDetails] = useState({});
  const [postView] = usePostViewMutation();

  useEffect(() => {
    if (post_id) {
      const formData = new FormData();
      formData.append("post_id", post_id);
      formData.append("type", 2); //{1->classified,2->service}
      setLoader(true);
      postView(formData)
        .unwrap()
        .then((res) => {
          if (res?.status === "success") {
            // console.log(res);
            setLoader(false);
            setPostDetails(res);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
    }
  }, [post_id]);

  return (
    <div className="bg-white">
      {loader && (
        <div className="loader-icon position-fixed bg-opacity-25 loderbg ac-jc">
          <div className="loader-icon1">{/* <img src={lodergif} /> */}</div>
        </div>
      )}
      <div className="w-90 pb-3">
        <div className="d-flex flex-wrap">
          <ServiceDetailLeft postDetails={postDetails} />
          <ServicesDetailRight postDetails={postDetails} />
        </div>
        {postDetails?.images?.length > 0 && (
          <ServicesGallery postDetails={postDetails} />
        )}
      </div>
    </div>
  );
};

export default ServiceDetail;
