import imageCompression from "browser-image-compression";

export const handleImageUpload = async (event, setComperssedImg) => {
  const imageFile = event.target.files[0];

  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 400,
  };
  try {
    const compressedFile = await imageCompression(imageFile, options);
    setComperssedImg && setComperssedImg(compressedFile);
    return compressedFile;
    // console.log("compressedFile", compressedFile.size / 1024 / 1024);
    // console.log("compressedFile", compressedFile);

    // console.log("compressedFile instanceof Blob",compressedFile instanceof Blob); // true
    // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
  } catch (error) {
    console.log(error);
  }
};
