import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { sofa } from "../../../assets/img";
import PlaceIcon from "@mui/icons-material/Place";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import OwlCarousel from "react-owl-carousel";
import { row2, row3 } from "../../../redux/api/constants";
import { classifiedJson } from "../../../redux/api/DummyJson";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";

const ClassifiedRelatedList = ({ postDetails }) => {
  // console.log(postDetails);
  const navigate = useNavigate();
  return (
    <div className="tl-classified-box relate-box bg-gray4 px-3 d-flex flex-wrap">
      <OwlCarousel className="owl-theme" {...row3}>
        {postDetails?.map((item, ind) => {
          const { img, name, cost, location, postBy, time } = item;
          // console.log(item);
          return (
            <div className="classi-box">
              <div className="classified-box d-sm-flex p-2">
                <div className="lft-img-box">
                  <img
                    className="ms-auto"
                    src={
                      item?.images?.length > 0
                        ? item?.images[0]?.image
                          ? item?.images[0]?.image_path
                          : sofa
                        : sofa
                    }
                    alt=""
                  />
                </div>
                <div className="rit-box ms-2">
                  <h3>
                    {item?.title?.length > 25
                      ? item?.title?.slice(0, 25) + "..."
                      : item?.title}
                  </h3>
                  <h5>{item?.rate ? "₹ " + item?.rate : "₹ -"}</h5>
                  <div className="d-flex ac-jb w-100 mt-1 post-by">
                    <p className="d-flex">
                      <PlaceIcon className="fs-18" />
                      <span className="">{item?.location}</span>
                    </p>
                  </div>
                  <div
                    onClick={() =>
                      navigate("/classified/detail", {
                        state: { post_id: item?.id },
                      })
                    }
                    className="d-flex ac-js explore-btn"
                  >
                    <p className="">Explore More</p>
                    <ArrowForwardIcon className="fs-18" />
                  </div>
                  <div className="d-sm-flex ac-jb w-100 mt-2 mt-md-4 post-by">
                    <p className="d-flex">
                      Post by
                      <span className="ms-1">
                        <b> {item?.user?.name}</b>
                      </span>
                    </p>
                    <p className="d-flex">
                      <span className="me-1">
                        <b>{moment(item?.created_at).fromNow()} </b>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </OwlCarousel>
    </div>
  );
};

export default ClassifiedRelatedList;
