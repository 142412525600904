import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { jobList } from "../../../redux/api/DummyJson";
import PlaceIcon from "@mui/icons-material/Place";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  usePostListMutation,
  useCategoryDeleteMutation,
} from "../../../redux/api/api";
import useUser from "../../../redux/store/userData";
import moment from "moment/moment";
import ConfirmPopup from "../../Popup/ConfirmPopup";
import { profile1 } from "../../../assets/img";

const JobListBox = ({ method, jobList }) => {
  const { user } = useUser();
  const navigate = useNavigate();
  const [postLists] = usePostListMutation();
  const [deletePost] = useCategoryDeleteMutation();
  const [list, setList] = useState([]);
  const [disableDeleteBtn, setDisableDeleteBtn] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});
  const [deletePopup, setDeletePopup] = useState(false);

  useEffect(() => {
    // console.log(jobList);
    setList(jobList?.jobs);
  }, [jobList]);

  const getList = () => {
    let formdata = new FormData();
    formdata.append("type", 4);
    postLists(formdata)
      .unwrap()
      .then((res) => {
        let temp = [];
        if (res?.status == "success") {
          if (Array.isArray(res?.postList)) {
            // res?.postList?.map((item, ind) => {
            //   item?.classifieds?.map((items, inds) => {
            //     temp.push(items);
            //   });
            // });
            // let reverse = [...res?.postList].reverse();
            // setList(reverse);

            temp = [...res?.postList];
          } else {
            res?.postList?.data?.map((item, ind) => {
              temp.push(item);
            });
          }
          setList(temp?.reverse());
        }
      })
      .catch((err) => console.log(err));
  };

  const togglePopup = () => {
    setDeletePopup(!deletePopup);
  };

  const deleteApi = () => {
    setDisableDeleteBtn(true);
    // console.log(item);
    const formData = new FormData();
    formData.append("post_id", deleteItem?.id);
    formData.append("type", 4); // {1->classified,2->service}
    deletePost(formData)
      .unwrap()
      .then((res) => {
        if (res?.status === "success") {
          // console.log(res);
          // getList();
          window.location.reload();
          setDisableDeleteBtn(false);
          setDeleteItem({});
        }
      })
      .catch((err) => {
        console.log(err);
        setDisableDeleteBtn(false);
        togglePopup();
      });
  };

  useEffect(() => {
    if (method == "TabMethod") {
      getList();
    }
  }, []);

  const onClickDelete = (item) => {
    setDeleteItem(item);
    togglePopup();
  };

  return (
    <div className="service-box align-items-center d-flex flex-wrap w-100">
      {deletePopup && (
        <ConfirmPopup
          onCloseClick={togglePopup}
          message={"Are you sure you want delete"}
          disableBtn={disableDeleteBtn}
          onConfirmClick={deleteApi}
        />
      )}
      {list?.map((item, ind) => {
        const {
          img,
          name,
          title,
          designation,
          location,
          postBy,
          time,
          degree,
          type,
        } = item;
        // console.log(item);
        return (
          <Col
            xs={6}
            lg={4}
            xxl={method == "TabMethod" ? 4 : 4}
            className="p-2 px-md-2"
            key={ind}
          >
            <div
              className={` ${
                method == "TabMethod" &&
                "p-2 pb-3 mb-md-2 bg-sec rounded rounded-3"
              }`}
            >
              <button
                onClick={() =>
                  navigate("/job/detail", {
                    state: { post_id: item?.id },
                  })
                }
                className={`job-box bg-sec1 border-0 w-100`}
              >
                <div className="box-inner flex-column flex-md-row align-items-center justify-content-center justify-content-md-between d-flex">
                  <div className="profile-img">
                    {/* <KeyboardArrowDownIcon /> */}
                    <img
                      style={{ marginTop: img ? "auto" : "0px" }}
                      src={
                        item?.categories?.parent?.name
                          ?.toLowerCase()
                          .startsWith("vac")
                          ? item?.image
                            ? item?.image_path
                            : profile1
                          : item?.user?.image
                          ? item?.user?.image_path
                          : item?.user?.gender == "Male"
                          ? "https://img.freepik.com/free-photo/smiley-handsome-man-posing_23-2148911841.jpg?w=740&t=st=1689942327~exp=1689942927~hmac=bda76672938776892327321166719cf5f4f117e952887c2f31377b8cacaba331"
                          : "https://st3.depositphotos.com/6179956/15450/i/1600/depositphotos_154504514-stock-photo-university-student-girl-looking-happy.jpg"
                      }
                      alt=""
                    />
                  </div>
                  <div className="line3 d-none d-md-block" />
                  <div className="rit-box-jox text-center text-md-start">
                    <h4
                      style={{
                        textTransform: "capitalize",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {item?.job_title}
                    </h4>
                    <h5>{item?.job_position}</h5>
                    <p>
                      <PlaceIcon className="fs-15" />
                      {item?.job_location || item?.applicant_location}
                    </p>
                    <p
                      style={{
                        textTransform: "capitalize",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {item?.categories?.parent?.name
                        ?.toLowerCase()
                        .startsWith("vac") ? (
                        item?.company_name
                      ) : (
                        <>&nbsp;</>
                      )}
                    </p>
                    <div className="d-flex w-100 justify-content-center justify-content-md-end">
                      <button
                        // onClick={() =>
                        //   navigate("/service/detail", {
                        //     state: { post_id: item?.id },
                        //   })
                        // }
                        className="cust-btn"
                      >
                        {item?.categories?.parent?.name
                          ?.toLowerCase()
                          .startsWith("vac")
                          ? "Apply"
                          : "Hire"}
                      </button>
                    </div>
                    <div className="d-flex w-100 ac-jb mt-2">
                      <p>
                        Post By{" "}
                        <span
                          className="text-capitalize"
                          style={{ color: "#0c8ce9" }}
                        >
                          {item?.user?.name}{" "}
                        </span>
                      </p>
                      <p>
                        <span style={{ fontSize: "10px" }}>
                          {moment(item?.created_at).fromNow()}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </button>
              {method == "TabMethod" && (
                <div className="d-flex ac-je">
                  <button
                    className="cust-btn p-1 edit-delet-icon-tablist"
                    onClick={() =>
                      navigate("/editPost", {
                        state: { data: item, type: "jobs" },
                      })
                    }
                  >
                    <BorderColorIcon className=" text-white" />
                  </button>
                  <button
                    className="cust-btn p-1 edit-delet-icon-tablist"
                    disabled={disableDeleteBtn}
                    onClick={() => onClickDelete(item)}
                  >
                    <DeleteIcon className=" text-white" />
                  </button>
                </div>
              )}
            </div>
          </Col>
        );
      })}
    </div>
  );
};

export default JobListBox;
