// @ts-nocheck
import React, { useEffect, useState } from "react";
import { email1, phone1, profile1, whatsapp1 } from "../../assets/img";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import OwlCarousel from "react-owl-carousel";
import { options } from "../../redux/api/constants";
import { classifiedJson } from "../../redux/api/DummyJson";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Carousel from "react-bootstrap/Carousel";

const ExploreHome = ({ exploreList }) => {
  const [modalOpen, setModalOpen] = useState(null);
  const navigate = useNavigate();

  const showOnclickHandler = (ind) => {
    // console.log(ind);
    // console.log(exploreList);

    if (modalOpen === ind) {
      setModalOpen(null);
    } else {
      setModalOpen(ind);
    }

    // console.log("090");
  };
  var selId = null;

  // console.log("modalOpen", modalOpen);

  const imgPlaceholder =
    "https://img.freepik.com/free-photo/scandinavian-living-room-interior-design-zoom-background_53876-143147.jpg?w=996&t=st=1689942362~exp=1689942962~hmac=da4e361ec8b20b6562fb952ab64f9451c0ea88ef07595a24b7364dc163f6142a";

  // 766
  // 1199
  return (
    <div className="tl-explore text-center">
      <div className="service-header px-2 py-4">
        <h2 className="bg-primar text-white px-2 py-2 mb-1 mb-md-3 f4 justify-content-center d-flex m-auto rounded rounded-3">
          Explore
        </h2>
        <p className="primar f2 m-auto">
          Neighborhood's businesses and services! includes Healthcare, Social &
          Public Services, Retails, Properties and more.
        </p>
      </div>
      <div className="tl-box d-none flex-wrap ac-jc mb-5 gap-3">
        <Carousel
          data-bs-theme="dark"
          controls={false}
          pause={true}
          interval={50000}
        >
          {exploreList.map((item, ind) => {
            return (
              <Carousel.Item>
                <div className="box bg-primar box2">
                  <div
                    className="img-box box-res align-self-sm-center align-top"
                    style={{
                      height: "48vh",
                    }}
                  >
                    <img
                      style={{ objectFit: "cover", height: "100%" }}
                      src={item?.image ? item?.image_path : imgPlaceholder}
                      alt="First slide"
                    />
                  </div>
                  <div className="detail-btm position-relative mb-2">
                    {ind === modalOpen && (
                      <div className="media-clip">
                        <button className="d-flex jc-ac cust-btn">
                          <img src={phone1} alt="" />
                          <a
                            href={`tel:+91 ${item?.user?.mobile}`}
                            target="_blank"
                          >
                            Call Now
                          </a>
                        </button>
                        <button className="d-flex jc-ac cust-btn">
                          <img src={whatsapp1} alt="" />
                          {/* <p className="">Whats App</p> */}

                          <a
                            href={`https://wa.me/91${item?.user?.mobile}`}
                            target="_blank"
                          >
                            Whats App
                          </a>
                        </button>
                        {/* <button className="d-flex jc-ac cust-btn">
                          <img src={email1} alt="" />
                          <p className="">E-mail</p>
                        </button> */}
                      </div>
                    )}
                    <button
                      onClick={() => showOnclickHandler(ind)}
                      className="time-box d-flex"
                      style={{ cursor: "text" }} // new
                    >
                      <img src={phone1} alt="" />
                    </button>
                    <h5>{item?.title}</h5>
                    <h4>{item?.categories?.name}</h4>
                    <div className="location">
                      <p>{item?.description}</p>
                    </div>
                    <div className="post-explor d-flex flex-wrap ac-jb">
                      <button
                        onClick={() =>
                          navigate("/explore/detail", {
                            state: { post_id: item?.id },
                          })
                        }
                        className="cust-btn text-white"
                      >
                        View More
                      </button>
                      <p>
                        Posy By{" "}
                        <span className="text-primary">
                          {item?.user?.name + " "}
                        </span>
                        <span className="white">
                          {" "}
                          {moment(item?.created_at).fromNow()}
                        </span>
                      </p>
                    </div>
                  </div>
                  {/* <Carousel.Caption>
                    <h5>First slide label</h5>
                    <p>
                      Nulla vitae elit libero, a pharetra augue mollis interdum.
                    </p>
                  </Carousel.Caption> */}
                </div>
              </Carousel.Item>
            );
          })}
        </Carousel>
      </div>

      <div className="tl-box d-flex flex-wrap ac-jc mb-3 gap-3">
        <OwlCarousel className="owl-theme" {...options}>
          {exploreList.map((item, ind) => {
            return (
              <div className="box bg-primar">
                <div
                  className="img-box box-res align-self-sm-center align-top"
                  style={{
                    height: "41vh",
                  }}
                >
                  <img
                    style={{ objectFit: "cover", height: "100%" }}
                    src={item?.image ? item?.image_path : imgPlaceholder}
                    alt=""
                  />
                </div>
                <div
                  className="detail-btm position-relative mb-2"
                  style={{ padding: "0px 10px" }}
                >
                  {ind === modalOpen && (
                    <div className="media-clip d-none">
                      <button className="d-flex jc-ac cust-btn">
                        <img src={phone1} alt="" />
                        <a
                          href={`tel:+91 ${item?.user?.mobile}`}
                          target="_blank"
                        >
                          Call Now
                        </a>
                      </button>
                      <button className="d-flex jc-ac cust-btn">
                        <img src={whatsapp1} alt="" />
                        {/* <p className="">Whats App</p> */}

                        <a
                          href={`https://wa.me/91${item?.user?.mobile}`}
                          target="_blank"
                        >
                          Whats App
                        </a>
                      </button>
                      {/* <button className="d-flex jc-ac cust-btn">
                      <img src={email1} alt="" />
                      <a href={`mailto:${item?.user?.email}`} target="_blank">
                        E-mail
                      </a>
                    </button> */}
                    </div>
                  )}
                  <button
                    onClick={() => showOnclickHandler(ind)}
                    // onClick={() => {
                    //   selId = item?.id;
                    // }}
                    className="time-box d-flex"
                    style={{ cursor: "text" }} // new
                  >
                    <img src={phone1} alt="" />
                  </button>
                  <h5>{item?.title}</h5>
                  <h4>{item?.categories?.name}</h4>
                  <div className="location">
                    <p>{item?.description}</p>
                  </div>
                  <div className="post-explor d-flex flex-wrap ac-jb">
                    <button
                      onClick={() =>
                        navigate("/explore/detail", {
                          state: { post_id: item?.id },
                        })
                      }
                      className="cust-btn text-white"
                    >
                      View More
                    </button>
                    <p>
                      Posy By{" "}
                      <span className="text-primary">
                        {item?.user?.name + " "}
                      </span>
                      <span className="white">
                        {" "}
                        {moment(item?.created_at).fromNow()}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </OwlCarousel>
      </div>
    </div>
  );
};

export default ExploreHome;
