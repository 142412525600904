import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

//css
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/sass/constant/Custome.scss";
import "./assets/sass/default/responsive.scss";
import "./assets/sass/default/style.scss";
import "./assets/sass/constant/keyFrames.scss";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

//local import
import PrivateRoutes from "./redux/store/privateRoutes";
import "./assets/sass/default/style.scss";
import HomeScreen from "./screens/HomeScreen";
import Layout from "./Components/layout/Layout.";
import ClassifiedListScreen from "./screens/Classified/ClassifiedListScreen";
import ClassifiedDetail from "./screens/Classified/ClassifiedDetail";
import ServiceListScreen from "./screens/services/ServiceListScreen";
import ServiceDetail from "./screens/services/ServiceDetail";
import ProfileScreen from "./screens/profile/ProfileScreen";
import Layout1 from "./Components/layout/Layout1";
import LoginScreen from "./screens/Autentication/LoginScreen";
import JobListScreen from "./screens/Jobs/JobListScreen";
import JobDetailScreen from "./screens/Jobs/JobDetailScreen";
import ExploreListScreen from "./screens/Explore/ExploreListScreen";
import ExploreDetailScreen from "./screens/Explore/ExploreDetailScreen";
import NewPostScreen from "./screens/newPost/NewPostScreen";
import PostList from "./screens/postList/PostList";
import ContactUs from "./screens/newPost/ContactUs";
import ClassifiedPostList from "./Components/postList/classifiedPost/ClassifiedPostList";
import ServicesPost from "./Components/postList/servicesPost/ServicesPost";
import JobPostList from "./Components/postList/jobPostList/JobPostList";
import ExploreList from "./Components/postList/explorePost/ExploreList";
import useToken from "./redux/store/userTocken";
import NewPostEditScreen from "./screens/newPost/NewPostEditScreen";
import AboutScreen from "./screens/About/AboutScreen";
import TermsOfUsePage from "./Components/Login/TermsOfUsePage";
import PrivacyPolicyPage from "./Components/Login/PrivacyPolicyPage";

const ReactRoutes = () => {
  const { pathname } = useLocation();
  const { token } = useToken();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Routes>
      <Route path="/login" element={<LoginScreen />} />
      <Route path="/terms-and-condition" element={<TermsOfUsePage />} />
      <Route path="/privacy-and-policy" element={<PrivacyPolicyPage />} />
      <Route element={<Layout />}>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/classified/list" element={<ClassifiedListScreen />} />
        <Route path="/classified/detail" element={<ClassifiedDetail />} />
        <Route path="/service/list" element={<ServiceListScreen />} />
        <Route path="/service/detail" element={<ServiceDetail />} />
        <Route path="/job/list" element={<JobListScreen />} />
        <Route path="/job/detail" element={<JobDetailScreen />} />
        <Route path="/explore/list" element={<ExploreListScreen />} />
        <Route path="/explore/detail" element={<ExploreDetailScreen />} />
        <Route path="/about" element={<AboutScreen />} />
        <Route element={<PrivateRoutes token={token} />}>
          <Route element={<Layout1 />}>
            <Route path="/profile" element={<ProfileScreen />} />
            <Route path="/newPost" element={<NewPostScreen />} />
            <Route path="/editPost" element={<NewPostEditScreen />} />
            <Route path="/postList" element={<PostList />}>
              <Route
                path="/postList/classifiedPost"
                element={<ClassifiedPostList />}
              />
              <Route path="/postList/servicesPost" element={<ServicesPost />} />
              <Route path="/postList/jobPostList" element={<JobPostList />} />
              <Route path="/postList/explorePost" element={<ExploreList />} />
            </Route>
            <Route path="/contact-us" element={<ContactUs />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};

export default ReactRoutes;
