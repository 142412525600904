// @ts-nocheck
import React, { useState } from "react";
import { curve, profile1 } from "../../../assets/img";
import user from "../../../assets/img/stech.jpeg";
import OwlCarousel from "react-owl-carousel";
import { options } from "../../../redux/api/constants";
import { serviceJson } from "../../../redux/api/DummyJson";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate } from "react-router-dom";
import { Col } from "react-bootstrap";
import PlaceIcon from "@mui/icons-material/Place";

const ServiceDetailLeft = ({ postDetails }) => {
  const [showSort, setShowSort] = useState(false);
  const navigate = useNavigate();

  const showHandler = () => {
    setShowSort(!showSort);
  };

  // console.log("postDetails", postDetails);

  return (
    <Col xs={12} md={6} className="tl-service  text-center pb-3 d-flex">
      <div className="service-box ac-jc d-flex flex-wrap w-100 mt-5">
        <div className="box1 position-relative bg-white">
          <div className="curve">
            <img src={curve} className="curve-img w-100" />
          </div>
          <div className="box-inner1">
            <div className="profile-img">
              {/* <KeyboardArrowDownIcon /> */}
              <img
                src={
                  postDetails?.user?.image
                    ? postDetails?.user?.image_path
                    : postDetails?.explore?.user?.image
                    ? postDetails?.explore?.user?.image_path
                    : postDetails?.user?.gender == "Female"
                    ? "https://st3.depositphotos.com/6179956/15450/i/1600/depositphotos_154504514-stock-photo-university-student-girl-looking-happy.jpg"
                    : "https://img.freepik.com/free-photo/smiley-handsome-man-posing_23-2148911841.jpg?w=740&t=st=1689942327~exp=1689942927~hmac=bda76672938776892327321166719cf5f4f117e952887c2f31377b8cacaba331"
                }
                alt=""
              />
            </div>
            <h4 className="text-capitalize">
              {postDetails?.user?.name || postDetails?.explore?.user?.name}
            </h4>
            <h5>
              {postDetails?.service?.speciality ||
                postDetails?.explore?.key_field[0]?.speciality}
            </h5>
            <p>
              {postDetails?.service?.experience
                ? postDetails?.service?.experience + ` years's Experience`
                : postDetails?.explore?.key_field[0]?.education}
            </p>
            <p>
              <PlaceIcon className="fs-18" />
              {postDetails?.service?.location || postDetails?.explore?.location}
            </p>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default ServiceDetailLeft;
