import { profile1 } from "../../assets/img";

export const classifiedJson = [
  {
    img: "https://img.freepik.com/free-photo/scandinavian-living-room-interior-design-zoom-background_53876-143147.jpg?w=996&t=st=1689942362~exp=1689942962~hmac=da4e361ec8b20b6562fb952ab64f9451c0ea88ef07595a24b7364dc163f6142a",
    name: "Scandinavian living room interior design",
    cost: 6142,
    location: "Qatar",
    postBy: "Kasimna",
    time: new Date(),
    designation: "Plubmer",
    discription: "Lorem ipsum dolor, sit amet consectetur",
  },
  {
    img: "https://img.freepik.com/free-photo/scandinavian-living-room-interior-design-zoom-background_53876-143147.jpg?w=996&t=st=1689942362~exp=1689942962~hmac=da4e361ec8b20b6562fb952ab64f9451c0ea88ef07595a24b7364dc163f6142a",
    name: "Daroo celvat 3 seat sofa in pine yellow",
    cost: 987,
    location: "Tamil nadu",
    postBy: "ArulVend",
    time: new Date(),
    designation: "Plubmer",
    discription:
      "Nisi consequuntur itaque suscipit volupt atibu, Lorem ipsum dolor, sit amet ",
  },
  {
    img: "https://img.freepik.com/free-photo/scandinavian-living-room-interior-design-zoom-background_53876-143147.jpg?w=996&t=st=1689942362~exp=1689942962~hmac=da4e361ec8b20b6562fb952ab64f9451c0ea88ef07595a24b7364dc163f6142a",
    name: "Gray sofa brown living room",
    cost: 1689,
    location: "Mumbai",
    postBy: "Ravichan",
    time: new Date(),
    designation: "Plubmer",
    discription:
      "Suscipit volupt atibu poorem ipsum dolor, sit amet consectetur",
  },
];

export const serviceJson = [
  {
    img: "https://img.freepik.com/free-photo/smiley-handsome-man-posing_23-2148911841.jpg?w=740&t=st=1689942327~exp=1689942927~hmac=bda76672938776892327321166719cf5f4f117e952887c2f31377b8cacaba331",
    name: "Daroo celvat",
    designation: "Plumber",
    cost: 987,
    location: "Qatar",
    postBy: "Ravichan",
    time: new Date(),
  },
  {
    img: "https://img.freepik.com/free-photo/close-up-young-successful-man-smiling-camera-standing-casual-outfit-against-blue-background_1258-66609.jpg?w=996&t=st=1689942149~exp=1689942749~hmac=ea2ad4216a6e86d83d1138f2c941e355bc1a19f62c311ae5e43da6e5e9c92372",
    name: "Elvat freepik",
    designation: "Developer",
    cost: 987,
    location: "Mumbai",
    postBy: "Ravichan",
    time: new Date(),
  },
  {
    img: "https://img.freepik.com/free-photo/young-beautiful-woman-pink-warm-sweater-natural-look-smiling-portrait-isolated-long-hair_285396-896.jpg?w=996&t=st=1689942183~exp=1689942783~hmac=70bd812d505710d2df2eeb012e06fb7da05fc25e0d1ed8dd0b8f781a056467d8",
    name: "Beat sofaia",
    designation: "Supervisor",
    cost: 987,
    location: "New Delhi",
    postBy: "Ravichan",
    time: new Date(),
  },
  {
    img: "https://img.freepik.com/free-photo/close-up-young-successful-man-smiling-camera-standing-casual-outfit-against-blue-background_1258-66609.jpg?w=996&t=st=1689942149~exp=1689942749~hmac=ea2ad4216a6e86d83d1138f2c941e355bc1a19f62c311ae5e43da6e5e9c92372",
    name: "Elvat freepik",
    designation: "Developer",
    cost: 987,
    location: "Mumbai",
    postBy: "Ravichan",
    time: new Date(),
  },
];

export const jobList = [
  {
    img: "https://i0.wp.com/www.globalplayboy.com/wp-content/uploads/2022/07/portrait-happy-smiley-man-1.jpeg?resize=1024%2C683&ssl=1",
    title: "IT Technology, web company",
    designation: "Marketing Head",
    location: "New Delhi",
    degree: "MTech",
    type: "Apply",
    postBy: "Martin",
    time: new Date(),
  },
  {
    img: "",
    title: "IT Technology, web company",
    designation: "Marketing Head",
    location: "New Delhi",
    degree: "MTech",
    type: "Apply",
    post: "Martin",
    time: new Date(),
  },
  {
    img: "https://i0.wp.com/www.globalplayboy.com/wp-content/uploads/2022/07/portrait-happy-smiley-man-1.jpeg?resize=1024%2C683&ssl=1",
    title: "IT Technology, web company",
    designation: "Marketing Head",
    location: "New Delhi",
    degree: "MTech",
    type: "Apply",
    postBy: "Martin",
    time: new Date(),
  },
  {
    img: "",
    title: "IT Technology, web company",
    designation: "Marketing Head",
    location: "New Delhi",
    degree: "MTech",
    type: "Apply",
    post: "Martin",
    time: new Date(),
  },
  {
    img: "https://i0.wp.com/www.globalplayboy.com/wp-content/uploads/2022/07/portrait-happy-smiley-man-1.jpeg?resize=1024%2C683&ssl=1",
    title: "IT Technology, web company",
    designation: "Marketing Head",
    location: "New Delhi",
    degree: "MTech",
    type: "Apply",
    postBy: "Martin",
    time: new Date(),
  },
];

export const exploreJson = [
  {
    img: "https://img.freepik.com/free-photo/scandinavian-living-room-interior-design-zoom-background_53876-143147.jpg?w=996&t=st=1689942362~exp=1689942962~hmac=da4e361ec8b20b6562fb952ab64f9451c0ea88ef07595a24b7364dc163f6142a",
    name: "Farewell Event",
    cost: 6142,
    location: "Chennai",
    postBy: "kevin",
    time: new Date(),
    designation: "Plubmer",
    discription: "Lorem ipsum dolor, sit amet consectetur",
  },
  {
    img: "https://img.freepik.com/free-photo/scandinavian-living-room-interior-design-zoom-background_53876-143147.jpg?w=996&t=st=1689942362~exp=1689942962~hmac=da4e361ec8b20b6562fb952ab64f9451c0ea88ef07595a24b7364dc163f6142a",
    name: "Farewell Event",
    cost: 6142,
    location: "Chennai",
    postBy: "kevin",
    time: new Date(),
    designation: "Plubmer",
    discription: "Lorem ipsum dolor, sit amet consectetur",
  },
  {
    img: "https://img.freepik.com/free-photo/scandinavian-living-room-interior-design-zoom-background_53876-143147.jpg?w=996&t=st=1689942362~exp=1689942962~hmac=da4e361ec8b20b6562fb952ab64f9451c0ea88ef07595a24b7364dc163f6142a",
    name: "Farewell Event",
    cost: 6142,
    location: "Chennai",
    postBy: "kevin",
    time: new Date(),
    designation: "Plubmer",
    discription: "Lorem ipsum dolor, sit amet consectetur",
  },
];
