import React from "react";
import JobLists from "../../Jobs/JobList/JobLists";
import JobListBox from "../../Jobs/JobList/JobListBox";

const JobPostList = () => {
  return (
    <div className="jc-ac">
      <JobListBox method="TabMethod" />
    </div>
  );
};

export default JobPostList;
