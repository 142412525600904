import React, { useEffect, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import DoneIcon from "@mui/icons-material/Done";
import { useNavigate } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import HomeIcon from "@mui/icons-material/Home";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import TransgenderIcon from "@mui/icons-material/Transgender";
import BloodtypeIcon from "@mui/icons-material/Bloodtype";
import { NUMBER } from "../../redux/api/constants";
import companyLogo from "../../assets/img/header_logo.png";
import { useRegisterMutation } from "../../redux/api/api";
import { useDispatch } from "react-redux";
import { saveAlertMessage } from "../../redux/slice/alertMessage";
import Select from "react-select";
import { terms_and_conditions } from "../../assets/img";
import TermsOfUse from "./TermsOfUse";

const RegistrationRight = ({
  showLoginHandler,
  setRegister,
  setNumber: setLoginNum,
}) => {
  const dispatch = useDispatch();
  const [checkBox, setCheckBox] = useState(false);

  const [register] = useRegisterMutation();

  const [username, setUserName] = useState("");
  const [number, setNumber] = useState("");
  const [streetname, setStreetName] = useState({});
  const [country, setCountry] = useState("");
  const [profession, setProfession] = useState("");
  const [gender, setGender] = useState({});
  const [genders, setGenders] = useState("Gender");
  const [bloodGroup, setBloodGroup] = useState({});
  const [bloodGroups, setBloodGroups] = useState("bloodGroup");
  const [checkInp, setCheckInp] = useState("");
  const [checkInp2, setCheckInp2] = useState("");
  const [checkInp3, setCheckInp3] = useState("");

  // ERROR STATES
  const [usernameErr, setUserNameErr] = useState(false);
  const [numberErr, setNumberErr] = useState(false);
  const [streetnameErr, setStreetNameErr] = useState(false);
  const [countryErr, setCountryErr] = useState(false);
  const [professionErr, setProfessionErr] = useState(false);
  const [genderErr, setGenderErr] = useState(false);
  const [bloodGroupErr, setBloodGroupErr] = useState(false);

  const [option, setOption] = useState([]);
  const [bloodGOptions, setBloodGOptions] = useState([]);
  const [genderOptions, setGenderOptions] = useState([]);

  const streetNames = [
    "Yadavar Street",
    "Veerappa Aasari Street",
    "Vatta Thaikkal",
    "Vathiya Palli Street",
    "Varadaraja Perumal Koil Street",
    "Vanuvar Street",
    "Vannarapalayam Arijana Street",
    "Vannarapalayam Appasamy Padayachi Street",
    "Vannarapalayam",
    "Vandikkara Street",
    "Vallathambi Maraickayar Street",
    "Vakkambilbeer Street",
    "Vadakuthurai Vannarapalayam",
    "Vadakauthurai Arijina Colony",
    "Uppukara Street",
    "Uppukara Lane",
    "Thonothurai",
    "Thiyagu Chetty Street",
    "Thethukadai Street",
    "Thesan Tharka Street",
    "Thesan Thaikkal Street",
    "Therodum South Street",
    "Theetha Mudali Street",
    "Syed Mohamed Sahib Street",
    "Sivan Padagar Street",
    "Small Street",
    "Seshaya Nagar",
    "Senjiluvai Salai",
    "Second Double Well Street",
    "Sanjeevirayar Koil Street",
    "Sanaramudukku Street",
    "Samynathan Street",
    "Salangukara West Street",
    "Salangukara East Street",
    "Salakarai Mariyamman Koil Street",
    "Sakkilimedu",
    "Sahib Miyan",
    "S S Nagar",
    "Revu Main Road",
    "Renga Pillai Mandapa Street",
    "Ramapadayatchi Street",
    "Police Line",
    "Pettar Street",
    "Perumal Koil Street",
    "Periyanayaki Amman Koil Street",
    "Big Street",
    "Periya Kadai Street",
    "Periya Asarakana Street",
    "Pappan Kodi Street",
    "Pandaga Salai Street",
    "Nellu Kadai Street",
    "Nattan Kinnatru Mudukku Street",
    "Nanthavana Agrahara Street",
    "Nallam Bala Pillai Street",
    "Nakutha Maraickayar Street",
    "Muthal Double Well Street",
    "Muthaiya Muthaliyar Street",
    "Moulana Thaikal",
    "Mittai Kadai Street",
    "Mishan Street",
    "Mirjamiyan Street",
    "Mettu Street",
    "Mettu Pattani Mudukku Street",
    "Meeran Sandhu",
    "Meera Palli Street",
    "Meen Kadai Street",
    "Mattukaran Thaikal",
    "Mathina Nagar",
    "Malimar Nagar",
    "Makka Garden",
    "Main Road",
    "Madha Koil Street",
    "Logaiya Naidu Street",
    "Kuttiya Chetty Street",
    "Kummath Palli Street",
    "Kumarakoil South Street",
    "Kumarakoil East Street",
    "Kotthar Street",
    "Kotthar Lane",
    "Kottathangarai Street",
    "Kolan Kadai Street",
    "Kodi Marathu Street Rajaji Street",
    "Kitharsha Maraickayar Street",
    "Keerai Kara Street",
    "Katcherry Street",
    "Karunanithi Street",
    "Kamatchi Amman Koil Street",
    "Kallamuthiya Chetty Street",
    "Kalima Nagar Extention",
    "Kalima Nagar",
    "Kalima Complex",
    "Kajiyar Street",
    "Kajiyar Lane",
    "Kaithe Millath Nagar",
    "Junnath Miyan Street",
    "Jain Bava Thaikkal",
    "Hakka Sahib Darga Street",
    "Haji Bakkeer Malimar Tharga Street",
    "Gurusamy Rayar Street",
    "Gurusamy Mettu Street",
    "Gurunatha Chetty Mettu Street",
    "Fathima Nagar",
    "Ethirees Maraickayar Nagar",
    "Ellaiamman Koil Street",
    "Ekambara Aasari Street",
    "Dr Mohamed Ibrahim Nagar",
    "Dr Abdhul Kalam Nagar",
    "Cresent Nagar",
    "Chinna Kadai Street",
    "Chetty Street",
    "Bharath Nagar",
    "Bargon Mudukku Street",
    "B Manampadi Kadai Street",
    "Aysha Nagar",
    "Atrangarai Mettu Street",
    "Atrangarai Arijana Colony",
    "Athimoolasami Street",
    "Athangarai East Street",
    "Asiriyar Nagar",
    "Arunachala Mudaliyar Street",
    "Ariyanattu West Street",
    "Ariyanattu Vaduga Street",
    "Ariyanattu South Street",
    "Ariyanattu East Street",
    "Ariyanaattu Kavara Street",
    "Ariyanaattu Chattira Street",
    "Arakatchi Nachiyar Street",
    "Appasamy Padayachi Street",
    "Appa Palli Street",
    "Anusuya Nagar",
    "Anna Nagar",
    "Ameer Gardan",
    "Abdul Rahim Nagar",
    "Aagin Thaikal",
    "A P S Garden",
    "A K Nagar",
    "Agaram Street",
    "Anna Koil Street",
    "Beach Road",
    "Jain Bava Street",
    "Kadaikara Bawa Street",
    "Karikuppam Mainroad",
    "Khaziyar Santhu",
    "Khaziyar Street",
    "Periya Mathu Street",
    "Pillayar Koil Street",
    "Puthuchathiram Street",
    "Quaidha Millathu Street",
    "Rail Adi Street",
    "Redcross Street",
    "Royal Street",
  ];
  const bloodGrp = ["A+", "A-", "B+", "B-", "O+", "O-", "AB+", "AB-"];
  const genderVal = ["Male", "Female"];

  useEffect(() => {
    // STREET OPTIONS
    let temp = [];
    streetNames.sort().forEach((e) => {
      temp.push({
        value: e,
        label: e,
      });
    });
    setOption(temp);
    // BLOOD GROUP OPTIONS
    let bloodGTmp = [];
    bloodGrp.forEach((e) => {
      bloodGTmp.push({
        value: e,
        label: e,
      });
    });
    setBloodGOptions([...bloodGTmp]);
    // GENDER OPTIONS
    let genderTmp = [];
    genderVal.forEach((e) => {
      genderTmp.push({
        value: e,
        label: e,
      });
      setGenderOptions(genderTmp);
    });
  }, []);

  const navigate = useNavigate();
  const onClickHandler = () => {
    setCheckBox(!checkBox);
  };
  const sumbitHandler = () => {
    let formdata = new FormData();
    if (
      username?.length < 4 ||
      number.length < 10 ||
      streetname?.value?.length == undefined ||
      country?.length < 0 ||
      profession?.length < 0 ||
      gender === "" ||
      bloodGroup === ""
    ) {
      setUserNameErr(true);
      setNumberErr(true);
      setStreetNameErr(true);
      setCountryErr(true);
      setProfessionErr(true);
      setGenderErr(true);
      setBloodGroupErr(true);
    } else if (!checkBox) {
      // alert("Agree the terms and Conditions");
      dispatch(saveAlertMessage("Agree the terms and Conditions"));
    } else {
      formdata.append("mobile", number);
      formdata.append("name", username);
      formdata.append("address", streetname?.value);
      formdata.append("country", country);
      formdata.append("profession", profession);
      formdata.append("gender", gender?.value);
      formdata.append("blood_group", bloodGroup?.value);

      register(formdata)
        .unwrap()
        .then((res) => {
          if (res?.status == "success") {
            // alert(res?.message);
            dispatch(saveAlertMessage(res?.message));
            setRegister(false);
          }
        })
        .catch((err) => {
          console.log(err);
          // alert(err?.data?.message);
          // dispatch(saveAlertMessage(err?.data?.message))
        });
      // navigate("/");
    }
  };

  // SPECIAL CHARACTER DONT ALLOW

  const checkSpecialChar = (e) => {
    if (!/^[a-zA-Z\s ]+$/.test(e.key)) {
      e.preventDefault();
    }
  };

  const [termscond, setTermsCond] = useState(false)

  const termsHandle = () => {
    setTermsCond(!termscond)
  }

  return (
    <div className="SecondFlex overflow-y-scroll">
      {termscond && <TermsOfUse termsHandle={termsHandle} />}
      <div className="SecondFlexDesign ac-jc d-grid" style={{ height: "100%" }}>
        <img
          src={companyLogo}
          className="companyLogoRegister d-block d-md-none"
        />
        <div className="">
          <h2 className="LoginText1">Register</h2>
          {/* <label className="LoginFont">
            Enter The OTP on your Mobile Number
          </label> */}
          <div className="form-groups position-relative">
            <input
              type="text"
              placeholder="Name"
              className="MobileNumberTextInput1"
              onChange={(e) => setUserName(e.target.value)}
              value={username}
              onKeyDown={(e) => checkSpecialChar(e)}
            />
            <PersonIcon className="position-absolute s-10 t-8" />
            {usernameErr && username?.length < 4 && (
              <p className="error-state">Enter your name*</p>
            )}
          </div>
          <div className="form-groups position-relative">
            <input
              onInput={(e) => {
                if (e.target.value.length > e.target.maxLength)
                  e.target.value = e.target.value.slice(0, e.target.maxLength);
                if (e.target.value.length !== 10) {
                  e.target.setCustomValidity("invalid Number");
                } else if (e.target.value.length == 10) {
                  e.target.setCustomValidity("");

                  setNumber(e.target.value);
                }
              }}
              onFocus={(e) =>
                e.target.addEventListener(
                  "wheel",
                  function (e) {
                    e.preventDefault();
                  },
                  { passive: false }
                )
              }
              maxlength={10}
              onChange={(e) => {
                setNumber(e.target.value);
                setLoginNum(e.target.value);
              }}
              value={number}
              type="number"
              className="MobileNumberTextInput2"
            />
            <PhoneIcon className="position-absolute s-10 t-8" />
            <p
              className="numbervalue91"
              style={
                number?.length > 0 ? { color: "black" } : { color: "#757575" }
              }
            >
              +91
            </p>
            {numberErr &&
              (NUMBER.test(number) == false || number.length < 10) && (
                <p className="error-state">Enter your Valid phone*</p>
              )}
          </div>
          <div className="position-relative">
            {checkInp?.length > 0 ? null : streetname?.value?.length >
              0 ? null : (
              <p className="street-placeholder">Street Name</p>
            )}
            <div className="form-groups react-select position-relative">
              <Select
                placeholder="assad"
                type="text"
                value={streetname}
                onChange={(e) => setStreetName(e)}
                onInputChange={(e) => setCheckInp(e)}
                options={option}
              />
              <LocationOnIcon className="position-absolute s-10 t-8" />
              {streetnameErr && streetname?.value?.length == undefined && (
                <p className="error-state">Enter Your Street Name</p>
              )}
            </div>
          </div>
          <div className="form-groups position-relative">
            <input
              type="text"
              placeholder="Country of residence"
              className="MobileNumberTextInput1"
              onChange={(e) => setCountry(e.target.value)}
              value={country}
            />
            <HomeIcon className="position-absolute s-10 t-8" />
            {countryErr && country?.length < 4 && (
              <p className="error-state">Enter Your Country</p>
            )}
          </div>
          <div className="form-groups position-relative">
            <input
              type="text"
              placeholder="Profession"
              className="MobileNumberTextInput1"
              onChange={(e) => setProfession(e.target.value)}
              value={profession}
            />
            <BusinessCenterIcon className="position-absolute s-10 t-8" />
            {professionErr && profession?.length < 4 && (
              <p className="error-state">Enter Your Profession</p>
            )}
          </div>
          <div className="d-flex position-relative gap-3 w-100">
            {checkInp2?.length > 0 ? null : gender?.value?.length > 0 ? null : (
              <p className="street-placeholder">Gender</p>
            )}
            <div className="form-groups w-100 position-relative react-select">
              <Select
                placeholder="Gender"
                style={{
                  color: genders !== "Gender" ? "#000" : "#757575",
                }}
                value={gender}
                onChange={(e) => {
                  setGender(e);
                  setGenders(e);
                }}
                options={genderOptions}
                onInputChange={(e) => setCheckInp2(e)}
              />
              {/* <select
                className="MobileNumberTextInput1"
                style={{
                  color: genders !== "Gender" ? "#000" : "#757575",
                }}
                value={gender}
                onChange={(e) => {
                  setGender(e.target.value);
                  setGenders(e.target.value);
                }}
              >
                <option value="Gender" hidden>
                  Gender
                </option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select> */}
              <TransgenderIcon className="position-absolute s-10 t-8" />
              {genderErr && gender?.value?.length === undefined && (
                <p className="error-state-genderBlood">Select Your Gender</p>
              )}
            </div>
            <div className="form-groups w-100 position-relative react-select">
              {checkInp3?.length > 0 ? null : bloodGroup?.value?.length >
                0 ? null : (
                <p className="street-placeholder">Blood Group</p>
              )}
              <Select
                placeholder="Blood Group"
                value={bloodGroup}
                style={{
                  color: bloodGroups !== "bloodGroup" ? "#000" : "#757575",
                }}
                onChange={(e) => {
                  setBloodGroup(e);
                  setBloodGroups(e);
                }}
                options={bloodGOptions}
                onInputChange={(e) => setCheckInp3(e)}
              />
              {/* <select
                className="MobileNumberTextInput1"
                value={bloodGroup}
                style={{
                  color: bloodGroups !== "bloodGroup" ? "#000" : "#757575",
                }}
                onChange={(e) => {
                  setBloodGroup(e.target.value);
                  setBloodGroups(e.target.value);
                }}
              >
                <option value="bloodGroup" hidden>
                  Blood Group
                </option>
                <option value="A+">A+</option>
                <option value="A-">A-</option>
                <option value="B+">B+</option>
                <option value="B-">B-</option>
                <option value="O+">O+</option>
                <option value="O-">O-</option>
                <option value="AB+">AB+</option>
                <option value="AB-">AB-</option>
              </select> */}
              <BloodtypeIcon className="position-absolute s-10 t-8" />
              {bloodGroupErr && bloodGroup?.value?.length === undefined && (
                <p className="error-state-genderBlood">
                  Select Your Blood Group
                </p>
              )}
            </div>
          </div>
          <div className="checkBoxDesign d-flex">
            <button
              onClick={onClickHandler}
              className="cust-btn check-box d-flex ac-jc"
            >
              {checkBox && <DoneIcon />}
            </button>
            <p className="CheckBoxText">
              By Login you are agreeing to our{" "}
              <button
                className="cust-btn text-primary me-1"
                // onClick={() => window.open(terms_and_conditions, "_blank")}
                onClick={termsHandle}
              >
                Terms of use{" "}
              </button>{" "}
              {/* and
              <button className="cust-btn text-primary ms-1">
                {" "}
                Privacy Policy
              </button> */}
            </p>
          </div>
          <button
            onClick={sumbitHandler}
            className="cust-btn py-2 mt-3 w-100 bg-primar text-light f2 btn-submit"
          >
            Continue
          </button>
          <p className="NotRegisteredText f4 mt-3">
            Already have an{" "}
            <button
              onClick={showLoginHandler}
              className="cust-btn text-primary"
            >
              {" "}
              Account?
            </button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default RegistrationRight;
