import React, { useEffect, useState } from "react";
import JobDetailLeft from "../../Components/Jobs/jobDetail/JobDetailLeft";
import JobsDetailRight from "../../Components/Jobs/jobDetail/JobsDetailRight";
import JobsRelatePost from "../../Components/Jobs/jobDetail/JobsRelatePost";
import ServiceDetailLeft from "../../Components/Jobs/jobDetail/ServiceDetailLeft";
import { useLocation } from "react-router-dom";
import { useJobVacancyViewMutation } from "../../redux/api/api";
import { lodergif } from "../../assets/img";
const JobDetailScreen = () => {
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const post_id = location?.state?.post_id;
  const [postDetails, setPostDetails] = useState({});

  // RTK QUERY
  const [jobView] = useJobVacancyViewMutation();

  useEffect(() => {
    if (post_id) {
      const formData = new FormData();
      formData.append("post_id", post_id);
      setLoader(true);
      jobView(formData)
        .unwrap()
        .then((res) => {
          if (res?.status === "success") {
            // console.log(res);
            setLoader(false);
            setPostDetails(res);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
    }
  }, [post_id]);

  return (
    <div className="bg-white">
      {loader && (
        <div className="loader-icon position-fixed bg-opacity-25 loderbg ac-jc">
          <div className="loader-icon1">{/* <img src={lodergif} /> */}</div>
        </div>
      )}
      <div className="w-90 pb-3">
        <div className="d-flex flex-wrap">
          <JobDetailLeft postDetails={postDetails} />
          {/* <ServiceDetailLeft /> */}
          <JobsDetailRight postDetails={postDetails} />
        </div>
        <JobsRelatePost postDetails={postDetails} />
      </div>
    </div>
  );
};

export default JobDetailScreen;
