export const profile1 = require("./img1.jpg");
export const curve = require("./curve.png");
export const logo = require("./header_logo.png");
export const phone1 = require("./icons/phone.png");
export const whatsapp1 = require("./icons/whatsapp.png");
export const email1 = require("./icons/gmail.png");
export const classifiedBanner = require("./classified_banner.jpg");
export const sofa = require("./img2.jpg");
export const Sofa1 = require("./Sofa_1.jpg");
export const login = require("./login.jpg");
export const fileUpload = require("./Assets/Assets/file-upload.png");
export const singlephone = require("./Assets/Assets/icons/phone (1).png");
export const Loader = require("./Loader.gif");
export const arrow = require("./arrowright.png");
export const location = require("./locationicon.png");
export const lodergif = require("./lodergif.gif");
// export const lodergif = require("./new_loader.gif");
export const home_bann = require("./home_banner_image.jpg");
export const abt_commerce = require("./abt_commerce.png");
export const abt = require("./abt.png");
export const digital = require("./digital.png");
export const education = require("./Education.jpeg");
export const vision = require("./vision.png");
export const menu = require("./menu-icon.png");
export const terms_and_conditions = require("./T&C.pdf");
export const explore_banner = require("./Explore.jpeg");
export const jobs_banner = require("./Jobs.jpeg");
export const classified_banner = require("./Classified.jpeg");
export const service_banner = require("./Services.jpeg");
