import React, { useState } from "react";
import { Col } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import sofa_1 from "../../../assets/img/Sofa_1.jpg";
import sofa_2 from "../../../assets/img/Sofas.jpg";
import { sofa } from "../../../assets/img";

const ClassifiedBanner = ({ postDetails }) => {
  const [currentPhoto, setCurrentPhoto] = useState(1);
  const imageSelHandler = (e) => {
    // console.log("e", e);
    setCurrentPhoto(e + 1);
  };
  return (
    <Col
      xs={12}
      md={6}
      className="ser-caurosel-cont caros-box text-center pb-3  ac-jc"
    >
      <div className=" ac-jc d-flex flex-wrap mt-5">
        {postDetails?.classified?.images?.length > 0 ? (
          <div
            className="align-items-center justyfy-content-center img-cont"
            style={{ position: "relative" }}
          >
            <Carousel onSlide={(e) => imageSelHandler(e)} fade className="">
              {postDetails?.classified?.images?.map((item) => {
                return (
                  <Carousel.Item
                    key={item?.id}
                    interval={30000000}
                    className="align-items-center justyfy-content-center img-cont1"
                  >
                    <img
                      src={item?.image_path}
                      className="temp_img_size"
                      alt="banner-img align-self-center"
                    />
                  </Carousel.Item>
                );
              })}
            </Carousel>
            <p className="">
              {currentPhoto} / {postDetails?.classified?.images?.length} Photos
            </p>
          </div>
        ) : (
          <div
            className="align-items-center justyfy-content-center img-cont"
            style={{ position: "relative" }}
          >
            <Carousel onSlide={(e) => imageSelHandler(e)} fade className="">
              <Carousel.Item
                interval={30000000}
                className="align-items-center justyfy-content-center img-cont1"
              >
                <img
                  src={sofa}
                  className="temp_img_size"
                  alt="banner-img align-self-center"
                />
              </Carousel.Item>
            </Carousel>
            <p className="">
              {currentPhoto} / {postDetails?.classified?.images?.length} Photos
            </p>
          </div>
        )}
      </div>
    </Col>
  );
};

export default ClassifiedBanner;
