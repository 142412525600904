import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { abt } from '../../../assets/img'

const CommerceRight = () => {
    return (
        <Col xs={12} md={6} className="service-detail-sec col-custom-res align-self-center">
            <h5 className="primary1 font-custom
                ">COMMERCE
                <div className='activeLine align-self-center' />
            </h5>
            <div className="my-2 desc-txt font-custom2">
                <p>
                    In the spirit of broadening our horizons, Asquare made a humble beginning by introducing an array of top-quality goods. What began as a small venture has steadily evolved, fueled by our passion to provide our community with a diverse range of premium-quality goods.
                </p>
            </div>
        </Col>
    )
}

export default CommerceRight