// @ts-nocheck
import React, { useEffect, useState } from "react";
import { curve, profile1 } from "../../assets/img";
import user from "../../assets/img/stech.jpeg";
import OwlCarousel from "react-owl-carousel";
import { options } from "../../redux/api/constants";
import { serviceJson } from "../../redux/api/DummyJson";
import PlaceIcon from "@mui/icons-material/Place";
import { useNavigate } from "react-router-dom";
import { useHomeCategoryShowMutation } from "../../redux/api/api";
import moment from "moment/moment";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";

const ServiceHome = ({ serviceList }) => {
  const navigate = useNavigate();
  const dummyImage =
    "https://img.freepik.com/free-photo/young-beautiful-woman-pink-warm-sweater-natural-look-smiling-portrait-isolated-long-hair_285396-896.jpg?w=996&t=st=1689942183~exp=1689942783~hmac=70bd812d505710d2df2eeb012e06fb7da05fc25e0d1ed8dd0b8f781a056467d8";

  return (
    <div className="tl-service bg-gray4 text-center pb-3">
      <div className="service-header px-2 py-4">
        <h2 className="bg-dark text-light px-2 py-2 mb-1 mb-md-3 f4 justify-content-center d-flex m-auto rounded rounded-3">
          Services
        </h2>
        <p className="text-black f2 m-auto">
          Proficient professionals stand ready to help with all your needs.
        </p>
      </div>
      <div className="service-box ac-jc d-flex flex-wrap gap-3 w-90">
        <OwlCarousel className="owl-theme" {...options}>
          {serviceList?.map((item, ind) => {
            // const { img, name, designation, location, postBy, time } = item;
            // console.log(item);
            return (
              <div
                className="box position-relative bg-white mb-2 mb-sm-3 md-md-5"
                key={ind}
              >
                <div className="curve">
                  <img src={curve} className="curve-img w-100" />
                </div>
                <div className="box-inner">
                  <div className="profile-img">
                    <img
                      src={
                        item?.user?.image ? item?.user?.image_path : dummyImage
                      }
                      alt=""
                    />
                  </div>

                  <h4 className="">
                    {item?.title?.length > 30
                      ? item?.title?.slice(0, 30) + "..."
                      : item?.title}
                  </h4>
                  <h5>{item?.speciality}</h5>
                  <p>
                    <LocationOnOutlinedIcon className="fs-15" />
                    {item?.location}
                  </p>
                  <button
                    onClick={() =>
                      navigate("/service/detail", {
                        state: { post_id: item?.id },
                      })
                    }
                    className="cust-btn"
                  >
                    Read More
                  </button>
                  <p>
                    Post By {item?.user?.name}{" "}
                    <span> {moment(item?.created_at).fromNow()}</span>
                  </p>
                </div>
              </div>
            );
          })}
        </OwlCarousel>
      </div>
    </div>
  );
};

export default ServiceHome;
