// @ts-nocheck
import React, { useEffect, useState } from "react";
import { profile1, singlephone, whatsapp1 } from "../../assets/img";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import PlaceIcon from "@mui/icons-material/Place";
import OwlCarousel from "react-owl-carousel";
import { options } from "../../redux/api/constants";
import { classifiedJson } from "../../redux/api/DummyJson";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import { useHomeCategoryShowMutation } from "../../redux/api/api";

import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";

const ClassifiedHome = ({ classifiedList }) => {
  const dummyImage =
    "https://img.freepik.com/free-photo/scandinavian-living-room-interior-design-zoom-background_53876-143147.jpg?w=996&t=st=1689942362~exp=1689942962~hmac=da4e361ec8b20b6562fb952ab64f9451c0ea88ef07595a24b7364dc163f6142a";
  const navigate = useNavigate();

  return (
    <div className="tl-classified text-center">
      <div className="service-header px-2 py-4">
        <h2 className="bg-primar text-white px-2 py-2 mb-1 mb-md-3 f4 justify-content-center d-flex m-auto rounded rounded-3">
          Classified
        </h2>
        <p className="primar f2 m-auto">
          Discover the pulsating life of a local market.
        </p>
      </div>
      <div className="tl-box d-flex flex-wrap ac-jc mb-5">
        <OwlCarousel className="owl-theme" {...options}>
          {classifiedList?.map((item, ind) => {
            // const { image_path, name, cost, location, postBy, time } = item;
            // console.log(item);
            return (
              <div className="item" key={ind}>
                <div className="box bg-primar box2">
                  <div className="detail-btm">
                    <div
                      className="img-box box-res align-self-sm-center align-top image-custom-size"
                      style={{
                        height: "40vh",
                        width: "100%",
                        zIndex: 0,
                      }}
                    >
                      <img
                        style={{ objectFit: "cover", height: "100%" }}
                        src={
                          item?.images?.[0]?.image
                            ? item?.images?.[0]?.image_path
                            : dummyImage
                        }
                        alt=""
                      />
                    </div>
                    <div className="time-box">
                      <h4>{moment(item?.created_at).fromNow()}</h4>
                    </div>
                    <div
                      style={{
                        // backgroundColor: 'red',
                        paddingTop: "20px",
                        marginLeft: "15px",
                        marginRight: "15px",
                        marginBottom: "15px",
                      }}
                    >
                      <h5>{item?.title}</h5>
                      <h5> {item?.rate ? "₹ " + item?.rate : <> &nbsp;</>}</h5>
                      <div className="location mt-2">
                        <p>
                          <LocationOnOutlinedIcon className="fs-15" />
                          {item?.location}
                        </p>
                      </div>
                      <div className="post-explor d-flex ac-jb">
                        <p>
                          Post By{" "}
                          <span className="text-primary">
                            {item?.user?.name}
                          </span>
                        </p>
                        <button
                          onClick={() =>
                            navigate("/classified/detail", {
                              state: { post_id: item?.id },
                            })
                          }
                          className="cust-btn"
                        >
                          Explore More <ArrowForwardIcon />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </OwlCarousel>
      </div>
      {/* <div className="hide-cont">
        <div className='d-flex flex-column position-absolute said-btn'
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            right: '16px',
            gap: '10px',
            top: '960px'
          }}>
          <img src={singlephone} style={{ height: '20px', width: '20px' }} />
          <img src={whatsapp1} style={{ height: '20px', width: '20px' }} />
          <button className="btn btn-primary w-10"
            style={{
              color: '#fff',
            }}
          >
            <p className="btn-texts">E</p>
            <p className="btn-texts">N</p>
            <p className="btn-texts">Q</p>
            <p className="btn-texts">U</p>
            <p className="btn-texts">I</p>
            <p className="btn-texts">R</p>
            <p className="btn-texts">Y</p>
          </button>
        </div>
      </div> */}
    </div>
  );
};

export default ClassifiedHome;
