import React from 'react'
import AboutusLeft from './AboutusLeft'
import AboutusRight from './AboutusRight'

const AboutComo = () => {
    return (
        <div className="bg-white my-5 w-90">
            <div className='d-flex flex-wrap justify-content-between align-custom  align-items-cente'>
                <AboutusLeft />
                <AboutusRight />
            </div>
        </div>
    )
}

export default AboutComo