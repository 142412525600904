import React from "react";
import { Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { jobList } from "../../../redux/api/DummyJson";
import PlaceIcon from "@mui/icons-material/Place";
import OwlCarousel from "react-owl-carousel";
import { row2 } from "../../../redux/api/constants";
import moment from "moment";

const JobRelatedLis = ({ postDetails }) => {
  const navigate = useNavigate();
  return (
    <div className="job-box align-items-center d-flex flex-wrap w-100 p-3">
      <OwlCarousel className="owl-theme" {...row2}>
        {postDetails?.profiles?.map((item, ind) => {
          const vacancy = item?.categories?.parent?.name;
          return (
            // <button
            //   onClick={() => navigate("/job/detail")}
            //   className="job-box bg-sec1 border-0 w-100"
            // >
            //   <div className="box-inner flex-column flex-md-row align-items-center justify-content-center justify-content-md-between d-flex">
            <div
              onClick={() => {
                navigate("/job/detail", {
                  state: { post_id: item?.id },
                });
                // console.log("qoqkq");
              }}
              className="job-box m-1 bg-sec1"
              key={ind}
            >
              <button className="box-inner cust-btn flex-column flex-md-row align-items-center justify-content-center justify-content-md-between d-flex">
                <div className="profile-img">
                  <img
                    style={{ marginTop: "0px" }}
                    src={
                      vacancy?.toLowerCase().startsWith("appli")
                        ? item?.user?.image
                          ? item?.user?.image_path
                          : "https://cdn.vectorstock.com/i/1000x1000/23/81/default-avatar-profile-icon-vector-18942381.webp"
                        : item?.image
                        ? item?.image_path
                        : "https://cdn.vectorstock.com/i/1000x1000/23/81/default-avatar-profile-icon-vector-18942381.webp"
                    }
                    alt=""
                  />
                </div>
                <div className="line3 d-none d-md-block" />
                <div className="rit-box-jox text-center text-md-start">
                  <h4
                    style={{
                      textTransform: "capitalize",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {item?.job_title}
                  </h4>
                  <h5 style={{ textTransform: "capitalize" }}>
                    {vacancy?.toLowerCase().startsWith("appli")
                      ? // ? item?.experience + `Years's Experience`
                        item?.job_position
                      : item?.job_position}
                  </h5>
                  <p>
                    <PlaceIcon className="fs-15 mb-1" />
                    {vacancy?.toLowerCase().startsWith("appli")
                      ? item?.applicant_location
                      : item?.job_location}
                  </p>
                  <p
                    style={{
                      textTransform: "capitalize",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {vacancy?.toLowerCase().startsWith("appli")
                      ? " "
                      : item?.company_name}
                  </p>
                  <div className="d-flex w-100 justify-content-center justify-content-md-end">
                    <button className="cust-btn">
                      {vacancy?.toLowerCase().startsWith("vac")
                        ? "Apply"
                        : "Hire"}
                    </button>
                  </div>
                  <div className="d-flex w-100 ac-jb mt-2">
                    <p style={{ textTransform: "capitalize" }}>
                      Post By <span>{item?.user?.name} </span>
                    </p>
                    <p>
                      <span>{moment(item?.created_at).fromNow()}</span>
                    </p>
                  </div>
                </div>
              </button>
            </div>
            //   </div>
            // </button>
          );
        })}
      </OwlCarousel>
    </div>
  );
};

export default JobRelatedLis;
