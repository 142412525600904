import React from 'react'
import EducationLeft from './EducationLeft'
import EducationRight from './EducationRight'

const EducationComo = () => {
    return (
        <div className="bg-white my-5 w-90">
            <div className='d-flex flex-wrap justify-content-between  align-items-cente comp-hide'>
                <EducationLeft />
                <EducationRight />
            </div>
            <div className="d-flex flex-wrap justify-content-around  align-items-cente comp-show"
                style={{ display: 'none' }}
            >
                <EducationRight />
                <EducationLeft />
            </div>
        </div>
    )
}

export default EducationComo