import React from "react";
import ClassifiedPostList from "../../Components/postList/classifiedPost/ClassifiedPostList";
import PostTab from "../../Components/postList/PostTab";
import { Outlet } from "react-router-dom";

const PostList = () => {
  return (
    <div className="rit-post-list h-100">
      <div className="topTab">
        <PostTab />
      </div>
      <Outlet />
    </div>
  );
};

export default PostList;
