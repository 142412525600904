import React from "react";
import { CloseButton } from "react-bootstrap";
import ArrowRightAltRoundedIcon from "@mui/icons-material/ArrowRightAltRounded";

const TermsOfUse = ({ termsHandle }) => {
  return (
    <div className="modal-popup">
      <div className="back-close" />
      <div className="center-content pb-5">
        <button
          className="modal-cls-btn fs-33 f5 primary px-2 mb-0 mx-2 border-0"
          onClick={termsHandle}
        >
          <CloseButton />
        </button>
        <div className=" pt-5 px-4 w-100 justify-content-center mt-md-5 flex-md-row">
          <h4 className="f4 text-dark fs-xl-23 fs-lg-19 fs-md-20 fs-sm-21 fs-xs-22 box-text-form text-center">
            Terms and Conditions
          </h4>
        </div>
        <div className="w-70 mt-4 mb-0 service-detail-sec">
          <h1 className="font-custom primary1">1. Introduction</h1>
          <div className="my-2 desc-txt font-custom2">
            <p>
              Welcome to PARANGIPETTAI.COM. These terms and conditions outline
              the rules and regulations for the use of ASQUARES’s website,
              located at https://parangipettai.com.
            </p>
            <div className="my-2 desc-txt">
              <p>
                By accessing this website, we assume you accept these terms and
                conditions. Do not continue to use PARANGIPETTAI.COM if you do
                not agree with all of the terms and conditions stated on this
                page.
              </p>
            </div>
          </div>
        </div>
        <div className="w-70 mt-4 mb-0 service-detail-sec">
          <h1 className="font-custom primary1">2. Content Responsibility</h1>
          <div className="my-2 desc-txt font-custom2">
            <p>
              All content posted, shared, or uploaded by users on this website
              is the sole responsibility of the individual who provided it.
              PARANGIPETTAI.COM is not responsible for user-generated content,
              including its accuracy, appropriateness, or legality. Users may be
              held legally accountable for their posted content, especially if
              it infringes on the rights of others or violates the law.
            </p>
          </div>
        </div>
        <div className="w-70 mt-4 mb-0 service-detail-sec">
          <h1 className="font-custom primary1">3. Content Guidelines</h1>
          <div className="my-2 desc-txt font-custom2">
            <p>
              Given that this website is accessible by families and children,
              users are urged to post content that is appropriate for all age
              groups. The administrator reserves the right to remove any content
              deemed unsuitable or not adhering to our content guidelines
              without prior notice.
            </p>
          </div>
        </div>
        <div className="w-70 mt-4 mb-0 service-detail-sec">
          <h1 className="font-custom primary1">
            4. Rights of the Administrator
          </h1>
          <div className="my-2 desc-txt font-custom2">
            <p className="mb-3">
              The administrator of PARANGIPETTAI.COM reserves the right to:
            </p>
            <div className="d-flex">
              <ArrowRightAltRoundedIcon className="font-custom" />
              <p>
                {" "}
                Remove or modify any content that does not adhere to our
                policies or guidelines.
              </p>
            </div>
            <div className="d-flex">
              <ArrowRightAltRoundedIcon className="font-custom" />
              <p>
                Block or ban any user for violations of these terms,
                inappropriate behavior, or any other reason, without prior
                notification.
              </p>
            </div>
          </div>
        </div>
        <div className="w-70 mt-4 mb-0 service-detail-sec">
          <h1 className="font-custom primary1">
            5. Changes to Terms and Conditions
          </h1>
          <div className="my-2 desc-txt font-custom2">
            <p>
              PARANGIPETTAI.COM reserves the right to revise these terms and
              conditions at any time. It is the user's responsibility to
              regularly check this page for any updates. Your continued use of
              the website following the posting of any changes means you accept
              those changes.
            </p>
          </div>
        </div>
        <div className="w-70 mt-4 mb-0 service-detail-sec">
          <h1 className="font-custom primary1">
            6. Governing Law & Jurisdiction
          </h1>
          <div className="my-2 desc-txt font-custom2">
            <p>
              These terms and conditions are governed by and construed in
              accordance with the laws of Tamil Nadu, India, and you irrevocably
              submit to the exclusive jurisdiction of the courts in that
              location.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfUse;
