import React, { useEffect, useRef, useState } from "react";
import ImageIcon from "@mui/icons-material/AddAPhoto";
import DeleteIcon from "@mui/icons-material/Delete";
import { Col, Image } from "react-bootstrap";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import {
  useCategoryListQuery,
  usePostCreateMutation,
  useSubcategoriesMutation,
  useJobVacancyPostMutation,
  useExplorePostCreateMutation,
} from "../../redux/api/api";
import { useNavigate } from "react-router-dom";
import { ImageCompress } from "../../services/utilities/imageCompress";
import { handleImageUpload } from "../../services/utilities/browserImage";
import { fileUpload, lodergif } from "../../assets/img";
import useUser from "../../redux/store/userData";

const NewPostScreen = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [imageUrlList, setImageUrlList] = useState([]);
  const [approval, setApproval] = useState(false);
  const [approvalData, setApprovalData] = useState({});
  const [pointsLists, setPointsLists] = useState([{ points: " " }]);
  const [dayTime, setDayTime] = useState([{ Day: " ", Time: " " }]);
  const [galleryLists, setGalleryLists] = useState([
    { title: "", description: "", image: "" },
  ]);
  const [pointsList, setPointsList] = useState([]);
  const [dayTimeList, setDayTimeList] = useState([]);
  const [selectData, setSelectData] = useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [location, setLocation] = useState("");
  const [price, setPrice] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [points, setPoints] = useState("");
  const [speciality, setSpeciality] = useState("");
  const [experience, setExperience] = useState("");
  const [nameField, setNameField] = useState("");
  const [facility, setFacility] = useState("");
  const [education, setEducation] = useState("");
  const [addMenu, setAddMenu] = useState("");
  const [addMenuUrl, setAddMenuUrl] = useState("");
  const [dayList, setDayList] = useState("Monday");
  const [openTime, setOpenTime] = useState("");
  const [closeTime, setCloseTime] = useState("");
  const [companyImage, setCompanyImage] = useState("");
  const [companyImageUrl, setCompanyImageUrl] = useState("");
  const [jobPosition, setJobPosition] = useState("");
  const [salaryPackage, setSalaryPackage] = useState("");
  const [gender, setGender] = useState("");
  const [keySkills, setKeySkills] = useState("");
  const [applicantLoc, setApplicantLoc] = useState("");
  const [timeType, setTimeType] = useState("");
  const [seekersLoc, setSeekersLoc] = useState("");
  const [galleryImage, setGalleryImage] = useState("");
  const [galleryImageUrl, setGalleryImageUrl] = useState("");
  const [galleryInputValue, setGalleryInputValue] = useState("");
  const [galleryTextArea, setGalleryTextArea] = useState("");
  const [galleryDataList, setGalleryDataList] = useState([]);
  const [focusCheck, setFocusCheck] = useState(false);

  //Error Handle
  const [selectDataErr, setSelectDataErr] = useState(false);
  const [priceErr, setPriceErr] = useState(false);
  const [categoryErr, setCategoryErr] = useState(false);
  const [subCategoryErr, setSubCategoryErr] = useState(false);
  const [locationErr, setLocationErr] = useState(false);
  const [titleErr, setTitleErr] = useState(false);
  const [descriptionErr, setDescriptionErr] = useState(false);
  const [pointsListErr, setPointsListErr] = useState(false);
  const [imageListErr, setImageListErr] = useState(false);
  const [specialityErr, setSpecialityErr] = useState(false);
  const [experienceErr, setExperienceErr] = useState(false);
  const [nameFieldErr, setNameFieldErr] = useState(false);
  const [facilityErr, setFacilityErr] = useState(false);
  const [educationErr, setEducationErr] = useState(false);
  const [galleryDataListErr, setGalleryDataListErr] = useState(false);
  const [jobPositionErr, setJobPOsitionErr] = useState(false);
  const [timeTypeErr, setTimeTypeErr] = useState(false);
  const [genderErr, setGenderErr] = useState(false);
  const [salaryPackageErr, setSalaryPackageErr] = useState(false);
  const [keySkillsErr, setkeySkillsErr] = useState(false);
  const [applicantLocErr, setApplicantLocErr] = useState(false);
  const [companyImageErr, setCompanyImageErr] = useState(false);
  const [galleryImageErr, setGalleryImageErr] = useState(false);
  const [galleryInputValErr, setGalleryInputValErr] = useState(false);
  const [galleryTextAreaErr, setGalleryTextAreaErr] = useState(false);
  const [seekersLocErr, setSeekersLocErr] = useState(false);
  const [compressedImg, setComperssedImg] = useState("");

  const uploadImage = useRef(null);
  const companyImageRef = useRef(null);
  const galleryImageRef = useRef(null);

  //FocusRef
  const moduleRef = useRef(null);
  const categoryRef = useRef(null);
  const subCategoryRef = useRef(null);
  const locationRef = useRef(null);
  const titleRef = useRef(null);
  const descriptionRef = useRef(null);
  const pointsRef = useRef(null);
  const imageListRef = useRef(null);
  const specialityRef = useRef(null);
  const experienceRef = useRef(null);
  const galleryImageListRef = useRef(null);
  const jobPositionRef = useRef(null);
  const nameFieldRef = useRef(null);
  const timeTypeRef = useRef(null);
  const salaryPackageRef = useRef(null);
  const genderRef = useRef(null);
  const keySkillsRef = useRef(null);
  const applicantLocRef = useRef(null);
  const addMenuImgRef = useRef(null);

  const [categoryList, setCategoryList] = useState([]);
  const [subcategoriesList, setSubCategoriesList] = useState([]);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const imageUploader = React.useRef(null);
  const uploadedImage = React.useRef(null);

  // RTK QUERY
  const { data: categoryData, isSuccess: isCategoryDataSuccess } =
    useCategoryListQuery();
  const [subcategoriesData] = useSubcategoriesMutation();
  const [postCreate] = usePostCreateMutation();
  const [jobCreate] = useJobVacancyPostMutation();
  const [exploreCreate] = useExplorePostCreateMutation();

  useEffect(() => {
    if (isCategoryDataSuccess) {
      setCategoryList(categoryData);
      // console.log(categoryData);
    }
  }, [categoryData]);

  const getCategoryId = (id) => {
    if (selectData === "Classified" || selectData === "Services") {
      return id;
    } else if (selectData === "Jobs") {
      const tmp = categoryList?.jobs_list?.find((item) => item?.name === id);
      return tmp?.id;
    } else if (selectData === "Explore") {
      const tmp = categoryList?.explores_list?.find(
        (item) => item?.name === id
      );
      return tmp?.id;
    }
  };

  const getSubcategories = (val) => {
    const categoryId = getCategoryId(val);
    // console.log(categoryId);
    const formData = new FormData();
    formData.append("subcategory_id", categoryId);
    subcategoriesData(formData)
      .unwrap()
      .then((res) => {
        // console.log(res);
        if (res?.status === "success") {
          setSubCategoriesList(res?.sub_categories);
        }
      });
  };

  const addImageHandle = async (e) => {
    setImageList([...imageList, window.URL.createObjectURL(e.target.files[0])]);
    // const compressedImage = await ImageCompress(
    //   e.target.files[0],
    //   setComperssedImg
    // );
    // console.log("compressedImg0", compressedImage);

    handleImageUpload(e, setComperssedImg);
  };

  // console.log("compressedImg", compressedImg);

  const addImageHandles = async (e) => {
    setCompanyImage(window.URL.createObjectURL(e.target.files[0]));
    handleImageUpload(e, setComperssedImg);
  };

  useEffect(() => {
    if (compressedImg?.name) {
      setImageUrlList([...imageUrlList, compressedImg]);
    }
  }, [compressedImg]);

  useEffect(() => {
    if (compressedImg?.name) {
      setCompanyImageUrl(compressedImg);
    }
  }, [compressedImg]);

  // console.log("imageUrlList", companyImageUrl);

  const addGalleryImageHandle = () => {
    if (
      galleryImage === "" ||
      galleryInputValue === "" ||
      galleryTextArea === ""
    ) {
      setGalleryImageErr(true);
      setGalleryInputValErr(true);
      setGalleryTextAreaErr(true);
      setGalleryDataListErr(true);
    } else {
      const obj = {
        item: {
          image: galleryImage,
          image_url: galleryImageUrl,
          title: galleryInputValue,
          description: galleryTextArea,
        },
      };
      setGalleryDataList([...galleryDataList, obj]);
      setGalleryImage("");
      setGalleryImageUrl("");
      setGalleryInputValue("");
      setGalleryTextArea("");
    }
  };

  // console.log(galleryDataList);

  const submitHandler = () => {
    if (selectData === "") {
      setSelectDataErr(true);
      setFocusCheck(true);
    } else if (selectData === "Classified") {
      if (
        category === "" ||
        subCategory === "" ||
        location.length < 1 ||
        // price <= 0 ||
        title.length < 1 ||
        description.length < 1 ||
        pointsLists.length <= 0 ||
        imageList.length <= 0
      ) {
        setCategoryErr(true);
        setSubCategoryErr(true);
        setLocationErr(true);
        // setPriceErr(true);
        setTitleErr(true);
        setDescriptionErr(true);
        setPointsListErr(true);
        setImageListErr(true);
        setFocusCheck(true);
      } else {
        const selectedSubCat = subcategoriesList?.find(
          (item) => item?.name === subCategory
        );
        // console.log("imageUrlList", imageUrlList);
        const formData = new FormData();
        formData.append("subcategory_id", selectedSubCat?.id);
        formData.append("title", title);
        formData.append("description", description);
        pointsLists?.forEach((item, index) => {
          formData.append(`points[${index}]`, item?.points);
        });
        formData.append("location", location);
        if (price) {
          formData.append("rate", price);
        }
        imageUrlList?.forEach((item, index) => {
          formData.append(`images[${index}]`, item);
        });
        classifiedPostHandler(formData);
      }
    } else if (selectData === "Services") {
      if (
        category === "" ||
        subCategory === "" ||
        location.length < 1 ||
        speciality.length < 1 ||
        experience === "" ||
        // price <= 0 ||
        title.length < 1 ||
        description.length < 1
        // ||
        // pointsList.length <= 0 ||
        // galleryDataList.length <= 0
      ) {
        setCategoryErr(true);
        setSubCategoryErr(true);
        setLocationErr(true);
        setSpecialityErr(true);
        setExperienceErr(true);
        // setPriceErr(true);
        setTitleErr(true);
        setDescriptionErr(true);
        // setPointsListErr(true);
        // setGalleryDataListErr(true);
        setFocusCheck(true);
      } else {
        const selectedSubCat = subcategoriesList?.find(
          (item) => item?.name === subCategory
        );
        let check;
        galleryLists?.map((item, ind) => {
          check = Object.values(item).some((x) => x === " " || x === "");
        });
        // console.log(galleryDataList);
        const formData = new FormData();
        if (check) {
          alert("fill all gallery detaills");
        } else {
          formData.append("subcategory_id", selectedSubCat?.id);
          formData.append("title", title);
          formData.append("description", description);
          pointsLists?.forEach((item, index) => {
            formData.append(`points[${index}]`, item?.points);
          });
          formData.append("location", location);
          if (price) {
            formData.append("rate", price);
          }
          formData.append("speciality", speciality);
          formData.append("experience", experience);
          if (galleryLists?.length > 0) {
            galleryLists?.map((item, index) => {
              formData.append(`images[${index}]`, item?.image);
              formData.append(`img_title[${index}]`, item?.title);
              formData.append(`img_description[${index}]`, item?.description);
            });
          }
          servicePostHandler(formData);
        }
      }
    } else if (selectData === "Jobs") {
      if (category === "") {
        setCategoryErr(true);
        setFocusCheck(true);
      } else if (category?.toLowerCase().startsWith("vac")) {
        if (
          subCategory === "" ||
          title.length < 1 ||
          jobPosition.length < 1 ||
          location.length < 1 ||
          nameField.length < 1 ||
          timeType === "" ||
          experience === "" ||
          gender === "" ||
          salaryPackage <= 0 ||
          keySkills.length < 1 ||
          applicantLoc.length < 1 ||
          description.length < 1 ||
          // pointsList <= 0 ||
          companyImage === ""
        ) {
          setSubCategoryErr(true);
          setTitleErr(true);
          setJobPOsitionErr(true);
          setLocationErr(true);
          setNameFieldErr(true);
          setTimeTypeErr(true);
          setExperienceErr(true);
          setGenderErr(true);
          setSalaryPackageErr(true);
          setkeySkillsErr(true);
          setApplicantLocErr(true);
          setDescriptionErr(true);
          // setPointsListErr(true);
          setCompanyImageErr(true);
          setFocusCheck(true);
        } else {
          const selectedSubCat = subcategoriesList?.find(
            (item) => item?.name === subCategory
          );

          let formData = new FormData();
          formData.append("subcategory_id", selectedSubCat?.id);
          formData.append("job_title", title);
          formData.append("job_position", jobPosition);
          formData.append("company_name", nameField);
          formData.append("description", description);
          formData.append("full_time_part_time", timeType);
          formData.append("gender", gender);
          formData.append("salary", salaryPackage);
          formData.append("experience", experience);
          formData.append("job_location", location);
          formData.append("applicant_location", applicantLoc);
          formData.append("key_skills", keySkills);

          if (companyImageUrl) {
            formData.append("image", companyImageUrl);
          }

          pointsLists?.map((item, ind) => {
            formData.append(`points[${ind}]`, item?.points);
          });

          jobVacancyPostHandler(formData);
        }
      } else if (category?.toLowerCase().startsWith("appli")) {
        if (
          subCategory === "" ||
          title.length < 1 ||
          jobPosition.length < 1 ||
          // nameField.length < 1 ||
          timeType === "" ||
          experience === "" ||
          gender === "" ||
          salaryPackage <= 0 ||
          keySkills.length < 1 ||
          seekersLoc.length < 1 ||
          description.length < 1
        ) {
          setSubCategoryErr(true);
          setTitleErr(true);
          setJobPOsitionErr(true);
          // setNameFieldErr(true);
          setTimeTypeErr(true);
          setExperienceErr(true);
          setGenderErr(true);
          setSalaryPackageErr(true);
          setkeySkillsErr(true);
          setSeekersLocErr(true);
          setDescriptionErr(true);
          // setPointsListErr(true);
          setFocusCheck(true);
        } else {
          const selectedSubCat = subcategoriesList?.find(
            (item) => item?.name === subCategory
          );

          let formData = new FormData();
          formData.append("subcategory_id", selectedSubCat?.id);
          formData.append("job_title", title);
          formData.append("job_position", jobPosition);
          formData.append("company_name", nameField);
          formData.append("description", description);
          formData.append("full_time_part_time", timeType);
          formData.append("gender", gender);
          formData.append("salary", salaryPackage);
          formData.append("experience", experience);
          // formData.append("job_location", location);
          formData.append("applicant_location", seekersLoc);
          formData.append("key_skills", keySkills);

          pointsLists?.map((item, ind) => {
            formData.append(`points[${ind}]`, item?.points);
          });

          jobSeekersPostHandler(formData);
        }
      }
    } else if (selectData === "Explore") {
      const selectedSubCat = subcategoriesList?.find(
        (item) => item?.name === subCategory
      );
      const formdata = new FormData();
      if (category === "") {
        setCategoryErr(true);
        setSubCategoryErr(true);
        setLocationErr(true);
        setNameFieldErr(true);
        setTitleErr(true);
        setDescriptionErr(true);
        setPointsListErr(true);
      } else if (
        selectData === "Explore" &&
        // (category?.toLowerCase().startsWith("edu") ||
        //   category?.toLowerCase().startsWith("sho") ||
        //   category?.toLowerCase().startsWith("pro") ||
        //   category?.toLowerCase().startsWith("soc"))
        !category?.toLowerCase().startsWith("hea") &&
        !category?.toLowerCase().startsWith("fo")
      ) {
        if (
          subCategory === "" ||
          location.length < 1 ||
          nameField.length < 1 ||
          title.length < 1 ||
          description.length < 1 ||
          pointsLists?.length < 0
        ) {
          setSubCategoryErr(true);
          setLocationErr(true);
          setNameFieldErr(true);
          setTitleErr(true);
          setDescriptionErr(true);
          setPointsListErr(true);
        } else {
          let check;
          let check2;
          pointsLists?.map((item, ind) => {
            check = Object.values(item).every((x) => x === " " || x === "");
          });
          galleryLists?.map((item, ind) => {
            check2 = Object.values(item).some((x) => x === " " || x === "");
          });

          if (check) {
            alert("fill all points");
          } else if (check2) {
            alert("fill all gallery detaills");
          } else {
            formdata.append("name", nameField);
            formdata.append("title", title);
            formdata.append("description", description);
            formdata.append("location", location);
            formdata.append("subcategory_id", selectedSubCat?.id);

            if (companyImageUrl?.name) {
              alert("upload image");
            }
            {
              formdata.append("image", companyImageUrl);
            }

            if (price) {
              formdata.append("rate", price);
            }

            if (dayTime?.length > 0) {
              dayTime?.map((item, ind) => {
                formdata.append(`time_table[${ind}][day]`, item?.Day);
                formdata.append(`time_table[${ind}][time]`, item?.Time);
              });
            }

            pointsLists?.map((item, ind) => {
              formdata.append(`points[${ind}]`, item?.points);
            });

            galleryLists?.map((item, index) => {
              formdata.append(`images[${index}]`, item?.image);
              formdata.append(`img_title[${index}]`, item?.title);
              formdata.append(`img_description[${index}]`, item?.description);
            });

            explorePostHandler(formdata);
          }
        }
      } else if (
        selectData === "Explore" &&
        category?.toLowerCase().startsWith("fo")
      ) {
        if (
          subCategory === "" ||
          location.length < 1 ||
          nameField.length < 1 ||
          title.length < 1 ||
          description.length < 1 ||
          price.length <= 0
        ) {
          setSubCategoryErr(true);
          setLocationErr(true);
          setNameFieldErr(true);
          setTitleErr(true);
          setDescriptionErr(true);
          setPriceErr(true);
        } else {
          let check;
          let check2;
          let check3;
          pointsLists?.map((item, ind) => {
            check = Object.values(item).every((x) => x === " " || x === "");
          });
          galleryLists?.map((item, ind) => {
            check2 = Object.values(item).some((x) => x === " " || x === "");
          });
          dayTime?.map((item, ind) => {
            check3 = Object.values(item).some((x) => x === " " || x === "");
          });
          if (check) {
            alert("fill all points");
          } else if (check2) {
            alert("fill all gallery detaills");
          } else if (check3) {
            alert("fill all day and time detaills");
          } else if (!addMenuUrl?.name) {
            alert("upload menu");
          } else if (!companyImageUrl?.name) {
            alert("upload image");
          } else {
            formdata.append("name", nameField);
            formdata.append("title", title);
            formdata.append("description", description);
            formdata.append("location", location);
            formdata.append("subcategory_id", selectedSubCat?.id);
            formdata.append("image", companyImageUrl);
            formdata.append("menu_pdf", addMenuUrl);

            if (price) {
              formdata.append("rate", price);
            }

            if (galleryLists?.length > 0) {
              galleryLists?.map((item, index) => {
                formdata.append(`images[${index}]`, item?.image);
                formdata.append(`img_title[${index}]`, item?.title);
                formdata.append(`img_description[${index}]`, item?.description);
              });
            }

            if (dayTime?.length > 0) {
              dayTime?.map((item, ind) => {
                formdata.append(`time_table[${ind}][day]`, item?.Day);
                formdata.append(`time_table[${ind}][time]`, item?.Time);
              });
            }

            pointsLists?.map((item, ind) => {
              formdata.append(`points[${ind}]`, item?.points);
            });

            explorePostHandler(formdata);
          }
        }
      } else if (
        selectData === "Explore" &&
        category?.toLowerCase().startsWith("hea")
      ) {
        if (
          subCategory === "" ||
          location.length < 1 ||
          nameField.length < 1 ||
          facility.length < 1 ||
          education.length < 1 ||
          speciality.length < 1 ||
          title.length < 1 ||
          description.length < 1
          // ||
          // pointsList.length <= 0
        ) {
          setSubCategoryErr(true);
          setLocationErr(true);
          setNameFieldErr(true);
          setFacilityErr(true);
          setEducationErr(true);
          setSpecialityErr(true);
          setTitleErr(true);
          setDescriptionErr(true);
          // setPointsListErr(true);
        } else {
          let check;
          let check2;
          let check3;
          pointsLists?.map((item, ind) => {
            check = Object.values(item).every((x) => x === " " || x === "");
          });
          galleryLists?.map((item, ind) => {
            check2 = Object.values(item).some((x) => x === " " || x === "");
          });
          dayTime?.map((item, ind) => {
            check3 = Object.values(item).some((x) => x === " " || x === "");
          });
          if (check) {
            alert("fill all points");
          } else if (check2) {
            alert("fill all gallery detaills");
          } else if (check3) {
            alert("fill all day and time detaills");
          } else {
            formdata.append("name", nameField);
            formdata.append("title", title);
            formdata.append("description", description);
            formdata.append("location", location);
            formdata.append("subcategory_id", selectedSubCat?.id);

            if (!selectedSubCat?.name?.toLowerCase().startsWith("cl")) {
              if (companyImageUrl?.name) {
                alert("upload image");
              }
              {
                formdata.append("image", companyImageUrl);
              }
            }

            formdata.append(`other_fields[${0}][speciality]`, speciality);
            formdata.append(`other_fields[${0}][education]`, education);
            formdata.append(`other_fields[${0}][facility]`, facility);

            if (price) {
              formdata.append("rate", price);
            }

            if (galleryLists?.length > 0) {
              galleryLists?.map((item, index) => {
                formdata.append(`images[${index}]`, item?.image);
                formdata.append(`img_title[${index}]`, item?.title);
                formdata.append(`img_description[${index}]`, item?.description);
              });
            }

            if (dayTime?.length > 0) {
              dayTime?.map((item, ind) => {
                formdata.append(`time_table[${ind}][day]`, item?.Day);
                formdata.append(`time_table[${ind}][time]`, item?.Time);
              });
            }

            pointsLists?.map((item, ind) => {
              formdata.append(`points[${ind}]`, item?.points);
            });

            explorePostHandler(formdata);
          }
        }
      }
    }
  };

  // CLASSIFIED POST
  const classifiedPostHandler = (formData) => {
    setDisableSubmit(true);
    setLoader(true);
    postCreate(formData)
      .unwrap()
      .then((res) => {
        console.log(res);
        if (res?.status === "success") {
          // if (
          //   res?.message ==
          //   "Your Request Has Been submitted,Please wait for admin approval!"
          // ) {
          // }
          setLoader(false);
          setDisableSubmit(false);
          navigate("/postList/classifiedPost");
        }
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
        setDisableSubmit(false);
        if (err?.status === 400 && err?.data?.status === "success") {
          navigate("/postList/classifiedPost");
        }
      });
  };
  // SERVICE POST
  const servicePostHandler = (formData) => {
    setDisableSubmit(true);
    setLoader(true);
    postCreate(formData)
      .unwrap()
      .then((res) => {
        console.log(res);
        if (res?.status === "success") {
          setDisableSubmit(false);
          setLoader(false);
          if (res?.message?.toLowerCase().startsWith("your request")) {
            setApprovalData({ message: res?.message, type: 2 });
            setApproval(true);
          } else {
            navigate("/postList/servicesPost");
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
        setDisableSubmit(false);
        if (err?.status === 400 && err?.data?.status === "success") {
          navigate("/postList/servicesPost");
        }
      });
  };

  // JOB VACANCY POST
  const jobVacancyPostHandler = (formData) => {
    setDisableSubmit(true);
    setLoader(true);
    jobCreate(formData)
      .unwrap()
      .then((res) => {
        console.log(res);
        if (res?.status === "success") {
          setDisableSubmit(false);
          setLoader(false);
          navigate("/postList/jobPostList");
        }
      })
      .catch((err) => {
        console.log(err);
        setDisableSubmit(false);
        setLoader(false);
        if (err?.status === 400 && err?.data?.status === "success") {
          navigate("/postList/jobPostList");
        }
      });
  };

  // JOB SEEKERS POST
  const jobSeekersPostHandler = (formData) => {
    setDisableSubmit(true);
    setLoader(true);
    jobCreate(formData)
      .unwrap()
      .then((res) => {
        console.log(res);
        if (res?.status === "success") {
          setDisableSubmit(false);
          setLoader(false);
          navigate("/postList/jobPostList");
        }
      })
      .catch((err) => {
        console.log(err);
        setDisableSubmit(false);
        setLoader(false);
        if (err?.status === 400 && err?.data?.status === "success") {
          navigate("/postList/jobPostList");
        }
      });
  };

  // EXPL0RE  POST
  const explorePostHandler = (formData) => {
    setDisableSubmit(true);
    setLoader(true);
    exploreCreate(formData)
      .unwrap()
      .then((res) => {
        console.log(res);
        if (res?.status === "success") {
          setDisableSubmit(false);
          setLoader(false);
          if (res?.message?.toLowerCase().startsWith("your request")) {
            setApprovalData({ message: res?.message, type: 3 });
            setApproval(true);
          } else {
            navigate("/postList/explorePost");
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setDisableSubmit(false);
        setLoader(false);
        if (err?.status === 400 && err?.data?.status === "success") {
          navigate("/postList/explorePost");
        }
      });
  };

  const onClick = (type) => {
    if (type == 1) {
      navigate("");
    } else if (type == 2) {
      navigate("/postList/servicesPost");
    } else if (type == 3) {
      navigate("/postList/explorePost");
    } else if (type == 4) {
      navigate("");
    }
    setApproval(false);
  };

  const AddPointsHandler = () => {
    if (points !== "") {
      setPointsList([...pointsList, points]);
      setPoints("");
    } else {
      alert("Enter Points");
    }
  };

  const addPointChange = (value, index) => {
    let temp = [...pointsList];
    temp[index] = value;
    setPointsList(temp);
  };

  const addImageChange = (e, item, index) => {
    const obj = {
      item: {
        ...item,
        [e.target.name]: e.target.value,
      },
    };
    let temp = [...galleryDataList];
    temp[index] = obj;
    setGalleryDataList(temp);
  };

  const moduleHandler = (e) => {
    setSelectData(e.target.value);

    setCategoryErr(false);
    setSubCategoryErr(false);
    setLocationErr(false);
    setSpecialityErr(false);
    setExperienceErr(false);
    // setPriceErr(false);
    setTitleErr(false);
    setDescriptionErr(false);
    setPointsListErr(false);
    setImageListErr(false);
    setNameFieldErr(false);

    setCategory("");
    setSubCategory("");
    setLocation("");
    setSpeciality("");
    setExperience("");
    setPrice("");
    setTitle("");
    setDescription("");
    setPoints("");
    setPointsList([]);
    setImageList([]);
    setNameField("");
    setSubCategoriesList([]);
    setGalleryImage("");
    setGalleryImageUrl("");
    setGalleryInputValue("");
    setGalleryTextArea("");
    setGalleryDataList([]);
  };

  const categoryHandler = (e) => {
    setSubCategoriesList([]);
    setCategory(e.target.value);
    getSubcategories(e.target.value);
    if (
      category === "Education" ||
      category === "Shops" ||
      category === "Property" ||
      category === "Social"
    ) {
      setSubCategory("");
      setLocation("");
      setNameField("");
      setTitle("");
      setDescription("");
      setPointsList([]);

      setSubCategoryErr(false);
      setLocationErr(false);
      setNameFieldErr(false);
      setTitleErr(false);
      setDescriptionErr(false);
      setPointsListErr(false);
    } else if (category?.startsWith("Fo")) {
      setSubCategory("");
      setLocation("");
      setNameField("");
      setTitle("");
      setDescription("");
      setPointsList([]);

      setSubCategoryErr(false);
      setLocationErr(false);
      setNameFieldErr(false);
      setTitleErr(false);
      setDescriptionErr(false);
      setPointsListErr(false);
    } else if (category === "Health") {
      setSubCategory("");
      setLocation("");
      setNameField("");
      setFacility("");
      setEducation("");
      setSpeciality("");
      setTitle("");
      setDescription("");
      setPointsList([]);

      setSubCategoryErr(false);
      setLocationErr(false);
      setNameFieldErr(false);
      setFacilityErr(false);
      setEducationErr(false);
      setSpecialityErr(false);
      setTitleErr(false);
      setDescriptionErr(false);
      setPointsListErr(false);
    } else if (category === "Vacancy") {
      setSubCategory("");
      setTitle("");
      setJobPosition("");
      setLocation("");
      setNameField("");
      setTimeType("");
      setExperience("");
      setSalaryPackage("");
      setGender("");
      setKeySkills("");
      setApplicantLoc("");
      setDescription("");
      setPointsList([]);
      setCompanyImage("");

      setCategoryErr(false);
      setTitleErr(false);
      setJobPOsitionErr(false);
      setLocationErr(false);
      setNameFieldErr(false);
      setTimeTypeErr(false);
      setExperienceErr(false);
      setSalaryPackageErr(false);
      setGenderErr(false);
      setkeySkillsErr(false);
      setApplicantLocErr(false);
      setDescriptionErr(false);
      setPointsListErr(false);
      setCompanyImageErr(false);
    }
  };

  useEffect(() => {
    setImageList(imageList);
  }, [imageList]);

  useEffect(() => {
    if (selectDataErr === true) {
      if (selectData === "") {
        moduleRef.current.focus();
      }
    } else {
      if (selectData === "Classified") {
        if (categoryErr === true) {
          if (category === "") {
            categoryRef.current.focus();
          } else if (subCategoryErr === true) {
            if (subCategory === "") {
              subCategoryRef.current.focus();
            } else if (locationErr === true) {
              if (location.length < 1) {
                locationRef.current.focus();
              } else if (titleErr === true) {
                if (title.length < 1) {
                  titleRef.current.focus();
                } else if (descriptionErr === true) {
                  if (description.length < 1) {
                    descriptionRef.current.focus();
                  }
                  // else if (pointsListErr === true) {
                  //   if (pointsList.length <= 0) {
                  //     pointsRef.current.focus();
                  //   }
                  //  else if (imageListErr === true) {
                  //   if (imageList.length < 4) {
                  //     imageListRef.current.focus();
                  //   }
                  // }
                  // }
                }
              }
            }
          }
        }
      } else if (selectData === "Services") {
        if (categoryErr === true) {
          if (category === "") {
            categoryRef.current.focus();
          } else if (subCategoryErr === true) {
            if (subCategory === "") {
              subCategoryRef.current.focus();
            } else if (locationErr === true) {
              if (location.length < 1) {
                locationRef.current.focus();
              } else if (specialityErr === true) {
                if (speciality.length < 1) {
                  specialityRef.current.focus();
                } else if (experienceErr === true) {
                  if (experience === "") {
                    experienceRef.current.focus();
                  } else if (titleErr === true) {
                    if (title.length < 1) {
                      titleRef.current.focus();
                    } else if (descriptionErr === true) {
                      if (description.length < 1) {
                        descriptionRef.current.focus();
                      } else if (pointsListErr === true) {
                        if (pointsList.length <= 0) {
                          pointsRef.current.focus();
                        } else if (galleryDataListErr === true) {
                          if (galleryDataList.length <= 0) {
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      } else if (selectData === "Jobs") {
        if (category === "Vacancy") {
          if (categoryErr === true) {
            if (category === "") {
              categoryRef.current.focus();
            }
          } else if (subCategoryErr === true) {
            if (subCategory === "") {
              subCategoryRef.current.focus();
            } else if (titleErr === true) {
              if (title.length < 1) {
                titleRef.current.focus();
              } else if (jobPositionErr === true) {
                if (jobPosition.length < 1) {
                  jobPositionRef.current.focus();
                } else if (locationErr === true) {
                  if (location.length < 1) {
                    locationRef.current.focus();
                  } else if (nameFieldErr === true) {
                    if (nameField.length < 1) {
                      nameFieldRef.current.focus();
                    } else if (timeTypeErr === true) {
                      if (timeType === "") {
                        timeTypeRef.current.focus();
                      } else if (experienceErr === true) {
                        if (experience === "") {
                          experienceRef.current.focus();
                        } else if (salaryPackageErr === true) {
                          if (salaryPackage <= 0) {
                            salaryPackageRef.current.focus();
                          } else if (genderErr === true) {
                            if (gender === "") {
                              genderRef.current.focus();
                            } else if (keySkillsErr === true) {
                              if (keySkills.length < 1) {
                                keySkillsRef.current.focus();
                              } else if (applicantLocErr === true) {
                                if (applicantLoc.length < 1) {
                                  applicantLocRef.current.focus();
                                } else if (descriptionErr === true) {
                                  if (description.length < 1) {
                                    descriptionRef.current.focus();
                                  } else if (pointsListErr === true) {
                                    if (pointsList.length <= 0) {
                                      pointsRef.current.focus();
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    setFocusCheck(false);
  }, [focusCheck]);

  // HANDLE CHANGE POINT
  const addPointChanges = (e, index) => {
    let temp = [...pointsLists];
    const name = e.target.name;
    const value = e.target.value;
    temp[index][name] = value;
    setPointsLists(temp);
  };

  // HANDLE CHANGE DAY AND TIME
  const addDayTimeChanges = (e, index) => {
    let temp = [...dayTime];
    const name = e.target.name;
    const value = e.target.value;
    temp[index][name] = value;
    // console.log(temp);
    setDayTime(temp);
  };

  //  ADD POINTS
  const addPoints = () => {
    let temp = [...pointsLists];

    let check;

    pointsLists?.map((item, ind) => {
      check = Object.values(item).every((x) => x === " " || x === "");
    });
    if (check) {
      alert("fill all Points details");
    } else {
      temp.push({ points: " " });
    }

    setPointsLists(temp);
  };

  // ADD DAY AND TIME
  const addDayTime = () => {
    let temp = [...dayTime];

    let check;

    dayTime?.map((item, ind) => {
      check = Object.values(item).some((x) => x === " " || x === "");
    });
    if (check) {
      alert("fill all day and time details");
    } else {
      temp.push({ Day: " ", Time: " " });
    }

    setDayTime(temp);
  };

  //  ADD GALLERY LIST
  const addGllery = () => {
    let temp = [...galleryLists];

    let check;

    galleryLists?.map((item, ind) => {
      check = Object.values(item).some((x) => x === " " || x === "");
    });
    if (check) {
      alert("fill all details");
    } else {
      temp.push({ title: "", description: "", image: "" });
    }

    setGalleryLists(temp);
  };

  // REMOVE GALLERY
  const removeGalleryList = (event, ind) => {
    let temp = [...galleryLists];
    temp.splice(ind, 1);
    setGalleryLists(temp);
  };

  // HANDLE CHANGE GALLERY

  const addGalleryChange = async (e, ind, type) => {
    let temp = [...galleryLists];

    const name = e.target.name;
    let value;

    if (type == "text") {
      value = e.target.value;
    } else {
      value = await handleImageUpload(e);
    }
    // `[${}]`

    temp[ind][name] = value;
    setGalleryLists(temp);
  };
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  // const [sam, setSam] = useState()
  return (
    <div className="profile-right bg-white h-100">
      {approval && (
        <div className="loginpopup1">
          <div className="loginpopuplayout1 mo-di">
            <div className="customPopupDesign">
              <h6 className="text-center">{approvalData?.message}</h6>
              <div className="d-flex justify-content-center pt-2">
                <button
                  style={{
                    width: "55px",
                    alignItems: "center",
                    justifyContent: "center",
                    lineHeight: "20px",
                  }}
                  className="cust-btn bg-primar text-white rounded-3 p-2 d-grid m-3"
                  onClick={() => onClick(approvalData?.type)}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {loader && (
        <div className="loader-icon position-fixed bg-opacity-25 loderbg ac-jc">
          <div className="loader-icon1">{/* <img src={lodergif} /> */}</div>
        </div>
      )}
      <h5>New Post</h5>
      <div className="d-flex flex-wrap justify-content-between">
        <div className="form-groups position-relative">
          <h4>
            Module <span className="text-danger">*</span>
          </h4>
          <select value={selectData} onChange={moduleHandler} ref={moduleRef}>
            <option value="" hidden>
              Select Module
            </option>
            <option value="Classified">Classified</option>
            <option value="Services">Services</option>
            <option value="Jobs">Jobs</option>
            <option value="Explore">Explore</option>
          </select>
          {selectDataErr && selectData === "" && (
            <p className="error-state-profile">Select your Module</p>
          )}
        </div>
        {selectData !== "" && (
          <div className="form-groups position-relative">
            <h4>
              Category <span className="text-danger">*</span>
            </h4>
            {selectData === "Classified" && (
              <select
                value={category}
                onChange={categoryHandler}
                ref={categoryRef}
              >
                <option value="" hidden>
                  Select Category
                </option>
                {categoryList?.classified_list?.map((item) => {
                  return (
                    <option key={item?.id} value={item?.id}>
                      {item?.name}
                    </option>
                  );
                })}
              </select>
            )}
            {selectData === "Services" && (
              <select
                onChange={categoryHandler}
                ref={categoryRef}
                value={category}
              >
                <option value="" hidden>
                  Select Category
                </option>
                {categoryList?.service_list?.map((item) => {
                  return (
                    <option key={item?.id} value={item?.id}>
                      {item?.name}
                    </option>
                  );
                })}
              </select>
            )}
            {selectData === "Jobs" && (
              <select
                onChange={categoryHandler}
                name="Category"
                ref={categoryRef}
                value={category}
              >
                <option value="" hidden>
                  Select Category
                </option>
                {categoryList?.jobs_list?.map((item) => {
                  return (
                    <option key={item?.id} value={item?.name}>
                      {item?.name}
                    </option>
                  );
                })}
              </select>
            )}
            {selectData === "Explore" && (
              <select
                onChange={categoryHandler}
                name="Category"
                value={category}
              >
                <option value="" hidden>
                  Select Category
                </option>
                {categoryList?.explores_list?.map((item) => {
                  return (
                    <option key={item?.id} value={item?.name}>
                      {item?.name}
                    </option>
                  );
                })}
              </select>
            )}
            {categoryErr && category === "" && (
              <p className="error-state-profile">Select your Category</p>
            )}
          </div>
        )}
        {selectData === "Explore" && category?.toLowerCase().startsWith("hea")
          ? selectData !== "" && (
              <div className="form-groups position-relative">
                <h4>
                  Sub Category <span className="text-danger">*</span>
                </h4>
                <select
                  name="Sub Category"
                  value={subCategory}
                  ref={subCategoryRef}
                  onChange={(e) => setSubCategory(e.target.value)}
                >
                  <option value="" hidden>
                    Select Sub Category
                  </option>
                  {subcategoriesList?.map((item) => (
                    <option key={item?.id} value={item?.name}>
                      {item?.name}
                    </option>
                  ))}
                </select>
                {subCategoryErr && subCategory === "" && (
                  <p className="error-state-profile">
                    Select your Sub Category
                  </p>
                )}
              </div>
            )
          : selectData !== "" &&
            selectData !== "Jobs" && (
              <div className="form-groups position-relative">
                <h4>
                  Sub Category <span className="text-danger">*</span>
                </h4>
                <select
                  name="Sub Category"
                  value={subCategory}
                  ref={subCategoryRef}
                  onChange={(e) => setSubCategory(e.target.value)}
                >
                  <option value="" hidden>
                    Select Sub Category
                  </option>
                  {subcategoriesList?.map((item) => (
                    <option key={item?.id} value={item?.name}>
                      {item?.name}
                    </option>
                  ))}
                </select>
                {subCategoryErr && subCategory === "" && (
                  <p className="error-state-profile">
                    Select your Sub Category
                  </p>
                )}
              </div>
            )}
        {selectData !== "Explore" &&
        category !== "Health" &&
        selectData === "Jobs" &&
        category !== "" ? (
          <div className="form-groups position-relative">
            <h4>
              Sub Category <span className="text-danger">*</span>
            </h4>
            <select
              name="Sub Category"
              value={subCategory}
              ref={subCategoryRef}
              onChange={(e) => setSubCategory(e.target.value)}
            >
              <option value="" hidden>
                Select Sub Category
              </option>
              {subcategoriesList?.map((item) => (
                <option key={item?.id} value={item?.name}>
                  {item?.name}
                </option>
              ))}
            </select>
            {subCategoryErr && subCategory === "" && (
              <p className="error-state-profile">Select your Sub Category</p>
            )}
          </div>
        ) : null}

        {selectData === "Jobs"
          ? null
          : selectData !== "" && (
              <div className="form-groups position-relative">
                <h4>
                  Location <span className="text-danger">*</span>
                </h4>
                <input
                  type="text"
                  value={location}
                  ref={locationRef}
                  onChange={(e) => setLocation(e.target.value)}
                />
                {locationErr && location.length < 1 && (
                  <p className="error-state-profile">Enter your Location</p>
                )}
              </div>
            )}

        {selectData === "Services" ? (
          <div className="form-groups position-relative">
            <h4>
              Speciality <span className="text-danger">*</span>
            </h4>
            <input
              type="text"
              value={speciality}
              ref={specialityRef}
              onChange={(e) => setSpeciality(e.target.value)}
            />
            {specialityErr && speciality.length < 1 && (
              <p className="error-state-profile">Enter your Speciality</p>
            )}
          </div>
        ) : null}
        {selectData === "Services" ? (
          <div className="form-groups position-relative">
            <h4>
              Experience <span className="text-danger">*</span>
            </h4>
            <input
              type="number"
              value={experience}
              ref={experienceRef}
              onChange={(e) => setExperience(e.target.value)}
            />
            {experienceErr && experience === "" && (
              <p className="error-state-profile">Enter your Experience</p>
            )}
          </div>
        ) : null}
        {(selectData === "Classified" ||
          selectData === "" ||
          selectData === "Services" ||
          selectData === "Explore") &&
          // (category?.toLowerCase().startsWith("edu") ||
          //   category?.toLowerCase().startsWith("fo") ||
          //   category?.toLowerCase().startsWith("hea") ||
          //   category?.toLowerCase().startsWith("sho") ||
          //   category?.toLowerCase().startsWith("pro") ||
          //   category?.toLowerCase().startsWith("soc"))
          selectData !== "" && (
            <div className="form-groups position-relative">
              <h4>Price</h4>
              <input
                type="number"
                onInput={(e) => {
                  if (e.target.value.length > e.target.maxLength)
                    e.target.value = e.target.value.slice(
                      0,
                      e.target.maxLength
                    );
                  if (e.target.value.length !== 7) {
                    e.target.setCustomValidity("invalid Number");
                  } else if (e.target.value.length == 7) {
                    e.target.setCustomValidity("");

                    setPrice(e.target.value);
                  }
                }}
                onFocus={(e) =>
                  e.target.addEventListener(
                    "wheel",
                    function (e) {
                      e.preventDefault();
                    },
                    { passive: false }
                  )
                }
                maxlength={7}
                onChange={(e) => setPrice(e.target.value)}
                value={price}
              />
              {priceErr && price <= 0 && (
                <p className="error-state-profile">Enter your Price</p>
              )}
            </div>
          )}
        {selectData === "Explore" && (
          <div className="form-groups position-relative">
            <h4>
              Name <span className="text-danger">*</span>
            </h4>
            <input
              type="text"
              value={nameField}
              onChange={(e) => setNameField(e.target.value)}
            />
            {nameFieldErr && nameField.length < 1 && (
              <p className="error-state-profile">Enter your Name</p>
            )}
          </div>
        )}
        {selectData === "Jobs" &&
          category?.toLowerCase().startsWith("appli") && (
            <div className="form-groups position-relative">
              <h4>
                Profile Name <span className="text-danger">*</span>
              </h4>
              <input
                type="text"
                value={user?.name}
                onChange={(e) => setNameField(e.target.value)}
                disabled
              />
              {nameFieldErr && nameField.length < 1 && (
                <p className="error-state-profile">Enter your Profile Name</p>
              )}
            </div>
          )}
        {selectData === "Explore" &&
          category?.toLowerCase().startsWith("hea") && (
            <div className="form-groups position-relative">
              <h4>
                Facility <span className="text-danger">*</span>
              </h4>
              <input
                type="text"
                value={facility}
                onChange={(e) => setFacility(e.target.value)}
              />
              {facilityErr && facility.length < 1 && (
                <p className="error-state-profile">Enter your Facility</p>
              )}
            </div>
          )}
        {selectData === "Explore" &&
          category?.toLowerCase().startsWith("hea") && (
            <div className="form-groups position-relative">
              <h4>
                Education <span className="text-danger">*</span>
              </h4>
              <input
                type="text"
                value={education}
                onChange={(e) => setEducation(e.target.value)}
              />
              {educationErr && education.length < 1 && (
                <p className="error-state-profile">Enter your Education</p>
              )}
            </div>
          )}
        {selectData === "Explore" &&
          category?.toLowerCase().startsWith("hea") && (
            <div className="form-groups position-relative">
              <h4>
                Speciality <span className="text-danger">*</span>
              </h4>
              <input
                type="text"
                value={speciality}
                ref={specialityRef}
                onChange={(e) => setSpeciality(e.target.value)}
              />
              {specialityErr && speciality.length < 1 && (
                <p className="error-state-profile">Enter your Speciality</p>
              )}
            </div>
          )}
        {selectData === "Jobs" &&
        (category?.toLowerCase().startsWith("vac") ||
          category?.toLowerCase().startsWith("appli")) ? (
          <div className="form-groups position-relative">
            <h4>
              Job Title <span className="text-danger">*</span>
            </h4>
            <input
              type="text"
              value={title}
              ref={titleRef}
              onChange={(e) => setTitle(e.target.value)}
            />
            {titleErr && title.length < 1 && (
              <p className="error-state-profile">Enter your Title</p>
            )}
          </div>
        ) : (
          selectData !== "" &&
          selectData !== "Jobs" && (
            <div className="form-groups position-relative">
              <h4>
                Title <span className="text-danger">*</span>
              </h4>
              <input
                type="text"
                value={title}
                ref={titleRef}
                onInput={(e) => {
                  if (e.target.value.length > e.target.maxLength)
                    e.target.value = e.target.value.slice(
                      0,
                      e.target.maxLength
                    );
                  if (e.target.value.length !== 150) {
                    e.target.setCustomValidity("invalid Number");
                  } else if (e.target.value.length == 150) {
                    e.target.setCustomValidity("");

                    setTitle(e.target.value);
                  }
                }}
                onFocus={(e) =>
                  e.target.addEventListener(
                    "wheel",
                    function (e) {
                      e.preventDefault();
                    },
                    { passive: false }
                  )
                }
                maxlength={150}
                onChange={(e) => setTitle(e.target.value)}
              />
              {titleErr && title.length < 1 && (
                <p className="error-state-profile">Enter your Title</p>
              )}
            </div>
          )
        )}
        {selectData === "Jobs" &&
          (category?.toLowerCase().startsWith("vac") ||
            category?.toLowerCase().startsWith("appli")) && (
            <div className="form-groups position-relative">
              <h4>
                Job Position <span className="text-danger">*</span>
              </h4>
              <input
                type="text"
                value={jobPosition}
                ref={jobPositionRef}
                onChange={(e) => setJobPosition(e.target.value)}
              />
              {jobPositionErr && jobPosition.length < 1 && (
                <p className="error-state-profile">Enter your Position</p>
              )}
            </div>
          )}
        {selectData === "Jobs" && category?.toLowerCase().startsWith("vac") && (
          <div className="form-groups position-relative">
            <h4>
              Job Location <span className="text-danger">*</span>
            </h4>
            <input
              type="text"
              value={location}
              ref={locationRef}
              onChange={(e) => setLocation(e.target.value)}
            />
            {locationErr && location.length < 1 && (
              <p className="error-state-profile">Enter your Location</p>
            )}
          </div>
        )}
        {selectData === "Jobs" && category?.toLowerCase().startsWith("vac") && (
          <div className="form-groups position-relative">
            <h4>
              Company Name <span className="text-danger">*</span>
            </h4>
            <input
              type="text"
              value={nameField}
              ref={nameFieldRef}
              onChange={(e) => setNameField(e.target.value)}
            />
            {nameFieldErr && nameField.length < 1 && (
              <p className="error-state-profile">Enter your Company Name</p>
            )}
          </div>
        )}
        {selectData === "Jobs" &&
          (category?.toLowerCase().startsWith("vac") ||
            category?.toLowerCase().startsWith("appli")) && (
            <div className="form-groups position-relative">
              <h4>
                Time Type <span className="text-danger">*</span>
              </h4>
              <select
                onChange={(e) => setTimeType(e.target.value)}
                value={timeType}
                ref={timeTypeRef}
              >
                <option value="" hidden>
                  Time Type
                </option>
                <option value="Full Time">Full Time</option>
                <option value="Part Time">Part Time</option>
              </select>
              {timeTypeErr && timeType === "" && (
                <p className="error-state-profile">Select your Time Type</p>
              )}
            </div>
          )}
        {selectData === "Jobs" &&
          (category?.toLowerCase().startsWith("vac") ||
            category?.toLowerCase().startsWith("appli")) && (
            <div className="form-groups position-relative">
              <h4>
                Experience <span className="text-danger">*</span>
              </h4>
              <input
                type="number"
                value={experience}
                ref={experienceRef}
                onChange={(e) => setExperience(e.target.value)}
              />
              {experienceErr && experience === "" && (
                <p className="error-state-profile">Enter your Experience</p>
              )}
            </div>
          )}
        {selectData === "Jobs" &&
          category?.toLowerCase().startsWith("appli") && (
            <div className="form-groups position-relative">
              <h4>
                Applicant Location <span className="text-danger">*</span>
              </h4>
              <input
                type="text"
                value={seekersLoc}
                onChange={(e) => setSeekersLoc(e.target.value)}
              />
              {seekersLocErr && seekersLoc?.length < 1 && (
                <p className="error-state-profile">Enter Applicant Location</p>
              )}
            </div>
          )}
        {selectData === "Jobs" &&
          (category?.toLowerCase().startsWith("vac") ||
            category?.toLowerCase().startsWith("appli")) && (
            <div className="form-groups position-relative">
              <h4>
                Salary Package <span className="text-danger">*</span>
              </h4>
              <input
                type="number"
                value={salaryPackage}
                ref={salaryPackageRef}
                onChange={(e) => setSalaryPackage(e.target.value)}
              />
              {salaryPackageErr && salaryPackage <= 0 && (
                <p className="error-state-profile">Enter your Salary Package</p>
              )}
            </div>
          )}
        {selectData === "Jobs" &&
          (category?.toLowerCase().startsWith("vac") ||
            category?.toLowerCase().startsWith("appli")) && (
            <div className="form-groups position-relative">
              <h4>
                Gender <span className="text-danger">*</span>
              </h4>
              <select
                onChange={(e) => setGender(e.target.value)}
                ref={genderRef}
                value={gender}
              >
                <option value="" hidden>
                  Gender Type
                </option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
              {genderErr && gender === "" && (
                <p className="error-state-profile">Select your Gender</p>
              )}
            </div>
          )}
        {selectData === "Jobs" &&
          (category?.toLowerCase().startsWith("vac") ||
            category?.toLowerCase().startsWith("appli")) && (
            <div className="form-groups position-relative">
              <h4>
                Key Skills <span className="text-danger">*</span>
              </h4>
              <input
                type="text"
                ref={keySkillsRef}
                value={keySkills}
                onChange={(e) => setKeySkills(e.target.value)}
              />
              {keySkillsErr && keySkills.length < 1 && (
                <p className="error-state-profile">Enter your Key Skills</p>
              )}
            </div>
          )}
        {selectData === "Jobs" && category?.toLowerCase().startsWith("vac") && (
          <div className="form-groups position-relative">
            <h4>
              Applicant Location <span className="text-danger">*</span>
            </h4>
            <input
              type="text"
              value={applicantLoc}
              ref={applicantLocRef}
              onChange={(e) => setApplicantLoc(e.target.value)}
            />
            {applicantLocErr && applicantLoc.length < 1 && (
              <p className="error-state-profile">Enter Applicant Location</p>
            )}
          </div>
        )}

        {selectData === "Explore" && (
          // (category?.toLowerCase().startsWith("hea") ||
          //   category?.toLowerCase().startsWith("fo") ||
          //   category?.toLowerCase().startsWith("ed") ||
          //   category?.toLowerCase().startsWith("sho") ||
          //   category?.toLowerCase().startsWith("prop") ||
          //   category?.toLowerCase().startsWith("soc"))
          //   &&
          <div className="w-50 custom-btn-add">
            <div className="custom-input-flex">
              {dayTime?.map((item, ind) => {
                return (
                  <div
                    className="d-flex flex-colunm gap-2 form-groups position-relative w-100 w-custom"
                    key={ind}
                  >
                    <div className="w-100">
                      <h4>
                        Day{" "}
                        {category?.toLowerCase().startsWith("fo") && (
                          <span className="text-danger">*</span>
                        )}
                      </h4>
                      <input
                        className="w-100"
                        placeholder="Mon-Sat"
                        name={"Day"}
                        value={item?.Day}
                        onChange={(e) => addDayTimeChanges(e, ind)}
                      />
                    </div>
                    <div className="w-100">
                      <h4>
                        Time{" "}
                        {category?.toLowerCase().startsWith("fo") && (
                          <span className="text-danger">*</span>
                        )}
                      </h4>
                      <input
                        className="w-100"
                        placeholder="9:00AM-6:00PM/"
                        name="Time"
                        value={item?.Time}
                        onChange={(e) => addDayTimeChanges(e, ind)}
                      />
                    </div>
                    {dayTime?.length > 1 && (
                      <DeleteIcon
                        className="closeIcon-edit"
                        onClick={() => {
                          const secondList = [...dayTime];
                          secondList.splice(ind, 1);
                          setDayTime(secondList);
                        }}
                      />
                    )}
                  </div>
                );
              })}
              <div className="sort-by w-100 d-flex justify-content-end">
                {dayTime?.length > 0 && (
                  <button className="add-btn cust-btn" onClick={addDayTime}>
                    Add
                  </button>
                )}
              </div>
            </div>
          </div>
        )}

        {selectData !== "" && (
          <div className="form-groups">
            <div className="position-relative">
              <h4>
                Description <span className="text-danger">*</span>
              </h4>
              <textarea
                className="w-100 mt-1 rounded-1 border"
                value={description}
                ref={descriptionRef}
                onInput={(e) => {
                  if (e.target.value.length > e.target.maxLength)
                    e.target.value = e.target.value.slice(
                      0,
                      e.target.maxLength
                    );
                  if (e.target.value.length !== 500) {
                    e.target.setCustomValidity("invalid Number");
                  } else if (e.target.value.length == 500) {
                    e.target.setCustomValidity("");

                    setDescription(e.target.value);
                  }
                }}
                onFocus={(e) =>
                  e.target.addEventListener(
                    "wheel",
                    function (e) {
                      e.preventDefault();
                    },
                    { passive: false }
                  )
                }
                maxlength={500}
                onChange={(e) => setDescription(e.target.value)}
              />
              {descriptionErr && description.length < 1 && (
                <p className="error-state-profile">Enter your Description</p>
              )}
            </div>
          </div>
        )}
        {selectData !== "" && (
          <div className="form-groups position-relative">
            <h4>
              Points <span className="text-danger">*</span>
            </h4>
            <div>
              {/* {pointsList?.map((data, index) => (
                <div className="position-relative">
                  <input
                    type="text"
                    className="w-70"
                    value={data}
                    onChange={(e) => addPointChange(e.target.value, index)}
                  />
                  <DeleteIcon
                    className="closeIcon"
                    onClick={() => {
                      const secondList = [...pointsList];
                      secondList.splice(index, 1);
                      setPointsList(secondList);
                    }}
                  />
                </div>
              ))} */}
              {pointsLists?.map((item, ind) => {
                return (
                  <div className="position-relative" key={ind}>
                    <input
                      type="text"
                      name="points"
                      className="w-70"
                      value={item?.points}
                      onInput={(e) => {
                        if (e.target.value.length > e.target.maxLength)
                          e.target.value = e.target.value.slice(
                            0,
                            e.target.maxLength
                          );
                        if (e.target.value.length !== 250) {
                          e.target.setCustomValidity("invalid Number");
                        } else if (e.target.value.length == 250) {
                          e.target.setCustomValidity("");

                          addPointChanges(e, ind);
                        }
                      }}
                      onFocus={(e) =>
                        e.target.addEventListener(
                          "wheel",
                          function (e) {
                            e.preventDefault();
                          },
                          { passive: false }
                        )
                      }
                      maxlength={250}
                      onChange={(e) => addPointChanges(e, ind)}
                    />
                    {pointsLists?.length > 1 && (
                      <DeleteIcon
                        className="closeIcon"
                        onClick={() => {
                          const secondList = [...pointsLists];
                          secondList.splice(ind, 1);
                          setPointsLists(secondList);
                        }}
                      />
                    )}
                  </div>
                );
              })}
              <div className="sort-by d-flex justify-content-end w-100">
                {/* <input
                  type="text"
                  className="w-70"
                  value={points}
                  ref={pointsRef}
                  onChange={(e) => setPoints(e.target.value)}
                />
                <button className="add-btn cust-btn" onClick={AddPointsHandler}>
                  Add
                </button> */}
                {pointsLists?.length > 0 && (
                  <button className="add-btn cust-btn mb-4" onClick={addPoints}>
                    Add
                  </button>
                )}
              </div>
            </div>
            {pointsListErr && (
              <p className="error-state-profile pt-3">Enter your points</p>
            )}
          </div>
        )}

        {selectData === "Classified" ? (
          <div className="form-groups position-relative">
            <h4>
              Add Images <span className="text-danger">*</span>
            </h4>
            <div className="d-flex mt-2" ref={imageListRef}>
              {imageList.map((image, index) => (
                <div className="newPostView">
                  <img
                    src={image}
                    className="newPostImage rounded-3 border-1 m-lg-1 ms-1"
                  />
                  <DeleteIcon
                    className="closeIcon"
                    onClick={() => {
                      const secondList = [...imageList];
                      const firstList = [...imageUrlList];
                      secondList.splice(index, 1);
                      firstList.splice(index, 1);
                      setImageList(secondList);
                      setImageUrlList(firstList);
                    }}
                  />
                </div>
              ))}
              <div
                className="upload-img d-flex ac-jc bg-gray3 p-4 rounded-3 m-lg-1 ms-1"
                ref={imageListRef}
                onClick={() => uploadImage.current.click()}
              >
                <ImageIcon className="fs-2" />
                <input
                  type="file"
                  ref={uploadImage}
                  onChange={addImageHandle}
                  className="d-none"
                  accept=".jpg,.jpeg,.png,.PNG,.JPG,.JEPG"
                />
              </div>
            </div>
            {imageListErr && imageList.length <= 0 && (
              <p className="error-state-profile">Select your Images</p>
            )}
          </div>
        ) : null}
        {selectData === "Explore" &&
          category?.toLowerCase().startsWith("fo") && (
            <div className="form-groups">
              <h4>
                Add Menu <span className="text-danger">*</span>
              </h4>
              {!addMenu && (
                <img
                  src={fileUpload}
                  className="addMenuImg mt-1"
                  onClick={() => addMenuImgRef.current.click()}
                />
              )}
              {addMenu && (
                <div className="position-relative mt-1 rounded-1">
                  <iframe src={addMenu} className="iFrameDisplay" />
                  <DeleteIcon
                    className="closeIcon-company1"
                    onClick={() => {
                      setAddMenu("");
                      setAddMenuUrl("");
                    }}
                  />
                </div>
              )}

              <input
                type="file"
                ref={addMenuImgRef}
                onChange={(e) => {
                  setAddMenu(window.URL.createObjectURL(e.target.files[0]));
                  setAddMenuUrl(e.target.files[0]);
                }}
                // className={`${addMenu && "d-none"}`}
                className="d-none"
                accept=".pdf"
              />
              {/* <div
              className="upload-img d-flex ac-jc bg-gray3 p-4 rounded-3 m-lg-1 ms-1"
              onClick={() => uploadImage.current.click()}>
              <NoteAddIcon className="fs-2" />
              <input
                type="file"
                ref={uploadImage}
                onChange={addImageHandle}
                className="d-none"
              />
            </div> */}
            </div>
          )}
        {selectData === "Jobs" && category?.toLowerCase().startsWith("vac") && (
          <div className="form-groups position-relative">
            <h4>
              Company Image <span className="text-danger">*</span>
            </h4>
            <div className="d-flex mt-2">
              {companyImage !== "" && (
                <div className="position-relative">
                  <img
                    src={companyImage}
                    className="newPostImage rounded-3 border-1 m-lg-1 ms-1"
                  />
                  <DeleteIcon
                    className="closeIcon-company"
                    onClick={() => {
                      setCompanyImage("");
                      setCompanyImageUrl("");
                    }}
                  />
                </div>
              )}
              {companyImage === "" && (
                <div
                  className="upload-img d-flex ac-jc bg-gray3 p-4 rounded-3 m-lg-1 ms-1"
                  onClick={() => companyImageRef.current.click()}
                >
                  <ImageIcon className="fs-2" />
                  <input
                    type="file"
                    ref={companyImageRef}
                    onChange={(e) => {
                      setCompanyImage(
                        window.URL.createObjectURL(e.target.files[0])
                      );
                      setCompanyImageUrl(e.target.files[0]);
                    }}
                    accept=".jpg,.jpeg,.png,.PNG,.JPG,.JEPG"
                    className="d-none"
                  />
                </div>
              )}
            </div>
            {companyImageErr && companyImage === "" && (
              <p className="error-state-profile">Select Company Image</p>
            )}
          </div>
        )}
        {selectData === "Explore" &&
          // (category?.toLowerCase().startsWith("edu") ||
          //   category?.toLowerCase().startsWith("fo") ||
          //   category?.toLowerCase().startsWith("sho") ||
          //   category?.toLowerCase().startsWith("pro") ||
          //   category?.toLowerCase().startsWith("soc"))
          !category?.toLowerCase().startsWith("hea") && (
            <div className="form-groups w-100">
              <h4>
                Add Image <span className="text-danger">*</span>
              </h4>
              <div className="d-flex mt-2">
                {companyImage !== "" && (
                  <div className="position-relative">
                    <img
                      src={companyImage}
                      className="newPostImage rounded-3 border-1 m-lg-1 ms-1"
                    />
                    <DeleteIcon
                      className="closeIcon-company"
                      onClick={() => {
                        setCompanyImage("");
                        setCompanyImageUrl("");
                      }}
                    />
                  </div>
                )}
                {companyImage === "" && (
                  <div
                    className="upload-img d-flex ac-jc bg-gray3 p-4 rounded-3 m-lg-1 ms-1"
                    onClick={() => companyImageRef.current.click()}
                  >
                    <ImageIcon className="fs-2" />
                    <input
                      type="file"
                      ref={companyImageRef}
                      accept=".jpg,.jpeg,.png,.PNG,.JPG,.JEPG"
                      onChange={(e) => {
                        addImageHandles(e);
                      }}
                      className="d-none"
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        {selectData === "Explore" &&
          category?.toLowerCase().startsWith("hea") &&
          // (subCategory?.toLowerCase().startsWith("medi") ||
          //   subCategory?.toLowerCase().startsWith("la"))
          !subCategory?.toLowerCase().startsWith("cli") && (
            <div className="form-groups w-100">
              <h4>
                Add Image <span className="text-danger">*</span>
              </h4>
              <div className="d-flex mt-2">
                {companyImage !== "" && (
                  <div className="position-relative">
                    <img
                      src={companyImage}
                      className="newPostImage rounded-3 border-1 m-lg-1 ms-1"
                    />
                    <DeleteIcon
                      className="closeIcon-company"
                      onClick={() => {
                        setCompanyImage("");
                        setCompanyImageUrl("");
                      }}
                    />
                  </div>
                )}
                {companyImage === "" && (
                  <div
                    className="upload-img d-flex ac-jc bg-gray3 p-4 rounded-3 m-lg-1 ms-1"
                    onClick={() => companyImageRef.current.click()}
                  >
                    <ImageIcon className="fs-2" />
                    <input
                      type="file"
                      ref={companyImageRef}
                      onChange={(e) => {
                        addImageHandles(e);
                      }}
                      className="d-none"
                      accept=".jpg,.jpeg,.png,.PNG,.JPG,.JEPG"
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        {selectData === "Explore" || selectData === "Services" ? (
          <div
            className="form-groups position-relative"
            ref={galleryImageListRef}
          >
            <h4>
              Gallery Images <span className="text-danger">*</span>
            </h4>
            {/* {galleryDataList?.map(({ item }, index) => (
              <div className="d-flex mt-2 position-relative">
                <div className="upload-img d-flex ac-jc bg-gray3 p-4 rounded-3 m-lg-1 ms-1">
                  <div className="position-relative">
                    <img
                      src={item.image}
                      className="newPostImage rounded-3 border-1 m-lg-1 ms-1"
                    />
                  </div>
                </div>
                <Col className="">
                  <input
                    type="text"
                    value={item.title}
                    name="title"
                    onChange={(e) => addImageChange(e, item, index)}
                  />
                  <textarea
                    type="text"
                    placeholder="Description"
                    className="textAreaServices-galleryadd"
                    value={item.description}
                    name="description"
                    onChange={(e) => addImageChange(e, item, index)}
                  />
                </Col>
                <DeleteIcon
                  className="closeIcon"
                  onClick={() => {
                    const secondList = [...galleryDataList];
                    secondList.splice(index, 1);
                    setGalleryDataList(secondList);
                  }}
                />
              </div>
            ))}
            <div className="d-flex mt-2">
              <div className="upload-img d-flex ac-jc bg-gray3 p-4 rounded-3 m-lg-1 ms-1">
                {galleryImage !== "" && (
                  <div className="position-relative">
                    <img
                      src={galleryImage}
                      className="newPostImage rounded-3 border-1 m-lg-1 ms-1"
                    />
                  </div>
                )}
                {galleryImage === "" && (
                  <div>
                    <ImageIcon
                      className="fs-35"
                      onClick={() => galleryImageRef.current.click()}
                    />
                    <input
                      type="file"
                      ref={galleryImageRef}
                      onChange={(e) => {
                        setGalleryImage(
                          window.URL.createObjectURL(e.target.files[0])
                        );
                        setGalleryImageUrl(e.target.files[0]);
                      }}
                      accept=".jpg,.jpeg,.png,.PNG,.JPG,.JEPG"
                      className="d-none"
                    />
                  </div>
                )}
              </div>
              <Col className="">
                <input
                  type="text"
                  placeholder="Title"
                  value={galleryInputValue}
                  onChange={(e) => setGalleryInputValue(e.target.value)}
                />
                <div className="d-flex w-100">
                  <textarea
                    type="text"
                    placeholder="Description"
                    className="textAreaServices"
                    value={galleryTextArea}
                    onChange={(e) => setGalleryTextArea(e.target.value)}
                  />
                  <button
                    className="add-btn cust-btn"
                    onClick={addGalleryImageHandle}
                  >
                    Add
                  </button>
                </div>
              </Col>
            </div> */}
            {galleryLists?.map((item, index) => {
              // console.log(item);
              let urlImage;
              if (item?.image?.name) {
                urlImage = window.URL.createObjectURL(item?.image);
              } else {
                urlImage = item?.image;
              }
              return (
                <div className="d-flex mt-2 position-relative" key={index}>
                  <div className=" d-flex ac-jc">
                    <div className="position-relative">
                      <div className="mcp_image">
                        <div className="prof-imag">
                          <button
                            className="upload-img bg-gray3  rounded-3  cust-btn m-lg-1 ms-1"
                            onClick={() => imageUploader.current.click()}
                          >
                            {item?.image ? (
                              <Image
                                ref={uploadedImage}
                                src={urlImage}
                                className="prf-img  "
                              />
                            ) : (
                              <ImageIcon className="fs-35" />
                            )}
                          </button>
                          <input
                            type="file"
                            accept=".jpg,.jpeg,.png,.PNG,.JPG,.JEPG"
                            name="image"
                            onChange={(e) =>
                              addGalleryChange(e, index, "image")
                            }
                            ref={imageUploader}
                            className="d-none"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <Col className="">
                    <input
                      type="text"
                      value={item?.title}
                      placeholder="Title"
                      name="title"
                      onInput={(e) => {
                        if (e.target.value.length > e.target.maxLength)
                          e.target.value = e.target.value.slice(
                            0,
                            e.target.maxLength
                          );
                        if (e.target.value.length !== 150) {
                          e.target.setCustomValidity("invalid Number");
                        } else if (e.target.value.length == 150) {
                          e.target.setCustomValidity("");

                          addGalleryChange(e, index, "text");
                        }
                      }}
                      onFocus={(e) =>
                        e.target.addEventListener(
                          "wheel",
                          function (e) {
                            e.preventDefault();
                          },
                          { passive: false }
                        )
                      }
                      maxlength={150}
                      onChange={(e) => addGalleryChange(e, index, "text")}
                    />
                    <textarea
                      type="text"
                      placeholder="Description"
                      className="textAreaServices-galleryadd"
                      value={item?.description}
                      name="description"
                      onChange={(e) => addGalleryChange(e, index, "text")}
                      onInput={(e) => {
                        if (e.target.value.length > e.target.maxLength)
                          e.target.value = e.target.value.slice(
                            0,
                            e.target.maxLength
                          );
                        if (e.target.value.length !== 1500) {
                          e.target.setCustomValidity("invalid Number");
                        } else if (e.target.value.length == 1500) {
                          e.target.setCustomValidity("");

                          addGalleryChange(e, index, "text");
                        }
                      }}
                      onFocus={(e) =>
                        e.target.addEventListener(
                          "wheel",
                          function (e) {
                            e.preventDefault();
                          },
                          { passive: false }
                        )
                      }
                      maxlength={1500}
                    />
                  </Col>
                  {galleryLists?.length > 1 && (
                    <DeleteIcon
                      className="closeIcon"
                      onClick={() => {
                        removeGalleryList(item, index);
                      }}
                    />
                  )}
                </div>
              );
            })}
            <div className="d-flex justify-content-end">
              <button className="add-btn cust-btn" onClick={addGllery}>
                Add
              </button>
            </div>
            {galleryDataListErr && galleryDataList.length <= 0 && (
              <p className="error-state-profile">
                Add Image, Title and Description
              </p>
            )}
          </div>
        ) : null}
      </div>
      <div className="mt-3">
        <button
          disabled={disableSubmit}
          onClick={submitHandler}
          className="cust-btn btn-style bg-sec m-lg-0"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default NewPostScreen;
