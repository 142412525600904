import React from 'react'

const ImageCropErrPopup = ({ setImageErr }) => {
    const onClick = () => {
        setImageErr(false)
    }
    return (
        <div className="loginpopup1">
            <div className="loginpopuplayout1 mo-di">
                <div className="customPopupDesign">
                    <h6 className="text-center">Image should be cropped</h6>
                    <div className="d-flex justify-content-center pt-2">
                        <button style={{
                            width: '55px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            lineHeight: '20px'
                        }}
                            className="cust-btn bg-primar text-white rounded-3 p-2 d-grid m-3"
                            onClick={onClick}
                        >
                            Ok
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ImageCropErrPopup