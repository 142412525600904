import React from 'react'
import { Col } from 'react-bootstrap'
import { education } from '../../../assets/img'

const EducationRight = () => {
    return (
        <Col xs={12} md={5} className="tl-service align-items-center justify-content-center">
            <div className="service-box ac-jc d-flex flex-wrap w-100">
                <div className="bg-white">
                    <div className="box-inner1">
                        <img
                            src={education}
                            className="image-abt object-fit-contain"
                            loading='lazy'
                        />
                    </div>
                </div>
            </div>
        </Col>
    )
}

export default EducationRight