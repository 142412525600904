// @ts-nocheck
import React, { useState } from "react";
import JobRelatedList from "../JobList/JobRelatedList";

const JobsRelatePost = ({ postDetails, start }) => {
  const [related, setRelated] = useState(true);
  const [times, setTimes] = useState(false);
  const onClickShowHandler = (e) => {
    if (e == 1) {
      setRelated(true);
      setTimes(false);
    }
    if (e == 2) {
      setTimes(true);
      setRelated(false);
    }
  };
  return (
    <div className="w-100">
      <div className="d-flex flex-wrap relate-post-tab">
        <button
          onClick={() => onClickShowHandler(1)}
          className={`cust-btn ${related && "active"} `}
        >
          <span>
            {postDetails?.vacancy?.categories?.parent?.name
              .toLowerCase()
              .startsWith("appli")
              ? "Profiles"
              : "Vacancies"}
          </span>
        </button>
        {/* <button
          onClick={() => onClickShowHandler(2)}
          className={`cust-btn ${times && "active"} `}
        >
          <span>Times</span>
        </button> */}
      </div>
      {postDetails?.profiles?.length > 0 && (
        <div className="bg-gray4">
          <JobRelatedList postDetails={postDetails} />
        </div>
      )}
    </div>
  );
};

export default JobsRelatePost;
