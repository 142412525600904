import React from "react";
import { CloseButton } from "react-bootstrap";
import ArrowRightAltRoundedIcon from "@mui/icons-material/ArrowRightAltRounded";
import { Link } from "react-router-dom";

const PrivacyPolicy = ({ privacyPolicyHandle }) => {
  return (
    <div className="modal-popup">
      <div className="back-close" />
      <div className="center-content pb-5">
        <button
          className="modal-cls-btn fs-33 f5 primary px-2 mb-0 mx-2 border-0"
          onClick={privacyPolicyHandle}
        >
          <CloseButton />
        </button>
        <div className=" pt-5 px-4 w-100 justify-content-center mt-md-5 flex-md-row">
          <h4 className="f4 text-dark fs-xl-23 fs-lg-19 fs-md-20 fs-sm-21 fs-xs-22 box-text-form text-center">
            Privacy Policy
          </h4>
        </div>
        <div className="w-70 mt-4 mb-0 service-detail-sec">
          <h1 className="font-custom primary1">Introduction</h1>
          <div className="my-2 desc-txt font-custom2">
            <p>
              At PNO, we understand that your privacy is of utmost importance.
              We are fully committed to protecting the personal information you
              share with us and ensuring that it is handled with care and
              respect.
            </p>
            <div className="my-2 desc-txt">
              <p>
                This Privacy Policy outlines how we collect, use, and protect
                the information you provide when using our mobile application
                PNO.
              </p>
            </div>

            <div className="my-2 desc-txt">
              <p>
                Our goal is to be transparent about our practices, helping you
                feel confident about how your data is managed. Whether you're
                using our App to post content, build your business profile, or
                communicate with other users, we want you to know that your
                privacy is a priority.
              </p>
            </div>

            <div className="my-2 desc-txt">
              <p>
                We have implemented robust security measures to safeguard your
                information, and we are committed to maintaining the trust you
                place in us by using our services.
              </p>
            </div>
          </div>
        </div>
        <div className="w-70 mt-4 mb-0 service-detail-sec">
          <h1 className="font-custom primary1">
            Our Commitment to All Users, Including Children
          </h1>
          <div className="my-2 desc-txt font-custom2">
            <p>
              Our App is designed to be safe and appropriate for users of all
              ages, including children. We do not provide any content that is
              unsuitable for children, and we take extra care to ensure that the
              environment within our App is family friendly. We believe that
              digital tools should be accessible to everyone, including younger
              audiences, and we are committed to providing a secure and
              respectful space for all our users.
            </p>
          </div>
        </div>
        <div className="w-70 mt-4 mb-0 service-detail-sec">
          <h1 className="font-custom primary1">Information We Collect</h1>
          <div className="my-2 desc-txt font-custom2">
            <p>
              <b>Personal Information:</b> This includes information you provide
              directly, such as Your name, Phone number, Blood Group, Gender,
              and any other information you submit through the App to post
              content or build your business profile.
            </p>

            <div className="my-2 desc-txt">
              <p>
                <b>Product Images and Content: </b> When you upload images or
                other content related to your products, this data is stored
                securely on our cloud storage servers.
              </p>
            </div>
          </div>
        </div>
        <div className="w-70 mt-4 mb-0 service-detail-sec">
          <h1 className="font-custom primary1">How We Use Your Information</h1>
          <div className="my-2 desc-txt font-custom2">
            <p className="mb-3">We use the information collected to:</p>
            <div className="d-flex">
              <ArrowRightAltRoundedIcon className="font-custom" />
              <p> Provide, maintain, and improve our App and services.</p>
            </div>
            <div className="d-flex">
              <ArrowRightAltRoundedIcon className="font-custom" />
              <p>
                Enable you to post content, build your business profile, and
                communicate with other users.
              </p>
            </div>
            <div className="d-flex">
              <ArrowRightAltRoundedIcon className="font-custom" />
              <p>
                Store and manage the images and content you upload for your
                products.
              </p>
            </div>
            <div className="d-flex">
              <ArrowRightAltRoundedIcon className="font-custom" />
              <p>Personalize the user experience and offer tailored content.</p>
            </div>
            <div className="d-flex">
              <ArrowRightAltRoundedIcon className="font-custom" />
              <p>
                Communicate with you, including sending updates and promotional
                materials.
              </p>
            </div>
            <div className="d-flex">
              <ArrowRightAltRoundedIcon className="font-custom" />
              <p>Analyze usage patterns to enhance our services.</p>
            </div>
            <div className="d-flex">
              <ArrowRightAltRoundedIcon className="font-custom" />
              <p>Ensure compliance with legal obligations.</p>
            </div>
          </div>
        </div>
        <div className="w-70 mt-4 mb-0 service-detail-sec">
          <h1 className="font-custom primary1">Data Storage and Security</h1>
          <div className="my-2 desc-txt font-custom2">
            <p>
              <b>Cloud Storage: </b>
              Product images and other content you upload are securely stored on
              our cloud storage servers.
            </p>

            <div className="my-2 desc-txt">
              <p>
                <b>Access Control: </b> Nobody has access to your images and
                content unless you choose to publish them in your product
                listings within the App.
              </p>
            </div>

            <div className="my-2 desc-txt">
              <p>
                <b>Security Measures: </b> We implement reasonable security
                measures to protect your data from unauthorized access,
                alteration, or destruction.
              </p>
            </div>
          </div>
        </div>
        <div className="w-70 mt-4 mb-0 service-detail-sec">
          <h1 className="font-custom primary1">Data Sharing</h1>
          <div className="my-2 desc-txt font-custom2">
            <p>
              We do not share, trade, or otherwise transfer your personal
              information or product data with third parties except in the
              following cases:
            </p>

            <div className="my-2 desc-txt">
              <p>
                <b>Service Providers: </b> We may share your information with
                third-party service providers who assist us in operating our app
                and providing our services, subject to strict confidentiality
                obligations.
              </p>
            </div>

            <div className="my-2 desc-txt">
              <p>
                <b>For legal reasons: </b> We may disclose your information if
                required by law or in response to a valid legal process.
              </p>
            </div>
          </div>
        </div>

        <div className="w-70 mt-4 mb-0 service-detail-sec">
          <h1 className="font-custom primary1">Your Rights</h1>
          <div className="my-2 desc-txt font-custom2">
            <p>You have the right to:</p>

            <div className="d-flex">
              <ArrowRightAltRoundedIcon className="font-custom" />
              <p>Access the personal information we hold about you.</p>
            </div>

            <div className="d-flex">
              <ArrowRightAltRoundedIcon className="font-custom" />
              <p>
                Request correction or deletion of your personal information.
              </p>
            </div>

            <div className="d-flex">
              <ArrowRightAltRoundedIcon className="font-custom" />
              <p>Withdraw your consent to data processing at any time.</p>
            </div>
            <div className="d-flex">
              <ArrowRightAltRoundedIcon className="font-custom" />
              <p>
                You can opt out of receiving promotional communications from us
                by contacting us.
              </p>
            </div>

            <div className="my-2 desc-txt">
              <p>
                To exercise these rights, please contact us at ASQUARE, contacts
              </p>
            </div>
          </div>
        </div>

        <div className="w-70 mt-4 mb-0 service-detail-sec">
          <h1 className="font-custom primary1">
            Changes to This Privacy Policy
          </h1>
          <div className="my-2 desc-txt font-custom2">
            <p>
              We may update this Privacy Policy from time to time. Any changes
              will be posted within the App, and the revised policy will be
              effective immediately upon posting.
            </p>
          </div>
        </div>

        <div className="w-70 mt-4 mb-0 service-detail-sec">
          <h1 className="font-custom primary1">Contact Us</h1>
          <div className="my-2 desc-txt font-custom2">
            <p>
              If you have any questions about this Privacy Policy, please
              contact us at:
            </p>
          </div>
        </div>

        <div className="w-70 mt-4 mb-0 service-detail-sec">
          <h1 className="font-custom primary1">ASQUARE</h1>
          <div className="my-2 desc-txt font-custom2">
            <p>Pakkir Malimar Street, Parangi Pettai.</p>

            <p>
              <Link target="_blank" to="tel:04144-243243">
                Tel: 04144-243243
              </Link>
            </p>

            <p>
              {" "}
              <Link
                to={`https://web.whatsapp.com/send?autoload=1&app_absent=0&phone=919988779928&text=Hi`}
                target="_blank"
              >
                WhatsApp: +91 9988779928
              </Link>
            </p>

            <p>
              <Link to="mailto:asquare.pno@gmail.com" target="_blank">
                Email: asquare.pno@gmail.com
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
