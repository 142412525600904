import React from "react";
import { Col } from "react-bootstrap";
import ClassifiedBox from "../../Classified/ClassifiedList/ClassifiedBox";

const ClassifiedPostList = () => {
  return (
    <div className="d-flex flex-wrap w-100 gap-3">
      <ClassifiedBox method="TabMethod" />
    </div>
  );
};

export default ClassifiedPostList;
