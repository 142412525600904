import React from "react";
import { Outlet } from "react-router-dom";
import NavBar from "../navBar/NavBar1";
import FooterFirst from "../footer/footerFirst";
import FooterMiddle from "../footer/footerMiddle";
import FooterLast from "../footer/footerLast";
import ProfileNav from "../navBar/ProfileNav";
const Layout1 = () => {
  return (
    <div className="bg-lightBlue d-flex min-h-100">
      <ProfileNav />
      <div className="body-profile w-100 overflow-y-scroll">
        <Outlet />
      </div>
    </div>
  );
};

export default Layout1;
