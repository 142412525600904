import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { abt } from '../../../assets/img'

const AboutusRight = () => {
    return (
        <Col xs={12} md={6} className="service-detail-sec col-custom-res align-self-center">
            <h5 className="primary1 font-custom
                ">ABOUT US
                <div className='activeLine align-self-center' />
            </h5>
            <div className="my-2 desc-txt font-custom2">
                <p>We genuinely appreciate your keen interest in getting to know us better. Your curiosity is highly valued and esteemed.
                </p>
                <div className='my-2 desc-txt'>
                    <p>
                        We Prefer Service Over Business
                        ASQUARE is a perfect fusion of local allegiance and global finesse. Anchored by dedication, perseverance, and integrity, Asquare has been a stalwart supporter of our local community. Our roots are deeply embedded in the culture of Parangi Pettai, and we hold an unwavering commitment to serve our community in the most meaningful ways possible
                    </p>
                </div>
            </div>
        </Col>
    )
}

export default AboutusRight