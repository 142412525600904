// @ts-nocheck
import React, { useEffect, useState } from "react";
import { classifiedBanner, jobs_banner } from "../../assets/img";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import HomeIcon from "@mui/icons-material/Home";
import JobTab from "../../Components/Jobs/JobList/JobTab";
import JobList from "../../Components/Jobs/JobList/JobList";
import {
  useCategoryListsMutation,
  useJobSubcategoriesMutation,
  useOverallListMutation,
  useSubcategoryListMutation,
} from "../../redux/api/api";
import JobLists from "../../Components/Jobs/JobList/JobLists";
import ReactPaginate from "react-paginate";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

const JobListScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showTabList, setShowTabList] = useState(true);
  const [subCategTabList, setSubCategTabList] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [subcategoriesList, setSubCategoriesList] = useState([]);
  const [subcategoriesListId, setSubCategoriesListId] = useState("");
  const [selectedSubCat, setSelectedSubCat] = useState();
  const [jobList, setJobList] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(10);
  const [listOrder, setListOrder] = useState("");
  const [catNames, setCatNames] = useState([]);
  const [catSubNames, setCatSubNames] = useState([]);
  const [subcategoriesListsId, setSubCategoriesListsId] = useState("");

  const [jobSubcategories] = useJobSubcategoriesMutation();
  const [subcategoryListData] = useSubcategoryListMutation();
  const [overallList] = useOverallListMutation();
  const [catgoryList] = useCategoryListsMutation();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (subcategoriesListsId) {
      getCategoryListWithPaginate(subcategoriesListsId);
    } else if (subcategoriesListId) {
      getSubcategoriesListPaginate(subcategoriesListId);
    } else if (!searchParams?.get("catId")) {
      getOverallList();
    }
  }, [page]);

  const getOverallList = (order) => {
    removeQueryParams();
    if (Object.values(catNames)?.length > 0) {
      setPage(1);
    }
    setSubCategoriesListsId("");
    setSubCategoriesListId("");
    const formData = new FormData();
    formData.append("type", 4); //   {1->classified,2->services,3->explores,4->jobs}

    if (order || listOrder !== "") {
      formData.append("order", order ? order : listOrder);
    } else {
      formData.append("order", "desc");
      setListOrder("desc");
    }

    let params = `?page=${page}`;
    overallList({ payload: formData, params })
      .unwrap()
      .then((res) => {
        if (res?.status === "success") {
          setJobList(res);
          setSubCategTabList(true);
          setCatNames([]);
          setCatSubNames([]);
          setSelectedSubCat("");
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const showTabListHandler = (item) => {
    setPage(1);
    const formData = new FormData();
    formData.append("subcategory_id", item?.id);
    jobSubcategories(formData)
      .unwrap()
      .then((res) => {
        // console.log(res);
        if (res?.status === "success") {
          setSubCategoriesList(res?.sub_categories);
          if (searchParams?.get("subId")) {
            setSelectedSubCat(Number(searchParams?.get("subId")));
          }
          if (searchParams?.get("subId")) {
            getSubcategoriesList(searchParams?.get("subId"));
          } else {
            getCategoryList(item?.id);
            setSubCategoriesListsId(item?.id);
          }

          // setCatSubNames(res?.sub_categories[0]);
          // setSubCategoriesListId(res?.sub_categories?.[0]?.id);

          setSubCategTabList(false);
        }
      });
  };

  const getCategoryList = (id) => {
    setSelectedSubCat("");
    setSubCategoriesListsId(id);
    let formdata = new FormData();
    formdata.append("type", 4);
    formdata.append("category_id", id);
    let params = `?page=${1}`;
    setSubCategoriesListId("");
    formdata.append("order", "desc");
    setListOrder("desc");
    catgoryList({ payload: formdata, params })
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          setJobList(res);
        }
      });
  };

  // CATEGORY LISTS WITH PAGINATE
  const getCategoryListWithPaginate = (id, order) => {
    setSubCategoriesListsId(id);
    setSelectedSubCat("");
    let formdata = new FormData();
    formdata.append("type", 4);
    formdata.append("category_id", id);
    if (order || listOrder !== "") {
      formdata.append("order", order ? order : listOrder);
    } else {
      formdata.append("order", "desc");
      setListOrder("desc");
    }
    let params = `?page=${page}`;
    catgoryList({ payload: formdata, params })
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          setJobList(res);
        }
      });
  };

  const getSubcategoriesList = (id) => {
    // console.log("page", page);
    setSubCategoriesListId(id);
    setJobList([]);
    setSubCategoriesListsId("");
    const formData = new FormData();
    if (id) {
      let params = `?page=${1}`;
      formData.append("subcategory_id", id);
      formData.append("order", "desc");
      setListOrder("desc");
      formData.append("type", 4); //   {1->classified,2->services,3->explores,4->jobs}

      subcategoryListData({ payload: formData, params })
        .unwrap()
        .then((res) => {
          // console.log(res);
          if (res?.status === "success") {
            setJobList(res);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  const getSubcategoriesListPaginate = (id, order) => {
    // console.log("page", page);
    setSubCategoriesListId(id);
    setJobList([]);
    setSubCategoriesListsId("");
    const formData = new FormData();
    if (order || listOrder !== "") {
      formData.append("order", order ? order : listOrder);
    } else {
      formData.append("order", "desc");
      setListOrder("desc");
    }
    if (id) {
      let params = `?page=${page}`;
      formData.append("subcategory_id", id);
      formData.append("type", 4); //  {1->classified,2->services,3->explores,4->jobs}
      subcategoryListData({ payload: formData, params })
        .unwrap()
        .then((res) => {
          // console.log(res);
          if (res?.status === "success") {
            setJobList(res);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  const onSubCategoryClick = (item) => {
    getSubcategoriesList(item?.id);
    setSelectedSubCat(item?.id);
    setCatSubNames(item);
    removeQueryParams();
    setListOrder("");
  };

  const handlePageClick = (e) => {
    setPage(e.selected + 1);
  };

  useEffect(() => {
    setPageCount(jobList?.pagination?.last_page);
  }, [jobList]);

  useEffect(() => {
    // console.log(listOrder);
    if (location?.state?.subcategory_id) {
      showTabListHandler(location?.state?.subcategory_id);
    } else if (searchParams?.get("catId") && searchParams?.get("subId")) {
      let item = {
        id: searchParams?.get("catId"),
      };
      let data = {
        id: searchParams?.get("catId"),
        name: searchParams?.get("catName"),
      };
      let datas = {
        id: searchParams?.get("subId"),
        name: searchParams?.get("subName"),
      };
      showTabListHandler(item);
      setCatNames(data);
      setCatSubNames(datas);
    } else if (searchParams?.get("catId")) {
      let item = {
        id: searchParams?.get("catId"),
      };
      let data = {
        id: searchParams?.get("catId"),
        name: searchParams?.get("catName"),
      };
      showTabListHandler(item);
      setCatNames(data);
    } else {
      if (subcategoriesListId) {
        // console.log("INSIDE IF");
        getSubcategoriesListPaginate(subcategoriesListId, listOrder);
      } else if (subcategoriesListsId) {
        getCategoryListWithPaginate(subcategoriesListsId, listOrder);
      } else {
        // console.log("OUTSIDE IF");
        getOverallList(listOrder);
      }
    }
  }, [listOrder, searchParams?.get("catId")]);

  // REMOVE PARAMS
  const removeQueryParams = () => {
    const catId = searchParams.get("catId");
    const subId = searchParams.get("subId");
    const catName = searchParams.get("catName");
    const subName = searchParams.get("subName");

    if (catId) {
      // 👇️ delete each query param
      searchParams.delete("catId");

      // 👇️ update state after
      setSearchParams(searchParams);
    }

    if (subId) {
      searchParams.delete("subId");
      setSearchParams(searchParams);
    }

    if (catName) {
      searchParams.delete("catName");
      setSearchParams(searchParams);
    }

    if (subName) {
      searchParams.delete("subName");
      setSearchParams(searchParams);
    }
  };

  return (
    <div className="tl-clasified-page">
      <div className="list-banner pt-3 pb-2">
        <img src={jobs_banner} alt="" />
      </div>
      <div className="bread-crum-list d-flex ac-je">
        <button
          className="cust-btn d-flex ac-js fs-14 f2"
          onClick={() => navigate("/")}
        >
          <HomeIcon className="fs-16 me-1" />
          Home
        </button>
        <ChevronRightIcon className="fs-20 me-1" />
        <button
          className="cust-btn d-flex ac-js fs-14 f2"
          onClick={() => getOverallList()}
        >
          Jobs
        </button>
        {Object.values(catNames)?.length > 0 && (
          <>
            <ChevronRightIcon className="fs-20 me-1" />
            <button
              className="cust-btn d-flex ac-js fs-14 f2"
              onClick={() => {
                setCatSubNames([]);
                removeQueryParams();
                showTabListHandler(catNames);
                setSubCategoriesListId("");
                setPage(1);
                setListOrder("");
              }}
            >
              {catNames?.name}
            </button>
          </>
        )}
        {jobList?.jobs?.length > 0 &&
          Object.values(catSubNames)?.length > 0 && (
            <>
              <ChevronRightIcon className="fs-20 me-1" />
              <button className="cust-btn d-flex ac-js fs-14 f2">
                {catSubNames?.name}
              </button>
            </>
          )}
      </div>

      <JobTab
        showTabList={showTabList}
        setShowTabList={setShowTabList}
        subCategTabList={subCategTabList}
        showTabListHandler={showTabListHandler}
        setPage={setPage}
        subcategoriesList={subcategoriesList}
        selectedSubCat={selectedSubCat}
        onSubCategoryClick={onSubCategoryClick}
        setCatNames={setCatNames}
      />
      <JobLists
        jobList={jobList}
        setListOrder={setListOrder}
        listOrder={listOrder}
      />

      {/* FOOTER */}
      <div className="pagination-tab d-flex ac-jc">
        <ReactPaginate
          containerClassName="pagination-tab-inner d-flex ac-jc"
          pageClassName="cust-btn d-flex ac-jc"
          nextClassName="cust-btn d-flex ac-jc"
          previousClassName="cust-btn d-flex ac-jc"
          breakClassName="cust-btn d-flex ac-jc"
          activeClassName="cust-btn d-flex ac-jc active"
          breakLabel="..."
          nextLabel={<KeyboardArrowRightIcon />}
          onPageChange={handlePageClick}
          pageRangeDisplayed={2}
          pageCount={pageCount}
          forcePage={page - 1}
          previousLabel={<KeyboardArrowLeftIcon />}
          renderOnZeroPageCount={null}
        />
      </div>
    </div>
  );
};

export default JobListScreen;
