import React from "react";
import ExploreBox from "../../Explore/ExploreList/ExploreBox";

const ExploreList = () => {
  return (
    <div className="d-flex flex-wrap w-100 gap-3">
      <ExploreBox method="TabMethod" />
    </div>
  );
};

export default ExploreList;
