import React from "react";
import { Outlet } from "react-router-dom";
import NavBar from "../navBar/NavBar1";
import FooterFirst from "../footer/footerFirst";
import FooterMiddle from "../footer/footerMiddle";
import FooterLast from "../footer/footerLast";
const Layout = () => {
  return (
    <div className="bg-lightBlue min-h-100">
      <NavBar />
      <div className="body-design w-100">
        <Outlet />
      </div>
      <div className="footer-cont">
        <div className="d-none d-md-block w-100">
          <FooterFirst />
        </div>
        <div className="d-block w-100">
          <FooterMiddle />
        </div>
        <div className="d-none d-md-block w-100">
          <FooterLast />
        </div>
      </div>
    </div>
  );
};

export default Layout;
