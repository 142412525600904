import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  createRef,
} from "react";
import { NUMBER } from "../../redux/api/constants";
import DeleteIcon from "@mui/icons-material/Delete";
import ImageIcon from "@mui/icons-material/AddAPhoto";
import {
  useLazyProfile_viewQuery,
  useProfileEditMutation,
  useProfile_viewQuery,
} from "../../redux/api/api";
import useUser from "../../redux/store/userData";
import Select from "react-select";
import { sofa } from "../../assets/img";
import ImageCrop from "./ImageCrop";

// IMAGE CROPER
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import ImageCropErrPopup from "./ImageCropErrPopup";

const ProfileScreen = () => {
  // RTK
  const [profileUpdate] = useProfileEditMutation();
  //InputValues
  const [fullName, setFullName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [streetName, setStreetName] = useState({});
  const [residenceName, setResidenceName] = useState("");
  const [profession, setProfession] = useState("");
  const [bloodGroup, setBloodGroup] = useState({});
  const [gender, setGender] = useState({});
  const [profileImage, setProfileImage] = useState("");
  const [focusCheck, setFocusCheck] = useState(false);
  const [image, setImage] = useState("");
  const [update, setUpdate] = useState(false);
  const [option, setOption] = useState([]);
  const [bloodGOptions, setBloodGOptions] = useState([]);
  const [genderOptions, setGenderOptions] = useState([]);
  const [count, setCount] = useState(0);
  const [btn, setBtn] = useState(false);
  const [loading, setLoading] = useState(false);

  // IMAGE CROPER

  const [images, setImages] = useState("");
  const [cropData, setCropData] = useState("");
  const cropperRef = createRef();

  const { setUser } = useUser();

  //ErrorValues
  const [fullNameErr, setFullNameErr] = useState(false);
  const [mobileNumberErr, setMobileNumberErr] = useState(false);
  const [streetNameErr, setStreetNameErr] = useState(false);
  const [residenceNameErr, setResidenceNameErr] = useState(false);
  const [professionErr, setProfessionErr] = useState(false);
  const [bloodGroupErr, setBloodGroupErr] = useState(false);
  const [genderErr, setGenderErr] = useState(false);
  const [imageErr, setImageErr] = useState(false);

  // RTK QUERY

  const [profile_view] = useLazyProfile_viewQuery();

  const profileImageRef = useRef(null);

  const streetNames = [
    "Yadavar Street",
    "Veerappa Aasari Street",
    "Vatta Thaikkal",
    "Vathiya Palli Street",
    "Varadaraja Perumal Koil Street",
    "Vanuvar Street",
    "Vannarapalayam Arijana Street",
    "Vannarapalayam Appasamy Padayachi Street",
    "Vannarapalayam",
    "Vandikkara Street",
    "Vallathambi Maraickayar Street",
    "Vakkambilbeer Street",
    "Vadakuthurai Vannarapalayam",
    "Vadakauthurai Arijina Colony",
    "Uppukara Street",
    "Uppukara Lane",
    "Thonothurai",
    "Thiyagu Chetty Street",
    "Thethukadai Street",
    "Thesan Tharka Street",
    "Thesan Thaikkal Street",
    "Therodum South Street",
    "Theetha Mudali Street",
    "Syed Mohamed Sahib Street",
    "Sivan Padagar Street",
    "Small Street",
    "Seshaya Nagar",
    "Senjiluvai Salai",
    "Second Double Well Street",
    "Sanjeevirayar Koil Street",
    "Sanaramudukku Street",
    "Samynathan Street",
    "Salangukara West Street",
    "Salangukara East Street",
    "Salakarai Mariyamman Koil Street",
    "Sakkilimedu",
    "Sahib Miyan",
    "S S Nagar",
    "Revu Main Road",
    "Renga Pillai Mandapa Street",
    "Ramapadayatchi Street",
    "Police Line",
    "Pettar Street",
    "Perumal Koil Street",
    "Periyanayaki Amman Koil Street",
    "Periya Street",
    "Periya Kadai Street",
    "Periya Asarakana Street",
    "Pappan Kodi Street",
    "Pandaga Salai Street",
    "Nellu Kadai Street",
    "Nattan Kinnatru Mudukku Street",
    "Nanthavana Agrahara Street",
    "Nallam Bala Pillai Street",
    "Nakutha Maraickayar Street",
    "Muthal Double Well Street",
    "Muthaiya Muthaliyar Street",
    "Moulana Thaikal",
    "Mittai Kadai Street",
    "Mishan Street",
    "Mirjamiyan Street",
    "Mettu Street",
    "Mettu Pattani Mudukku Street",
    "Meeran Sandhu",
    "Meera Palli Street",
    "Meen Kadai Street",
    "Mattukaran Thaikal",
    "Mathina Nagar",
    "Malimar Nagar",
    "Makka Garden",
    "Main Road",
    "Madha Koil Street",
    "Logaiya Naidu Street",
    "Kuttiya Chetty Street",
    "Kummath Palli Street",
    "Kumarakoil South Street",
    "Kumarakoil East Street",
    "Kotthar Street",
    "Kotthar Lane",
    "Kottathangarai Street",
    "Kolan Kadai Street",
    "Kodi Marathu Street Rajaji Street",
    "Kitharsha Maraickayar Street",
    "Keerai Kara Street",
    "Katcherry Street",
    "Karunanithi Street",
    "Kamatchi Amman Koil Street",
    "Kallamuthiya Chetty Street",
    "Kalima Nagar Extention",
    "Kalima Nagar",
    "Kalima Complex",
    "Kajiyar Street",
    "Kajiyar Lane",
    "Kaithe Millath Nagar",
    "Junnath Miyan Street",
    "Jain Bava Thaikkal",
    "Hakka Sahib Darga Street",
    "Haji Bakkeer Malimar Tharga Street",
    "Gurusamy Rayar Street",
    "Gurusamy Mettu Street",
    "Gurunatha Chetty Mettu Street",
    "Fathima Nagar",
    "Ethirees Maraickayar Nagar",
    "Ellaiamman Koil Street",
    "Ekambara Aasari Street",
    "Dr Mohamed Ibrahim Nagar",
    "Dr Abdhul Kalam Nagar",
    "Cresent Nagar",
    "Chinna Kadai Street",
    "Chetty Street",
    "Bharath Nagar",
    "Bargon Mudukku Street",
    "B Manampadi Kadai Street",
    "Aysha Nagar",
    "Atrangarai Mettu Street",
    "Atrangarai Arijana Colony",
    "Athimoolasami Street",
    "Athangarai East Street",
    "Asiriyar Nagar",
    "Arunachala Mudaliyar Street",
    "Ariyanattu West Street",
    "Ariyanattu Vaduga Street",
    "Ariyanattu South Street",
    "Ariyanattu East Street",
    "Ariyanaattu Kavara Street",
    "Ariyanaattu Chattira Street",
    "Arakatchi Nachiyar Street",
    "Appasamy Padayachi Street",
    "Appa Palli Street",
    "Anusuya Nagar",
    "Anna Nagar",
    "Ameer Gardan",
    "Abdul Rahim Nagar",
    "Aagin Thaikal",
    "A P S Garden",
    "A K Nagar",
  ];
  const bloodGrp = ["A+", "A-", "B+", "B-", "O+", "O-", "AB+", "AB-"];
  const genderVal = ["Male", "Female"];

  useEffect(() => {
    let temp = [];

    streetNames.sort().forEach((e) => {
      temp.push({
        value: e,
        label: e,
      });
    });

    setOption(temp);
    // BLOOD GROUP OPTIONS
    let bloodGTmp = [];
    bloodGrp.forEach((e) => {
      bloodGTmp.push({
        value: e,
        label: e,
      });
    });
    setBloodGOptions([...bloodGTmp]);
    // GENDER OPTIONS
    let genderTmp = [];
    genderVal.forEach((e) => {
      genderTmp.push({
        value: e,
        label: e,
      });
      setGenderOptions(genderTmp);
    });
  }, []);

  const getProfile = () => {
    profile_view()
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          let data = res?.user;
          setFullName(data?.name);
          setMobileNumber(data?.mobile);
          setStreetName({ value: data?.address, label: data?.address });
          setResidenceName(data?.country);
          setProfession(data?.profession);
          setBloodGroup({ value: data?.blood_group, label: data?.blood_group });
          setGender({ value: data?.gender, label: data?.gender });
          if (data?.image == null) {
            setProfileImage("");
          } else {
            setProfileImage(data?.image_path);
          }
        }
      })
      .catch((err) => { });
  };

  useEffect(() => {
    getProfile();
  }, []);

  const submitHandler = () => {
    let formdata = new FormData();
    if (count == 0) {
      if (
        fullName.length < 1 ||
        mobileNumber.length < 10 ||
        streetName.length < 1 ||
        residenceName.length < 1 ||
        profession.length < 1 ||
        bloodGroup === "" ||
        gender === ""
      ) {
        setFullNameErr(true);
        setMobileNumberErr(true);
        setStreetNameErr(true);
        setResidenceNameErr(true);
        setProfessionErr(true);
        setBloodGroupErr(true);
        setGenderErr(true);
        setFocusCheck(true);
        // console.log('Error')
      } else {
        formdata.append("mobile", mobileNumber);
        formdata.append("name", fullName);
        formdata.append("address", streetName?.value);
        formdata.append("country", residenceName);
        formdata.append("profession", profession);
        formdata.append("gender", gender?.value);
        formdata.append("blood_group", bloodGroup?.value);

        if (image) {
          formdata.append("image", image);
        }
        setLoading(true);
        setBtn(true);
        profileUpdate(formdata)
          .then((res) => {
            if (res?.data?.status == "success") {
              setUpdate(false);
              setUser(res?.data?.user);
              setLoading(false);
              setBtn(false);
              setTimeout(() => {
                window.location.reload();
              }, 400);
            }
          })
          .catch((err) => {
            setBtn(false);
            setLoading(false);
            console.log("err", err);
          });
      }
    } else {
      setImageErr(true);
    }
  };

  const fullNameId = useRef(null);
  const mobileNumberId = useRef(null);
  const streetNameId = useRef(null);
  const residenceNameId = useRef(null);
  const professionId = useRef(null);
  const bloodGroupId = useRef(null);
  const genderId = useRef(null);

  useEffect(() => {
    if (focusCheck === true) {
      if (fullNameErr === true) {
        if (fullName.length < 1) {
          fullNameId.current.focus();
        } else if (mobileNumberErr === true) {
          if (NUMBER.test(mobileNumber) == false || mobileNumber.length < 10) {
            mobileNumberId.current.focus();
          } else if (streetNameErr === true) {
            if (streetName.length < 1) {
              streetNameId.current.focus();
            } else if (residenceNameErr === true) {
              if (residenceName.length < 1) {
                residenceNameId.current.focus();
              } else if (professionErr === true) {
                if (profession < 1) {
                  professionId.current.focus();
                } else if (bloodGroupErr === true) {
                  if (bloodGroup === "") {
                    bloodGroupId.current.focus();
                  } else if (genderErr === true) {
                    if (gender === "") {
                      genderId.current.focus();
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    setFocusCheck(false);
  }, [focusCheck]);

  // IMAGE CROPER

  const onChange = (e) => {
    e.preventDefault();
    let files;
    // console.log(e);
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setCount(1);
      setImages(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      setCropData(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
      setImage(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
      setCount(0);
    }
  };

  return (
    <>
      <div className="profile-right bg-white h-100 scroll-w">
        {loading && (
          <div className="loader-icon position-fixed bg-opacity-25 loderbg ac-jc">
            <div className="loader-icon1">{/* <img src={lodergif} /> */}</div>
          </div>
        )}
        <h5>Profile</h5>
        <div className="d-flex flex-wrap justify-content-between">
          <div className="form-groups position-relative">
            <h4>Full Name</h4>
            <input
              type="text"
              ref={fullNameId}
              onChange={(e) => setFullName(e.target.value)}
              value={fullName}
              disabled={update ? false : true}
            />
            {fullNameErr && fullName.length < 1 && (
              <p className="error-state-profile">Enter your Full Name</p>
            )}
          </div>
          <div className="form-groups position-relative">
            <h4>Mobile Number</h4>
            <input
              type="number"
              className="mobileInput-profile mt-1"
              ref={mobileNumberId}
              onChange={(e) => setMobileNumber(e.target.value)}
              value={mobileNumber}
              disabled
            />
            <p className="mobileInput-profile-p">+91</p>
            {mobileNumberErr &&
              (NUMBER.test(mobileNumber) == false ||
                mobileNumber.length < 10) && (
                <p className="error-state-profile">Enter your Mobile Number</p>
              )}
          </div>
          <div className="form-groups position-relative">
            <h4>Street Name</h4>
            <div
              style={{
                border: "1px solid #d4d4d4",
                borderRadius: "5px",
                color: "#000",
              }}
            >
              <Select
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: "#fff",
                    paddingLeft: 15,
                    fontSize: "15px",
                    fontFamily: "f2",
                    color: "#000",
                  }),
                }}
                type="text"
                placeholder="Street Name"
                value={streetName}
                onChange={(e) => setStreetName(e)}
                options={option}
                isDisabled={update ? false : true}
              />
            </div>
            {streetNameErr && streetName.length < 1 && (
              <p className="error-state-profile">Enter your Street Name</p>
            )}
          </div>
          <div className="form-groups position-relative">
            <h4>Country of Residence</h4>
            <input
              type="text"
              ref={residenceNameId}
              onChange={(e) => setResidenceName(e.target.value)}
              value={residenceName}
              disabled={update ? false : true}
            />
            {residenceNameErr && residenceName.length < 1 && (
              <p className="error-state-profile">
                Enter your Country of Residence
              </p>
            )}
          </div>
          <div className="form-groups position-relative">
            <h4>Profession</h4>
            <input
              type="text"
              ref={professionId}
              onChange={(e) => setProfession(e.target.value)}
              value={profession}
              disabled={update ? false : true}
            />
            {professionErr && profession.length < 1 && (
              <p className="error-state-profile">Enter your Profession</p>
            )}
          </div>
          <div className="form-groups position-relative">
            <h4>Blood Group</h4>
            <div
              style={{
                border: "1px solid #d4d4d4",
                borderRadius: "5px",
                color: "#000",
              }}
            >
              <Select
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: "#fff",
                    paddingLeft: 15,
                    fontSize: "15px",
                    fontFamily: "f2",
                    color: "#000",
                  }),
                }}
                type="text"
                placeholder="Blood Group"
                value={bloodGroup}
                onChange={(e) => setBloodGroup(e)}
                options={bloodGOptions}
                isDisabled={update ? false : true}
              />
              {/* <select
              onChange={(e) => setBloodGroup(e.target.value)}
              ref={bloodGroupId}
              value={bloodGroup}
              disabled={update ? false : true}
            >
              <option value="" hidden>
                Blood Group
              </option>
              <option value="A+">A+</option>
              <option value="A-">A-</option>
              <option value="B+">B+</option>
              <option value="B-">B-</option>
              <option value="O+">O+</option>
              <option value="O-">O-</option>
              <option value="AB+">AB+</option>
              <option value="AB-">AB-</option>
            </select> */}
            </div>
            {bloodGroupErr && bloodGroup === "" && (
              <p className="error-state-profile">Select your Blood Group</p>
            )}
          </div>
          <div className="form-groups position-relative">
            <h4>Gender</h4>
            <div
              style={{
                border: "1px solid #d4d4d4",
                borderRadius: "5px",
                color: "#000",
              }}
            >
              <Select
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: "#fff",
                    paddingLeft: 15,
                    fontSize: "15px",
                    fontFamily: "f2",
                    color: "#000",
                  }),
                }}
                type="text"
                placeholder="Gender"
                value={gender}
                isDisabled={update ? false : true}
                onChange={(e) => setGender(e)}
                options={genderOptions}
              />
              {/* <select
            onChange={(e) => setGender(e.target.value)}
            ref={genderId}
            value={gender}
            disabled={update ? false : true}
          >
            <option value="" hidden>
              Gender
            </option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select> */}
            </div>
            {genderErr && gender === "" && (
              <p className="error-state-profile">Enter your Gender</p>
            )}
          </div>
        </div>
        <div className="form-groups">
          <h4>Profile Image</h4>
          {profileImage !== "" && (
            <div className="position-relative">
              <img
                src={profileImage}
                className="newPostImage rounded-3 border-1 m-lg-1 ms-1"
              />
              {/* <DeleteIcon
                className="deleteIcon-profile"
                onClick={() => (update == true ? setProfileImage("") : "")}
              /> */}
            </div>
          )}
          {profileImage === "" && (
            <div
              className="upload-img d-flex ac-jc bg-gray3 p-4 rounded-3 m-lg-1 ms-1"
              onClick={() =>
                update == true ? profileImageRef.current.click() : null
              }
            >
              <ImageIcon className="fs-2" />
              <input
                type="file"
                ref={profileImageRef}
                onChange={(e) => {
                  setProfileImage(
                    window.URL.createObjectURL(e.target.files[0])
                  );
                  setImage(e.target.files[0]);
                }}
                className="d-none"
                disabled
              />
            </div>
          )}
        </div>
        <div className="d-flex w-100 mt-3">
          {!update ? (
            <button
              className="cust-btn btn-style bg-sec"
              onClick={() => setUpdate(true)}
            >
              Edit
            </button>
          ) : null}
          {update ? (
            <div>
              <div className="mt-3">
                <div className="w-100">
                  <input type="file" onChange={onChange} />
                  <Cropper
                    ref={cropperRef}
                    style={
                      images
                        ? { height: "100%", width: "100%" }
                        : { display: "none" }
                    }
                    zoomTo={0.5}
                    preview=".img-preview"
                    src={images}
                    initialAspectRatio={9 / 9}
                    guides={false}
                    viewMode={1}
                    minCropBoxHeight={100}
                    minCropBoxWidth={100}
                    responsive={true}
                    autoCropArea={1}
                    aspectRatio={4 / 4}
                    checkOrientation={false}
                  />
                </div>
                {imageErr && <ImageCropErrPopup setImageErr={setImageErr} />}
                <div>
                  <div className="box mb-5">
                    {images && (
                      <p className="d-flex justify-content-end">
                        <button
                          className="cust-btn btn-style bg-sec"
                          style={{ width: "20%" }}
                          onClick={getCropData}
                        >
                          Crop Image
                        </button>
                      </p>
                    )}
                    {cropData && (
                      <img
                        style={{ width: "250px" }}
                        src={cropData}
                        alt="cropped"
                      />
                    )}
                  </div>
                </div>
              </div>
              <button
                className="cust-btn btn-style bg-sec"
                disabled={btn ? true : false}
                onClick={submitHandler}
              >
                Save
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default ProfileScreen;
