// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
// local import
import { logo, menu } from "../../assets/img";
import useWindowDimensions from "../../redux/api/UseWindoesDimensions";
import useUser from "../../redux/store/userData";
import { useCategoryListQuery } from "../../redux/api/api";

const NavBar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuOpens, setMenuOpens] = useState(null);
  const [subMenu, setSubMenu] = useState("");
  const { user } = useUser();
  const location = useLocation();
  const navigate = useNavigate();

  const [categoryList, setCategoryList] = useState({});
  // RTK QUERY
  const { data: categoryData, isSuccess: isCategorySuccess } =
    useCategoryListQuery();
  // console.log(categoryList?.classified_list?.length)
  useEffect(() => {
    if (isCategorySuccess) {
      // console.log(categoryData);
      setCategoryList(categoryData);
    }
  }, [categoryData]);

  const { height, width } = useWindowDimensions();

  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const menuOpenHander = () => {
    setMenuOpen(!menuOpen);
  };

  const menuCloseHander = (id) => {
    if (menuOpens == id) {
      setMenuOpens(null);
    } else {
      setMenuOpens(id);
    }
  };

  // console.log(splitLocation);
  const subMenuHandler = (menu_name) => {
    if (menu_name === subMenu) {
      setSubMenu("");
    } else {
      setSubMenu(menu_name);
    }
  };

  const [listopen1, SetListOpen1] = useState(true);

  // const sunMenuOpen1 = () => {
  //   setMenuOpen(menuOpen);
  // }

  // console.log("categoryList", categoryList);

  return (
    <div className="nav-bar1 bg-white d-flex ae-jb w-100 px-2 px-sm-5 position-relative">
      <button onClick={() => navigate("/")} className="cust-btn py-2">
        <img
          src={logo}
          onClick={() => { }}
          className="company-logo"
          alt="company-logo"
        />
      </button>
      <button
        onClick={() => {
          setMenuOpen(false);
          navigate(user ? "/profile" : "/login");
        }}
        className="nav-sign-in-cont1 d-block d-lg-none mb-3 text-capitalize "
      >
        {user?.image == null ? (
          <PersonOutlineIcon />
        ) : (
          <img src={user?.image_path} />
        )}
        {user ? user?.name : "Sign In"}
      </button>
      <button
        onClick={menuOpenHander}
        className="mobile-nav d-block d-lg-none mb-3"
        style={{ zIndex: 111111 }}
      >
        <MenuIcon />
      </button>
      <div
        className={`menus-list mb-2 ${menuOpen && "menus-list1"}`}
      // className="menus-list d-lg-block mb-2"
      >
        <div className="d-md-flex ac-jb menu position-relative">
          <button
            className="cust-btn menu-categ-btn dropdownmenu2"
            onClick={() => {
              setMenuOpen(!menuOpen);
              navigate("/");
              setSubMenu("");
            }}
          >
            <p className="menu2">home</p>
            <div
              style={{
                background: splitLocation[1] == "" ? "#000" : "#fff",
              }}
              className="activeLine ms-0"
            />
          </button>
          <div className="position-relative">
            <button
              className="cust-btn menu-categ-btn dropdownmenu"
            // onClick={() => subMenuHandler("class")}
            >
              <p
                className="menu"
                onClick={() => {
                  navigate("/classified/list");
                  // setSubMenu('class')
                  setMenuOpen(!menuOpen);
                }}
              >
                Classified <KeyboardArrowDownIcon />
              </p>
              <div className="submenu">
                {categoryList?.classified_list?.slice(0, 4).map((item, ind) => (
                  <li className="list-1">
                    <Link
                      to={`/classified/list?catId=${item?.id}&catName=${item?.name}`}
                    >
                      <p
                        class="sub"
                        onClick={() => {
                          setMenuOpen(!menuOpen);
                        }}
                      >
                        {/* {item?.name?.length > 10
                        ? item?.name.slice(0, 10) + "..."
                        : item?.name} */}
                        {item?.name}
                      </p>
                    </Link>
                  </li>
                ))}
                {categoryData?.classified_list?.length > 4 && (
                  <li className="list-1">
                    <Link to={`/classified/list`}>
                      <p
                        class="sub"
                        onClick={() => {
                          setMenuOpen(!menuOpen);
                        }}
                      >
                        More
                      </p>
                    </Link>
                  </li>
                )}
              </div>
              <div
                style={{
                  background:
                    splitLocation[1] == "classified" ? "#000" : "#fff",
                }}
                className="activeLine align-self-center"
              />
            </button>
            {subMenu === "class" && (
              <>
                <div className="sub-categ d-flex  align-items-md-center justify-content-md-center bg-lt-blue ">
                  <div className="tab-box">
                    <button
                      className="cust-btn menu-categ-title text-start ac-jb"
                      onClick={() => {
                        setMenuOpen(!menuOpen);
                        navigate("/classified/list");
                        setSubMenu("");
                      }}
                    >
                      Furniture
                      <KeyboardArrowDownIcon />
                    </button>
                    <button className="cust-btn menu-categ-title text-start ac-jb">
                      Electrials
                    </button>
                    <button className="cust-btn menu-categ-title text-start ac-jb">
                      Mobile Phones
                    </button>
                    <button className="cust-btn menu-categ-title text-start ac-jb">
                      Sports
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
          <button className="cust-btn menu-categ-btn dropdownmenu3">
            <p
              class="menu3"
              onClick={() => {
                navigate("/service/list");
                setMenuOpen(!menuOpen);
              }}
            >
              Services
              <KeyboardArrowDownIcon />
            </p>
            <div className="submenu3">
              {categoryList?.service_list?.slice(0, 4).map((item, ind) => (
                <li>
                  <Link
                    to={`/service/list?catId=${item?.id}&catName=${item?.name}`}
                  >
                    <p
                      class="sub3"
                      onClick={() => {
                        setMenuOpen(!menuOpen);
                      }}
                    >
                      {/* {item?.name?.length > 10
                        ? item?.name.slice(0, 10) + "..."
                        : item?.name} */}
                      {item?.name}
                    </p>
                  </Link>
                </li>
              ))}
              {categoryData?.service_list?.length > 4 && (
                <li>
                  <Link to={`/service/list`}>
                    <p
                      class="sub3"
                      onClick={() => {
                        setMenuOpen(!menuOpen);
                      }}
                    >
                      More
                    </p>
                  </Link>
                </li>
              )}
            </div>

            <div
              style={{
                background: splitLocation[1] == "service" ? "#000" : "#fff",
              }}
              className="activeLine align-self-center"
            />
          </button>
          <button className="cust-btn menu-categ-btn dropdownmenu4">
            {/* <p>JOBS
              <KeyboardArrowDownIcon />
            </p> */}

            <p
              class="menu4"
              onClick={() => {
                navigate("job/list");
                setMenuOpen(!menuOpen);
              }}
            >
              Jobs
              <KeyboardArrowDownIcon />
            </p>
            <div className="submenu4">
              {categoryList?.jobs_list?.slice(0, 4).map((item, ind) => (
                <li>
                  <Link
                    to={`/job/list?catId=${item?.id}&catName=${item?.name}`}
                  >
                    <p
                      class="sub4"
                      onClick={() => {
                        setMenuOpen(!menuOpen);
                      }}
                    >
                      {/* {item?.name?.length > 10
                        ? item?.name.slice(0, 10) + "..."
                        : item?.name} */}
                      {item?.name}
                    </p>
                  </Link>
                </li>
              ))}
              {categoryData?.jobs_list?.length > 4 && (
                <li className="list-1">
                  <Link to={`/job/list`}>
                    <p
                      class="sub"
                      onClick={() => {
                        setMenuOpen(!menuOpen);
                      }}
                    >
                      More
                    </p>
                  </Link>
                </li>
              )}
            </div>

            <div
              style={{
                background: splitLocation[1] == "job" ? "#000" : "#fff",
              }}
              className="activeLine align-self-center"
            />
          </button>
          <button
            className="cust-btn menu-categ-btn dropdownmenu5 "
          // onClick={() => subMenuHandler("explore")}
          >
            <p
              class="menu5"
              onClick={() => {
                navigate("explore/list");
                setMenuOpen(!menuOpen);
              }}
            >
              Explore
              <KeyboardArrowDownIcon />
            </p>
            <div className="submenu5">
              {categoryList?.explores_list?.slice(0, 4).map((item, ind) => (
                <li>
                  <Link
                    to={`/explore/list?catId=${item?.id}&catName=${item?.name}`}
                  >
                    <p
                      class="sub5"
                      onClick={() => {
                        setMenuOpen(!menuOpen);
                      }}
                    >
                      {/* {item?.name?.length > 10
                        ? item?.name.slice(0, 10) + "..."
                        : item?.name} */}
                      {item?.name}
                    </p>
                  </Link>
                </li>
              ))}
              {categoryData?.explores_list?.length > 4 && (
                <li>
                  <Link to={`/explore/list`}>
                    <p
                      class="sub5"
                      onClick={() => {
                        setMenuOpen(!menuOpen);
                      }}
                    >
                      More
                    </p>
                  </Link>
                </li>
              )}
            </div>
            <div
              style={{
                background: splitLocation[1] == "explore" ? "#000" : "#fff",
              }}
              className="activeLine align-self-center"
            />
          </button>
          <button
            className="cust-btn menu-categ-btn dropdownmenu6"
            onClick={() => {
              setMenuOpen(!menuOpen);
              navigate("/about");
            }}
          >
            <p className="menu6">About</p>
            <div
              style={{
                background: splitLocation[1] == "about" ? "#000" : "#fff",
              }}
              className="activeLine align-self-center"
            />
          </button>
        </div>
      </div>
      <button
        onClick={() => navigate(user ? "/profile" : "/login")}
        className="nav-sign-in-cont d-none d-lg-block mb-3 text-capitalize"
      >
        {user?.image == null ? (
          <PersonOutlineIcon />
        ) : (
          <img src={user?.image_path} />
        )}
        {user ? user?.name : "Sign In"}
      </button>
    </div>
  );
};

export default NavBar;
