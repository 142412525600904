import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Audio } from "react-loader-spinner";
import "./App.css";
import { Loader, lodergif } from "./assets/img";
import ErrorPopup from "./Components/Popup/ErrorPopup";
import { saveAlertMessage } from "./redux/slice/alertMessage";
import ReactRoutes from "./routes";

function App() {
  const [loaders, setLoaders] = useState(false);
  const dispatch = useDispatch();
  const alertMessage = useSelector((state) => state.alertMessage);

  // For Loader
  const isSomeQueryPending = useSelector((state) =>
    Object.values(state.api.queries).some((query) => query.status === "pending")
  );

  useEffect(() => {
    setTimeout(() => {
      if (isSomeQueryPending === false) {
        setLoaders(true);
      }
    }, 5000);
  }, [isSomeQueryPending]);

  const onCloseClick = () => {
    dispatch(saveAlertMessage(null));
  };
  return (
    <>
      {alertMessage && (
        <ErrorPopup alertMessage={alertMessage} onCloseClick={onCloseClick} />
      )}

      {!loaders && (
        <div className="loader-icon position-fixed bg-opacity-25 loderbg ac-jc">
          <div className="loader-icon1">{/* <img src={lodergif} /> */}</div>
        </div>
      )}

      <ReactRoutes />
    </>
  );
}

export default App;
