import React, { useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import DoneIcon from "@mui/icons-material/Done";
import { useNavigate } from "react-router-dom";
import { NUMBER } from "../../redux/api/constants";
import companyLogo from "../../assets/img/header_logo.png";
import { terms_and_conditions } from "../../assets/img";
import TermsOfUse from "./TermsOfUse";
import PrivacyPolicy from "./PrivacyPolicy";

const LoginRight = ({
  submitHandler,
  checkBox,
  setCheckBox,
  createAccHandler,
  number,
  numberErr,
  setNumberErr,
  setNumber,
  btn,
}) => {
  const navigate = useNavigate();
  const onClickHandler = () => {
    setCheckBox(!checkBox);
  };

  const [termscond, setTermsCond] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState(false);

  const termsHandle = () => {
    setTermsCond(!termscond);
  };

  const privacyPolicyHandle = () => {
    setPrivacyPolicy(!privacyPolicy);
  };

  return (
    <div className="SecondFlex">
      {termscond && <TermsOfUse termsHandle={termsHandle} />}
      {privacyPolicy && (
        <PrivacyPolicy privacyPolicyHandle={privacyPolicyHandle} />
      )}
      <div className="SecondFlexDesign">
        <img src={companyLogo} className="companyLogo d-block d-md-none" />
        <h2 className="LoginText">Login</h2>
        <label className="LoginFont">Enter The Registered Mobile Number</label>
        <div className="number-container">
          <input
            onInput={(e) => {
              if (e.target.value.length > e.target.maxLength)
                e.target.value = e.target.value.slice(0, e.target.maxLength);
              if (e.target.value.length !== 10) {
                e.target.setCustomValidity("invalid Number");
              } else if (e.target.value.length == 10) {
                e.target.setCustomValidity("");

                setNumber(e.target.value);
              }
            }}
            onFocus={(e) =>
              e.target.addEventListener(
                "wheel",
                function (e) {
                  e.preventDefault();
                },
                { passive: false }
              )
            }
            maxlength={10}
            onChange={(e) => setNumber(e.target.value)}
            value={number}
            type="number"
            placeholder="Mobile Number"
            className="MobileNumberTextInput"
          />
          <p>+91</p>
          {numberErr &&
            (NUMBER.test(number) == false || number.length < 10) && (
              <h6 className="error-state-login">
                Enter your registered number*
              </h6>
            )}
        </div>

        <div className="checkBoxDesign d-flex">
          <button
            onClick={onClickHandler}
            className="cust-btn check-box d-flex ac-jc"
          >
            {checkBox && <DoneIcon />}
          </button>
          <p className="CheckBoxText">
            By Login you are agreeing to our{" "}
            <button
              className="cust-btn text-primary me-1"
              // onClick={() => window.open(terms_and_conditions, "_blank")}
              onClick={termsHandle}
              // onClick={()=>navigate('/terms-and-condition')}
            >
              Terms of use{" "}
            </button>{" "}
            and
            <button
              className="cust-btn text-primary ms-1"
              onClick={privacyPolicyHandle}
            >
              {" "}
              Privacy Policy
            </button>
          </p>
        </div>
        <button
          onClick={submitHandler}
          disabled={btn ? true : false}
          className="cust-btn py-2 mt-3 w-100 bg-primar text-light f2 btn-submit"
        >
          Get OTP
        </button>
        <p className="NotRegisteredText f4 mt-3">
          Not Registered yet?{" "}
          <button onClick={createAccHandler} className="cust-btn text-primary">
            {" "}
            Create An Account?
          </button>
        </p>
      </div>
    </div>
  );
};

export default LoginRight;
