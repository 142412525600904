// @ts-nocheck
import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import { sofa, Sofa1 } from "../../../assets/img";

const ExploreRelatePost = ({ postDetails }) => {
  const [currentPhoto, setCurrentPhoto] = useState(1);
  const imageSelHandler = (e) => {
    setCurrentPhoto(e + 1);
  };

  const [reademore, setReadMore] = useState(true);
  const linesee = () => {
    setReadMore(!reademore);
  };

  return (
    <div className="gallery-post-tl">
      <h4 className="gallery-text bg-primar">Gallery</h4>
      <Carousel
        onSlide={(e) => imageSelHandler(e)}
        indicators={false}
        className="w-100 banner-carousel-gallery"
        fade
      >
        {postDetails?.gallery?.map((item) => {
          return (
            <Carousel.Item className="w-100 d-lg-flex">
              <div className="d-flex ac-jb justify-content-center align-items-center">
                <div className="left-carousel d-flex justify-content-center align-items-center custpm-height-carosel">
                  <p className="current-photo">
                    {currentPhoto} / {postDetails?.gallery?.length} Photos
                  </p>
                  <img
                    className="d-block w-100 object-fit-cover border rounded d-flex justify-content-center align-items-center"
                    src={item?.image ? item?.image_path : Sofa1}
                    alt="First slide"
                  />
                </div>
                <div className="line2 d-none d-lg-block"></div>
              </div>
              <div className="cap-tion mt-3 mt-lg-0">
                <h5>{item?.title}</h5>
                <div className="dis-cont custom-cap-tion">
                  <p className="mt-1 f2 custom-dis">
                    {item?.description?.length >= 650 && reademore
                      ? item?.description?.slice(0, 650)
                      : item?.description}
                    <span>
                      {item?.description?.length >= 650 && reademore && "..."}
                    </span>
                  </p>
                  {item?.description?.length > 650 && (
                    <button
                      className="bg-primar px-2 py-1 justify-content-center rounded rounded-3 round-custom"
                      onClick={linesee}
                    >
                      {reademore ? "Read more" : "Read less"}
                    </button>
                  )}
                </div>
              </div>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
};

export default ExploreRelatePost;
